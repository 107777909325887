import React, { useRef } from "react";
import PopUp from "../Utils/PopUp";
import lock from "../Assets/lock-dark.svg";
import close from "../Assets/close-dark.svg";
import "../ComponentCSS/ForgotPassword.css";
import Spinner from "../Utils/Spinner";
import mailIcon from "../Assets/mail-teal.svg";
import CustomButton from "../Utils/CustomButton";
import { AxiosResponse } from "axios";
import { Api } from "../Api/ApiClient";
import { toast } from "react-toastify";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
const ForgotPassword = (props: any) => {
  const emailRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div>
      <PopUp
        popUpDisplay="Block"   
        popUpContentWidth="31%"
        contentLeft="34%"
        contentTop="25%"
      >
        <div className="ps-1 pe-1">
          <div className="row">
            <div className="col-11 text-center">
              <div className="">
                <div className="ps-2">
                  <img src={lock} alt="Lock" className="ps-5" />
                </div>

                <div id="Main" className="pt-3">
                  <h1 className="text-center ps-5 cardHeading">
                    Forgot Password?
                  </h1>
                  <h1 className="text-center ps-5 cardSubHeading">
                    You can reset your password here.
                  </h1>
                  <div
                    className="input-group mb-3 pt-3 ps-5 pe-2"
                    style={{ height: "5vh" }}
                  >
                    <div className="input-group-prepend">
                      <span className=" border-0">
                        <img
                          src={mailIcon}
                          alt="logo label"
                          style={{ width: "50%", height: "50px" }}
                        />
                      </span>
                    </div>
                    <input
                      ref={emailRef}
                      type="text"
                      className="text-start form-control border-top-0 border-start-0 border-end-0  pl- pr-1  border_color_skyBlue border-3 inputPlaceholder"
                      placeholder="email address"
                      // onChange={(e)=>{SetInputValue({...item.placeHolder:})}}
                    />
                  </div>

                  <div className="pt-5 ps-5">
                    {loading === true? (
                     
                      <Spinner />
                    ) : (
                      <CustomButton
                        OnClickHandler={async () => {
                          setLoading(true);
                          const response: AxiosResponse =
                            await Api.forgotPassword(emailRef.current!.value);
                            
                            
                          var responseData = response.data;
                          setLoading(false);
                          notify(responseData.msg, "success");
                          // setLoading(false)
                          props.display((prev: any) => (prev = false));
                          
                        }}
                        buttonName="Submit"
                        disabled={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1">
              <img
                src={close}
                alt="close"
                onClick={() => {
                  props.display((prev: any) => (prev = false));
                }}
              />
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default ForgotPassword;