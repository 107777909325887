import React, { useEffect, useState } from "react";
// import ContainerDiv from "../Utils/ContainerDiv";
import NavBarCont from "../Utils/NavBarCont";
import backIcon from "../Assets/arrow_back-dark.svg";
import "../ComponentCSS/AgencyDetails.css";
import { AgencyDetailsDatatype } from "../Api/ApiResponseDataTypes";
import { useHistory, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Api } from "../Api/ApiClient";
import PieChart from "../Utils/PieChart";
import { setNavItems } from "../Utils/NavItems";
import CenterOfScreen from "../Utils/CenterOfScreen";
import Spinner from "../Utils/Spinner";

const AgencyDetails: React.FC = () => {
  const [AgencyDetails, setAgencyDetails] = useState<AgencyDetailsDatatype>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  useEffect(() => {
    getAgencyDetailsData();
    getPatientDistribution();
  }, []);
  const [pieChartData, setPieChartData] = useState<any>();
  const params: any = useParams();
  const getAgencyDetailsData = async () => {
    try {
      const response: AxiosResponse = await Api.getAgencyDetails(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("PayerOrgName")!.toString(),
        params.agency_id
      );
      console.log("Res" + JSON.stringify(response.data));

      const agencyResponse = response.data.data;
      var AgencyDetailsData: AgencyDetailsDatatype = {
        org_name: agencyResponse[0].org_name,
        org_id: agencyResponse[0].org_id,
        patient_total: agencyResponse[0].patient_total,
        high_risk: agencyResponse[0].high_risk,
        therapist_count: agencyResponse[0].therapist_total,
        supervisor_count: agencyResponse[0].supervisor_total,
        survey_completion: agencyResponse[0].survey_completion,
      };
      setAgencyDetails(AgencyDetailsData);
      setLoading((prev) => (prev = false));
      // console.log("check"+JSON.stringify(AgencyDetailsData))
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getPatientDistribution = async () => {
    try {
      const response: AxiosResponse = await Api.getPatientDistribution(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("PayerOrgName")!.toString(),
        "",
        params.agency_id
      );
      console.log("Res" + JSON.stringify(response.data));

      const patientDistributionResponse = response.data.data;
      var PatientDistributionData: any[] = [];
      PatientDistributionData.push(
        {
          name: "Greater than 65 (High Risk)",
          y: parseFloat(patientDistributionResponse[0].greaterThan65),
        },
        {
          name: "Less than 65 (Low Risk)",
          y: parseFloat(patientDistributionResponse[0].lessThan65),
        },
        {
          name: "No Score",
          y: parseFloat(patientDistributionResponse[0].noScore),
        }
      );

      setPieChartData(PatientDistributionData);
      // console.log("check"+JSON.stringify(AgencyDetailsData))
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <NavBarCont jsonArray={setNavItems(sessionStorage.getItem("Role")!)} />
      </div>
      <CenterOfScreen>
        <div
          style={{
            position: "relative",
            width: "80%",
            padding: "110px 10px 10px 10px",
          }}
        >
          {/* <ContainerDiv widthInPercentage="100%" height="90vh"> */}

          <>
            {loading === false ? (
              <div className="row pt-2" style={{ backgroundColor: "white" }}>
                <div id="back">
                  <img
                    src={backIcon}
                    alt="go back"
                    className="backArrow"
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                </div>
                <div className="col-md-7">
                  <div
                    className="row ps-5 pb-3 pe-3 pt-2"
                    style={{ width: "70%" }}
                  >
                    <div
                      className="p-1 m-3 agencyDetailsCard col-md-8"
                      // style={{ height: "fit-content" }}
                    >
                      <div className="row cardHeader pt-2">
                        <div className="col-md-12 text-center">
                          <p className="nameStyle">{AgencyDetails?.org_name}</p>
                        </div>
                      </div>

                      <div className="col-md-12 d-flex justify-content-center">
                        <table className="">
                          <tbody
                            className="text-center tBodyHeight"
                            style={{ overflowY: "hidden" }}
                          >
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                Location :
                              </td>
                              <td className="text-start col-md-6 cardtd"></td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                Business ID :
                              </td>
                              <td className="text-start col-md-6 p-2 cardtd"></td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                Medication ID :
                              </td>
                              <td className="text-start col-md-6 cardtd"></td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                Patients :{" "}
                              </td>
                              <td className="text-start col-md-6 cardtd">
                                {AgencyDetails?.patient_total}
                              </td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                High Risk Patients :{" "}
                              </td>
                              <td className="text-start col-md-6 cardtd">
                                {AgencyDetails?.high_risk}
                              </td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                Therapists :{" "}
                              </td>
                              <td className="text-start col-md-6 cardtd">
                                {AgencyDetails?.therapist_count}
                              </td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end col-md-6 cardtd">
                                Supervisors :{" "}
                              </td>
                              <td className="text-start col-md-6 cardtd">
                                {AgencyDetails?.supervisor_count}
                              </td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="text-end cardtd tdWidth">
                                Survey Completion Rate :
                              </td>
                              <td className="text-start  cardtd">
                                {AgencyDetails?.survey_completion}
                              </td>
                            </tr>
                            <tr className="col-md-12">
                              <td className="tdWidthButton">
                                <button
                                  className="cardButton p-3 btnFlex"
                                  onClick={() => {
                                    history.push(
                                      `/PatientSearch/${
                                        AgencyDetails?.org_id
                                      }/${AgencyDetails?.org_name}/${true}`
                                    );
                                  }}
                                >
                                  View Patients
                                </button>
                              </td>
                              <td>
                                <button
                                  className="cardButton p-3 btnFlex"
                                  onClick={() => {
                                    history.push(
                                      `/TherapistSearch/${AgencyDetails?.org_id}/${AgencyDetails?.org_name}`
                                    );
                                  }}
                                >
                                  View Therapists
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 p-2">
                  <div className="row p-3 text-center">
                    <h1>Overall SFSS Score Distribution</h1>
                  </div>
                  <div className="row">
                    {pieChartData && pieChartData.length !== 0 ? (
                      <div className="row ps-4">
                        <PieChart value={pieChartData} />
                      </div>
                    ) : pieChartData && pieChartData.length === 0 ? (
                      <div className="pieChartSpinner">
                        <p>No Data Available</p>
                      </div>
                    ) : (
                      <div className="pieChartSpinner">
                        <Spinner />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </>
          {/* </ContainerDiv> */}
        </div>
      </CenterOfScreen>
    </div>
  );
};

export default AgencyDetails;
