import { AxiosResponse } from "axios";
import React from "react";
import { Api } from "../Api/ApiClient";
import ContainerDiv from "./ContainerDiv";
import CustomButton from "./CustomButton";
import { toast } from "react-toastify";
// interface listOfCheckBoxvalues{
//     OragnizationName:string
// }
interface TherapistMappingDivProps {
  checkBoxList?: any[];
  unassignedList?: any[];
  onUnassignedCheckBoxSelected?: Function;
  supervisorDropdownValueChangeCheck?: Function;
  mappingAssignValue?: any[];
  onAssignedButtonClick?: Function;
  onUnassignedButtonClick?: Function;
  onAssignedCheckBoxSelected?: Function;
  mappingUnassignValue?: any[];
  onSubmitTherapistValue?: any[];
  onSubmitSupervisorValue?: any;
  disableSubmit?: any;
}

export interface MappingResponse {
  status: string;
  msg: string;
}

const TherapistMappingDiv: React.FC<TherapistMappingDivProps> = (
  TherapistMappingDivProps
) => {
  // const [listOfCheckBox, setlistOfCheckBox] = useState<listOfCheckBoxvalues[]>();
  // useEffect(() => {
  //     getDetails();

  //   }, []);
  var MappingResponse: MappingResponse;
  const notify = (message: string) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2500,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const onsubmit = async () => {
    //   alert("kkkk")
    var therapistArr: any[] = [];
    // alert(JSON.stringify(TherapistMappingDivProps.onSubmitSupervisorValue!.orgId) + "hhhhhhhhh"+
    //    JSON.parse(JSON.stringify( TherapistMappingDivProps.onSubmitTherapistValue![0].therapistId))
    //     )
    for (
      var i = 0;
      i < TherapistMappingDivProps!.unassignedList!.length;
      i++
    ) {
      if(TherapistMappingDivProps.unassignedList![i].checked === true){
        therapistArr.push(
          TherapistMappingDivProps.unassignedList![i].therapistId
        );
      }
      
    }
    for (
      var j = 0;
      j < TherapistMappingDivProps!.checkBoxList!.length;
      j++
    ) {
      if(TherapistMappingDivProps.checkBoxList![j].checked === true){
        therapistArr.push(
          TherapistMappingDivProps.checkBoxList![j].therapistId
        );
      }
      
    }
    alert("array" + TherapistMappingDivProps.onSubmitSupervisorValue);

    const getSupervisorTherapistMappingResponse: AxiosResponse =
      await Api.supervisorTherapistMapping(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        TherapistMappingDivProps.onSubmitSupervisorValue!.userId,
        therapistArr
      );
    MappingResponse = getSupervisorTherapistMappingResponse.data;
    if (MappingResponse.status === "success") {
      // alert(MappingResponse.msg)
      notify("Inserted Successfully");
    }
  };

  const onCancel = async()=>{
    TherapistMappingDivProps.supervisorDropdownValueChangeCheck!(JSON.stringify(TherapistMappingDivProps.onSubmitSupervisorValue))
  }
  return (
    <div>
      <ContainerDiv widthInPercentage="100%" height="50vh">
        <div className="bg-light rounded p-3">
          <div className="row p-3">
            <div
              className="col-4 text-center skyBlue "
              style={{ borderRadius: "15px", height: "45vh" }}
            >
              <h4 className="pt-4 fs-4">Unassigned Therapist</h4>
              <span className="d-block p-2">
                {TherapistMappingDivProps.unassignedList?.map((item, key) => (
                  <div className="d-flex justify-content-start p-2 ">
                    <input
                      key={item.therapistId}
                      type="checkbox"
                      style={{ zoom: "1.5", width: "10%" }}
                      checked={item.checked}
                      onChange={(event) => {
                        TherapistMappingDivProps.onUnassignedCheckBoxSelected!(
                          item.therapistName,
                          event.target.value,
                          event.target.checked,
                        );
                      }}
                      value={item.therapistId}
                    ></input>
                    <span key={key + 1} className="ps-2 fs-6">
                      {item.therapistName}
                    </span>
                  </div>
                ))}
              </span>
            </div>
            <div className="col-4 text-center">
              <div
                className="row p-2 "
                style={{
                  marginBottom: "50px",
                  height: "8vh",
                  position: "relative",
                  left: "10px",
                  top: "50px",
                }}
              >
                <CustomButton
                  buttonName=">>"
                  disabled={false}
                  OnClickHandler={(e) => {
                    // alert(">> button"+TherapistMappingDivProps.mappingAssignValue)
                   // if(TherapistMappingDivProps.mappingAssignValue?.length != 0){
                      TherapistMappingDivProps.onAssignedButtonClick!();
                    //}
                   
                  }}
                />
              </div>

              {/* <div className= "row p-2 "></div> 
                        <div className= "row p-2 "></div>  */}
              <div
                className="row p-2"
                style={{
                  marginBottom: "50px",
                  height: "8vh",
                  position: "relative",
                  left: "10px",
                  top: "100px",
                }}
              >
                <CustomButton
                  buttonName="<<"
                  disabled={false}
                  OnClickHandler={(e) => {
                    // alert("<< button"+TherapistMappingDivProps.mappingUnassignValue)
                    // if (
                    //   TherapistMappingDivProps.mappingUnassignValue?.length != 0
                    // ) {
                      TherapistMappingDivProps.onUnassignedButtonClick!();
                    //}
                  }}
                />
              </div>
            </div>
            <div
              className="col-4 text-center skyBlue "
              style={{ borderRadius: "15px" }}
            >
              <h4 className="pt-4 fs-4">Assigned Therapist</h4>
              <span className="d-block p-2">
                {TherapistMappingDivProps.checkBoxList?.map((item, key) => (
                  <div className="d-flex justify-content-start p-2 ">
                    <input
                      key={item.therapistId}
                      type="checkbox"
                      style={{ zoom: "1.5", width: "10%" }}
                      checked={item.checked}
                      onChange={(event) => {
                        TherapistMappingDivProps.onAssignedCheckBoxSelected!(
                          item.therapistName,
                          event.target.value,
                          event.target.checked,
                        );
                      }}
                      value={item.therapistId}
                    ></input>
                    <span className="ps-2 fs-6">{item.therapistName}</span>
                  </div>
                ))}
              </span>
            </div>
          </div>
          <div className="row" style={{ height: "50px" }}>
            <div className="col-4"></div>
            <div className="col-4">
            <div className="row">
            <div className="col-6 text-center  rounded p-3">
               
                <CustomButton
                  disabled={TherapistMappingDivProps.disableSubmit}
                  buttonName="Submit"
                  OnClickHandler={(e) => {
                    onsubmit();
                  }}
                ></CustomButton>
               
            </div>
            <div className="col-6 text-center  rounded p-3">
               
                <CustomButton
                  disabled={TherapistMappingDivProps.disableSubmit}
                  buttonName="Cancel"
                  OnClickHandler={(e) => {
                    onCancel();
                  }}
                ></CustomButton>
               
            </div>
            </div>
            </div>
            <div className="col-4 "></div>
          </div>
        </div>
      </ContainerDiv>
    </div>
  );
};

export default TherapistMappingDiv;
