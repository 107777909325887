import React, { MouseEventHandler } from "react";
import "../CSS/Popup.css";

interface PopUpProps {
  popUpDisplay: string;
  popUpContentWidth:string;
  contentTop:string;
  contentLeft:string;
  onClickHandler?:MouseEventHandler<HTMLDivElement>;
}

const PopUp: React.FC<PopUpProps> = ({ popUpDisplay, children ,popUpContentWidth,contentLeft,contentTop,onClickHandler}) => {
 
 const click=()=>{

 }
  return (
    <div>
      <div
        className="popup-modal"
        style={{
          display: `${popUpDisplay}`,
          position: "fixed",
          zIndex: 3,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 151, 191, 0.70)",
        }}
        onClick={onClickHandler!==undefined?onClickHandler:click}
      >
        <div className="modal_content" style={{width:popUpContentWidth,top:contentTop,left:contentLeft}}>{children}</div>
      </div>
    </div>
  );
};

export default PopUp;
