import React, { useState } from "react";
import logo from "../Assets/Clarity AI Logo.png";
import userTeal from "../Assets/userWhite.svg";
import PopUp from "./PopUp";
import settingsIcon from "../Assets/settings.svg";
import logoutIcon from "../Assets/logout-teal.svg";
import cancelIcon from "../Assets/close-teal.svg";
import closeIcon from "../Assets/close-dark.svg";
import doneIcon from "../Assets/done-teal.svg";
import sha1 from "sha1";
import CustomButton from "./CustomButton";
import { useHistory } from "react-router";
import { Api } from "../Api/ApiClient";
import clipboard from "../Assets/clipboard-text-dark.svg";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Spinner from "../Utils/Spinner";

interface NavBarContProps {
  children?: JSX.Element;
  jsonArray: any;
}

const NavBarCont: React.FC<NavBarContProps> = (NavBarContProps) => {
  const [userIcon, setUserIcon] = useState<string>("none");
  const [logout, setLogout] = useState<string>("none");
  const [settings, setSettings] = useState<string>("none");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [checkBox, setCheckbox] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  // var saveClickHandler: string;
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const PasswordData = () => {
    window.location.reload()
    setOldPassword("")
    console.log("oldPasswordRefresh:"+oldPassword);
    setNewPassword("")
    console.log("newPasswordRefresh:"+newPassword);
    setConfirmNewPassword("")
    console.log("confirmNewPasswordRefresh:"+confirmNewPassword);
    setSettings((prev) => (prev = "none"));
   
                  // window.location.reload()
  }
  
  return (
    <div>
      <nav
        className="navbar  navbarGradient navbar-light bg-light"
        style={{ position: "fixed", width: "100%", zIndex: 2 }}
      >
        <div className="container-fluid row">
          <div className="col-md-1">
            <img src={logo} style={{ marginLeft: "20px" }} alt="logo" />
          </div>

          <div className="col-md-10 text-end d-none d-sm-block" id="navbarNav">
            {/* <a className="nav-link active navItems navItemsBtn1" aria-current="page" href="#">Dashboard</a>
                            <a className="nav-link navItems" href="#">Users</a> */}
            <span>
              {/* {MenuItems.map((item,index)=>{
                                    return(
                                        <a key={index} className={item.cName}>
                                            {item.title}
                                        </a>
                                    )
                                })} */}
              {NavBarContProps.jsonArray &&
                NavBarContProps.jsonArray.map((item: any, key: any) => {
                  try {
                    return (
                      <a
                        key={key}
                        href={item.url}
                        className={
                          window.location.pathname === item.url
                            ? `${item.cName} active`
                            : item.cName
                        }
                      >
                        <span className="fs-5 heading"> {item.title}</span>
                      </a>
                    );
                  } catch (error) {
                    
                    console.log(JSON.stringify(error));
                    return(<></>)
                  }
                })}
            </span>
            {/* <button className="navbarButton">
                           
                            <svg fill="none" height="40" viewBox="0 0 34 36" width="40" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M16.954 33.711C8.37084 33.711 1.41284 26.4927 1.41284 17.5884C1.41284 8.68406 8.37084 1.4657 16.954 1.4657C25.5371 1.4657 32.4951 8.68406 32.4951 17.5884C32.4951 26.4927 25.5371 33.711 16.954 33.711ZM27.4082 25.0995C28.8339 22.9679 29.6694 20.3794 29.6694 17.5884C29.6694 10.303 23.9765 4.39709 16.954 4.39709C9.93141 4.39709 4.2385 10.303 4.2385 17.5884C4.2385 20.3794 5.07402 22.9679 6.49974 25.0995C8.08981 22.9207 11.8262 21.9854 16.954 21.9854C22.0817 21.9854 25.8181 22.9207 27.4082 25.0995ZM25.381 27.4668C24.9675 25.9243 21.9969 24.9168 16.954 24.9168C11.911 24.9168 8.9404 25.9243 8.52688 27.4668C10.7704 29.5282 13.7213 30.7796 16.954 30.7796C20.1866 30.7796 23.1375 29.5282 25.381 27.4668ZM16.954 8.79418C13.5336 8.79418 11.3026 11.3676 11.3026 14.657C11.3026 19.6805 13.7904 21.9854 16.954 21.9854C20.0877 21.9854 22.6053 19.757 22.6053 14.9501C22.6053 11.6106 20.3648 8.79418 16.954 8.79418ZM14.1283 14.657C14.1283 17.983 15.2843 19.0541 16.954 19.0541C18.6179 19.0541 19.7796 18.0257 19.7796 14.9501C19.7796 13.1186 18.6715 11.7256 16.954 11.7256C15.1649 11.7256 14.1283 12.9213 14.1283 14.657Z" fill="white" fill-opacity="0.7" fill-rule="evenodd"></path></svg>
                            </button>  */}
          </div>
          <div className="col-md-1">
            <button
              className="navbarButton"
              onClick={() => {
                userIcon === "none"
                  ? setUserIcon((prev) => (prev = "Block"))
                  : setUserIcon((prev) => (prev = "none"));
              }}
            >
              <img src={userTeal} alt="user white" />
            </button>
          </div>
        </div>
        <div
          style={{ backgroundColor: "white", width: "100%", height: "10px" }}
        ></div>
      </nav>

      <PopUp
        contentLeft="81%"
        contentTop="7%"
        popUpContentWidth="13.5%"
        popUpDisplay={userIcon}
        onClickHandler={() => {
          setUserIcon((prev) => (prev = "none"));
          setSettings((prev) => (prev = "none"));
        }}
      >
        <div className="ps-2">
          {sessionStorage.getItem("Role")?.toString() === "Therapist" ? (
            <div
              className="pb-3"
              onClick={(e) => {
                //alert("hello");
                // setSettings((prev) => (prev = "block"));
                e.stopPropagation();
                //  alert(sessionStorage.getItem("tid"))
                history.push(
                  "/therapistDetails/" + sessionStorage.getItem("therapistID")
                );
              }}
            >
              <img src={clipboard} alt="Therapist Profile" />
              <span className="ps-3" style={{ cursor: "pointer" }}>
                Therapist Profile
              </span>
            </div>
          ) : (
            <></>
          )}
          <div
            onClick={(e) => {
              //alert("hello");
              setUserIcon((prev) => (prev = "none"));
              setSettings((prev) => (prev = "block"));

              e.stopPropagation();
            }}
          >
            <img src={settingsIcon} alt="settings" />
            <span className="ps-3" style={{ cursor: "pointer" }}>
              Settings
            </span>
          </div>

          <div
            className="pt-3"
            onClick={(e) => {
              //alert("hello");
              setUserIcon((prev) => (prev = "none"));
              setLogout((prev) => (prev = "block"));
              e.stopPropagation();
            }}
          >
            {/* <img src={} alt="logout" /> */}
            <svg
              fill="none"
              height="30"
              viewBox="0 0 40 40"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1667 12.8333C16.5167 13.4833 16.5167 14.5167 17.1667 15.1667L20.3333 18.3333H5C4.08333 18.3333 3.33333 19.0833 3.33333 20C3.33333 20.9167 4.08333 21.6667 5 21.6667H20.3333L17.1667 24.8333C16.5167 25.4833 16.5167 26.5167 17.1667 27.1667C17.8167 27.8167 18.85 27.8167 19.5 27.1667L25.4833 21.1833C26.1333 20.5333 26.1333 19.4833 25.4833 18.8333L19.5 12.8333C18.85 12.1833 17.8167 12.1833 17.1667 12.8333ZM33.3333 31.6667H21.6667C20.75 31.6667 20 32.4167 20 33.3333C20 34.25 20.75 35 21.6667 35H33.3333C35.1667 35 36.6667 33.5 36.6667 31.6667V8.33333C36.6667 6.5 35.1667 5 33.3333 5H21.6667C20.75 5 20 5.75 20 6.66667C20 7.58333 20.75 8.33333 21.6667 8.33333H33.3333V31.6667Z"
                fill="#0A4364"
              ></path>
            </svg>
            <span className="ps-3" style={{ cursor: "pointer" }}>
              Log out
            </span>
          </div>
        </div>
      </PopUp>

      <PopUp
        contentLeft="38%"
        contentTop="35%"
        popUpContentWidth="29%"
        popUpDisplay={logout}
        onClickHandler={() => {
          setLogout((prev) => (prev = "none"));
        }}
      >
        <div className="row">
          <div className="col-md-10">
            <h3 className="ps-3">Are you sure you want to log out?</h3>
          </div>
          <div className="col-md-2">
            <img
              className="ps-2 pb-2"
              style={{ cursor: "pointer" }}
              src={closeIcon}
              alt="close"
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <div className="ps-5">
              <CustomButton
                OnClickHandler={async () => {
                  history.push("/");

                  const responseLogout: AxiosResponse = await Api.postLogout(
                    sessionStorage.getItem("token")!.toString(),
                    {
                      userId: sessionStorage.getItem("userId"),
                    }
                  );

                  console.log(JSON.stringify(responseLogout.data));
                  localStorage.clear();
                  sessionStorage.clear();
                }}
                disabled={false}
                buttonName="Logout"
                key="log-out"
                logo={logoutIcon}
              />
              <span className="ps-3">
                <span className="ps-3">
                  <CustomButton
                    OnClickHandler={(e) => {
                      setLogout((prev) => (prev = "none"));
                      e.stopPropagation();
                    }}
                    disabled={false}
                    buttonName="Cancel"
                    key="cancel"
                    logo={cancelIcon}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      </PopUp>
      <PopUp
        contentLeft="21%"
        contentTop="10%"
        popUpContentWidth="55%"
        popUpDisplay={settings}
      >
        <div className="row ps-2 pt-2">
          <div className="col-4">
            <h1>Settings</h1>
            {/* <CustomButton
              buttonName="Change Password"
              disabled={false}
              OnClickHandler={() => {}}
            /> */}
          </div>
          <div className="col-8 ">
            <h2>
              Change password{" "}
              <img
                style={{
                  paddingLeft: "30%",
                  paddingBottom: "4%",
                  cursor: "pointer",
                }}
                src={closeIcon}
                alt="close"
                onClick={() => {
                  PasswordData()
                  
                  // setSettings((prev) => (prev = "none"));

                  
                }}
              />
            </h2>

            {loading === false ? (
              <>
                {" "}
                <div className="pt-3">
                  {/* <label className="pb-2">Old password</label> */}
                  <input
                    className="border-top-0 border-end-0 border-start-0 p-2 border-info"
                    type={`${checkBox === false ? "password" : "text"}`}
                    placeholder="Old Password"
                    onChange={(e) => {
                      setOldPassword((prev) => (prev = e.target.value));
                    }}
                  ></input>
                </div>
                <div className="pt-4">
                  {/* <label className="pb-2">New password</label> */}
                  <input
                    className="border-top-0 border-end-0 border-start-0 p-2 border-info pb-3"
                    type={`${checkBox === false ? "password" : "text"}`}
                    placeholder="New Password"
                    onChange={(e) => {
                      setNewPassword((prev) => (prev = e.target.value));
                    }}
                  ></input>
                  <div className="pt-2">
                    <input
                      className="border-top-0 border-end-0 border-start-0 p-2 border-info pt-4"
                      type={`${checkBox === false ? "password" : "text"}`}
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        console.log("confirmNewPassword:"+confirmNewPassword);
                        setConfirmNewPassword(
                          (prev) => (prev = e.target.value)
                        );
                      }}
                    ></input>
                  </div>
                </div>
                <div className="pt-4">
                  <input
                    checked={checkBox}
                    type="checkbox"
                    style={{ width: "10%", padding: "2%" }}
                    onChange={() => {
                      setCheckbox((prev) => (prev = !checkBox));
                    }}
                  ></input>
                  <label> Show Password</label>
                </div>
                <div className="pt-3">
                  <small style={{ fontSize: "80%" }}>
                    Note: Password length should be between 11 to 20 characters
                    and should contain a lowercase character, an uppercase
                    character, a digit, a special character.
                  </small>
                </div>
                <div className="pt-3">
                  {oldPassword !== "" && oldPassword !== null && oldPassword !== undefined &&  newPassword !== "" && newPassword !== null && newPassword !== undefined && confirmNewPassword !== "" && confirmNewPassword !== null && confirmNewPassword !== undefined ? (<CustomButton
                    buttonName="Save"
                    disabled={false}
                    logo={doneIcon}
                    OnClickHandler={async () => {
                      // alert(
                      //   "" +
                      //     sha1(newPassword) +
                      //     "  " +
                      //     oldPassword +
                      //     "  " +
                      //     confirmNewPassword
                      // );
                      setLoading(true);
                      const send: any = {};

                      send.oldPwd = sha1(oldPassword);
                      send.newPwd = newPassword;
                      send.role = sessionStorage.getItem("Role");
                      send.code = sessionStorage.getItem("TenantId");

                      //alert(JSON.stringify(send));

                      const changePasswordResponse: any =
                        await Api.postChangePassword(
                          encodeURIComponent(
                            sessionStorage.getItem("token")!.toString()
                          ),
                          send
                        );
                      if (changePasswordResponse.data.status === "success") {
                        notify(changePasswordResponse.data.msg, "success");
                        setSettings((prev) => (prev = "none"));
                        // setOldPassword((prev) => (prev = ""))
                        // setNewPassword((prev) => (prev = ""))
                        // setConfirmNewPassword((prev) => (prev = ""))
                        setLoading(false);
                        history.push("/");
                      } else {
                        notify(changePasswordResponse.data.error.msg, "error");
                        setLoading(false);
                      }

                      // alert(JSON.stringify(forgotPasswordResponse.data));
                    }}
                  />):(<CustomButton
                    buttonName="Save"
                    disabled={true}
                    logo={doneIcon}
                    OnClickHandler={async () => {
                      // alert(
                      //   "" +
                      //     sha1(newPassword) +
                      //     "  " +
                      //     oldPassword +
                      //     "  " +
                      //     confirmNewPassword
                      // );
                      setLoading(true);
                      const send: any = {};

                      send.oldPwd = sha1(oldPassword);
                      send.newPwd = newPassword;
                      send.role = sessionStorage.getItem("Role");
                      send.code = sessionStorage.getItem("TenantId");

                      //alert(JSON.stringify(send));

                      const changePasswordResponse: any =
                        await Api.postChangePassword(
                          encodeURIComponent(
                            sessionStorage.getItem("token")!.toString()
                          ),
                          send
                        );
                      if (changePasswordResponse.data.status === "success") {
                        notify(changePasswordResponse.data.msg, "success");
                        setSettings((prev) => (prev = "none"));
                        setLoading(false);
                        history.push("/");
                      } else {
                        notify(changePasswordResponse.data.error.msg, "error");
                        setLoading(false);
                      }

                      // alert(JSON.stringify(forgotPasswordResponse.data));
                    }}
                  />)}
                  
                  
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default NavBarCont;
