import React, { useEffect, useState } from "react";
import CenterOfScreen from "../Utils/CenterOfScreen";
import NavBarCont from "../Utils/NavBarCont";
import { setNavItems } from "../Utils/NavItems";
import Spinner from "../Utils/Spinner";
import backButton from "../Assets/arrow_back-dark.svg";
import { useHistory, useParams } from "react-router-dom";
import "../ComponentCSS/PatientFileHistory.css";
import { Api } from "../Api/ApiClient";
import { AxiosResponse } from "axios";
import { FileHistoryResponse } from "../Api/PatientFileHistoryDataTypes";
import moment from "moment";

const PatientFileHistory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [patientFileHistory, setPatientFileHistory] =
    useState<FileHistoryResponse>();
  const history = useHistory();
  const params: any = useParams();
  let fileHistoryData: any;
  useEffect(() => {
    if (sessionStorage.getItem("file_history") != undefined && sessionStorage.getItem("file_history") != null) {
      fileHistoryData = sessionStorage.getItem("file_history");
      setPatientFileHistory(JSON.parse(fileHistoryData));
      setLoading((prev) => (prev = false));
    } else {
      getPatientFileHistory();
    }
  }, []);

  const getPatientFileHistory = async () => {
    try {
      const fileHistoryResponse: AxiosResponse = await Api.getFileHistoryList(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        String(params.pid)
        //  "1"
      );
      const fileHistoryResponseData: FileHistoryResponse =
        fileHistoryResponse.data;

      fileHistoryResponseData.status === "Success"
        ? setLoading((prev) => (prev = false))
        : setLoading((prev) => (prev = false));

      if (fileHistoryResponseData.status === "Success") {
        setLoading((prev) => (prev = false));
        setPatientFileHistory(fileHistoryResponseData);
        sessionStorage.setItem(
          "file_history",
          JSON.stringify(fileHistoryResponseData)
        );
      } else {
        //invalid token
        setLoading((prev) => (prev = false));
        history.push("/");
      }

      //alert(JSON.stringify(fileHistoryResponseData.data));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <React.Fragment>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      {loading === false ? (
        <CenterOfScreen>
          <div style={{ width: "70%", paddingTop: "4%" }}>
            <div
              className="backGroundWhite p-2 ps-3 pe-3 "
              style={{
                borderRadius: "12px",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={backButton}
                alt={"Go back"}
                onClick={() => {
                  history.goBack();
                }}
              />
              <div className="container text-center" style={{ width: "100%" }}>
                <h1 className="text-start pt-2 ps-5">
                  {patientFileHistory?.data.name}: File History
                </h1>
              </div>
              <div>
                <div className="table-responsive pt-4">
                  <div className="container" style={{ height: "40vh" }}>
                    <table
                      className="spacingBetweenRowsCollapse"
                      style={{ width: "inherit" }}
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Date</th>
                          <th>Old Value</th>
                          <th>New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientFileHistory?.data.events?.map((items, keys) => (
                          <tr key={keys}>
                            <td className="dotted_row">{items.event_name}</td>
                            <td className="dotted_row">
                              {moment(items.date).format("MM/DD/YYYY")}
                            </td>
                            <td className="dotted_row">{items.old_value}</td>
                            <td className="dotted_row">{items.new_value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CenterOfScreen>
      ) : (
        <CenterOfScreen>
          <Spinner />
        </CenterOfScreen>
      )}
    </React.Fragment>
  );
};

export default PatientFileHistory;
