import React, { useEffect } from "react";
import "./App.css";
import { Login } from "./Components/Login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import {
//   SPAdminNavItems,
//   SuperAdminNavItems,
//   SupervisorNavItems,
//   TherapistNavItems,
//   UtilizationNavItems,
//   VoicelablingNavItems,
// } from "./Utils/NavItems";
// import CenterOfScreen from "./Utils/CenterOfScreen";
// import OrganizationDashboard from "./Utils/SearchBarTable";
// import Dropdown from "./Utils/Dropdown";
// import TherapistMappingDiv from "./Utils/TherapistMappingDiv";
import SuperVisorDashboard, {
  checkSessionTypes,
} from "./Components/SuperVisorDashboard";
import SuperAdminMapping from "./Components/SuperAdminMapping";
import SPAdminMapping from "./Components/SPAdminMapping";
import SPAdminUsers from "./Components/SPAdminUsers";
import PatientDetails from "./Components/PatientDetails";
import SupervisorUsers from "./Components/SupervisorUsers";
import TherapistDetails from "./Components/TherapistDetails";
import TherapistSearch from "./Components/TherapistSearch";
import TherapistDashboard from "./Components/TherapistDashboard";
import PatientSearch from "./Components/PatientSearch";
import SpAdminDashboard from "./Components/SpAdminDashboard";
import UMDashboard from "./Components/UMDashboard";
import Agencies from "./Components/Agencies";
import AgenciesDetails from "./Components/AgencyDetails";
import PatientFileHistory from "./Components/PatientFileHistory";
import SuperAdminUsers from "./Components/SuperAdminUsers";
import PatientSearchVL from "./Components/PatientSearchVL";
import ImportPatient from "./Components/ImportPatient"; 
//import VoiceSample from "./Components/VoiceSample";
import { AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Api } from "./Api/ApiClient";
import ForgotPassword from "./Components/ForgotPassword";
// import AudioPlayer from "./Components/AudioPlayer";
import VoiceLabeling from "./Components/VoiceLabeling";
import CreatePassword from "./Components/CreatePassword"; 
// import SearchBar from "./Utils/SearchBar";

const App: React.FC = () => {
  // const history = useHistory();
  // const [showToast, setShowToast] = useState(true);
  const notify = () =>
    toast.error("Session expired", {
      position: "top-right",
      autoClose: 2000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const checkSession = async () => {
    try {
      const response: AxiosResponse = await Api.checkSession(
        encodeURIComponent(sessionStorage.getItem("token")!.toString())
      );
      const checkSessionData: checkSessionTypes = response.data;

      //alert(JSON.stringify(checkSessionData));
      if (checkSessionData.status === "success") {
      } else {
        //history.push("/");
        //setShowToast((prev) => (prev = true));

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);

        notify();
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  useEffect(() => {
    //alert(window.location)

    if (window.location.toString() === "http://localhost:3000/") {
    } else {
      checkSession();
    }
  });

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <Router>
        {/* <div style={{display:"none"}}>
          <App />
        </div> */}
        <Switch>
          <Route path="/" exact={true} render={() => <Login />} />
          {/* <Route path="/" exact={true} render={() => <AudioPlayer url="" />} /> */}
          <Route
            path="/supervisorDashboard"
            component={() => <SuperVisorDashboard />}
          />
          <Route
            path="/patientFileHistory/:pid"
            component={() => <PatientFileHistory />}
          />
          <Route
            path="/therapistDetails/:tid/:payer?"
            component={() => <TherapistDetails />}
          />
          <Route
            path="/patientDetails/:pid/:tid"
            component={() => <PatientDetails />}
          />
          <Route path="/User" component={() => <SupervisorUsers />} />
          <Route path="/SuperAdminUser" component={() => <SuperAdminUsers />} />
          <Route
            path="/TherapistSearch/:id?/:name?"
            component={() => <TherapistSearch />}
          />
          <Route
            path="/TherapistDashboard"
            component={() => <TherapistDashboard />}
          />
          {/* <Route
            path="/PatientSearch/:tid?"
            component={() => <PatientSearch />}
          /> */}
          <Route
            path="/PatientSearch/:id?/:name?/:agency?"
            component={() => <PatientSearch />}
          />
          <Route
            path="/OrganizationDashboard"
            component={() => <SpAdminDashboard />}
          />
          <Route path="/SPAdminUsers" component={() => <SPAdminUsers />} />
          <Route path="/UMDashboard" component={() => <UMDashboard />} />
          <Route
            path="/SuperAdminMapping"
            component={() => <SuperAdminMapping />}
          />
          <Route
            path="/TherapistMappingSPAdmin"
            component={() => <SPAdminMapping />}
          />
          <Route path="/Agencies" component={() => <Agencies />} />
          <Route
            path="/AgencyDetails/:agency_id"
            component={() => <AgenciesDetails />}
          />
          <Route
            path="/PatientSearchVL/:org_id?/:patient_code?"
            component={() => <PatientSearchVL />}
          />
          <Route path="/importPatients" component={() => <ImportPatient />} />
          {/* <Route
            path="/VoiceSample/:therapist_id/:patient_id"
            component={() => <VoiceSample />}
          /> */}
          <Route path="/ForgotPassword">
            <ForgotPassword />
          </Route>
          {/* <Route path="/createPassword/:token">
            <CreatePassword />
          </Route> */}
          <Route path="/CreatePassword" component={() => <CreatePassword />} />
  

          <Route
            path="/VoiceLabeling/:survey_id/:patient_code/:date/:org_id"
            component={() => <VoiceLabeling />}
          />

 
        </Switch>
      </Router>
    </>
  );
};

export default App;
