import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Api } from "../Api/ApiClient";
import _ from "underscore";
import CenterOfScreen from "../Utils/CenterOfScreen";
import NavBarCont from "../Utils/NavBarCont";
import backButton from "../Assets/arrow_back-dark.svg";
import Spinner from "../Utils/Spinner";
import PopUp from "../Utils/PopUp";
import { setNavItems } from "../Utils/NavItems";
import { useHistory, useParams } from "react-router-dom";
import "../ComponentCSS/VoiceLabeling.css";
import CustomButton from "../Utils/CustomButton";
import DatePicker from "react-datepicker";
import doneIcon from "../Assets/done-teal.svg";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

// var audio = new Audio();
// var timer: any;
// var fileDur = 90;

const VoiceLabeling: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  // const [popUpDisplay, setPopUpDisplay] = useState<string>("none");
  const history = useHistory();
  const params: any = useParams();
  let [voiceNoteListArr, setVoiceNoteList] = useState<any>([]);
  // let [status, setStatus] = useState<any>();
  // let [incr, setIncr] = useState<any>(1);
  // let [curProgress, setCurProgess] = useState<any>(0);
  // let [progress, setProgress] = useState<any>(0);
  const [displayDate, displayDatePicker] = useState<any>(false);
  const [selectedSurveyDate, setSelectedSurveyDate] = useState<Date>();
  let [surveyDate, setSurveyDate] = useState<string>();
  const [surveyId, setSurveyID] = useState<any>();
  const [voice_storage_link, setVoiceStorageLink] = useState<any>();
  const [voiceSampleId, setVoiceSampleId] = useState<any>();
  const [disabledFlag, setDisabledFlag] = useState<any>(true);

  const [patientLevelOfFunctioning, setPatientLevelOfFunctioning] =
    useState<any>({
      emotional: false,
      peer: false,
      executive: false,
      // academic: false,
      relational: false,
    });
  const [patientConfidenceVoice, setPatientConfidenceVoice] = useState<any>({
    fear: false,
    anger: false,
    sadness: false,
    neutral: false,
    happy: false,
  });

  const disabledFun = () => {
    var levelOfFunctioning = Object.values(patientLevelOfFunctioning);
    var confidenceValues = Object.values(patientConfidenceVoice);

    var levelOfFunctioningFlag = _.contains(levelOfFunctioning, false);
    var confidenceValuesFlag = _.contains(confidenceValues, false);

    // alert("LevelOfFunctioning-----" +levelOfFunctioning)
    // alert("confidence values-----" +confidenceValues);

    if (levelOfFunctioningFlag === false && confidenceValuesFlag === false) {
      setDisabledFlag(false);
    } else {
      setDisabledFlag(true);
    }
  };
  useEffect(() => {
    disabledFun();
  }, [patientLevelOfFunctioning, patientConfidenceVoice]);

  const [popUpDisplayOnAddVoiceLabel, setAddVoiceLabelPopUpDisplay] =
    useState<string>("none");
  const [saveVoiceLabelPopUp, setSaveVoiceLabelPopUp] =
    useState<string>("none");

  const [viewMode, setViewMode] = useState<any>();
  const [file_name, setFileName] = useState<any>();
  // const patientLevelOfFunctioningArr = ["exteremly_low", "low", "normal"];
  var voice_sample_data: any;
  useEffect(() => {
    if (sessionStorage.getItem("voice_sample_data") !== undefined && sessionStorage.getItem("voice_sample_data") !== null) {
      voice_sample_data = sessionStorage.getItem("voice_sample_data");
      setVoiceNoteList(JSON.parse(voice_sample_data));
      setLoading((prev) => (prev = false));
    } else {
      getVoiceSamples(params.survey_id);
    }
  }, []);
  const getVoiceSamples = async (survey_id: any) => {
    const getVoiceSamplesResponse: AxiosResponse = await Api.getVoiceSamples(
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      survey_id
    );

    if (getVoiceSamplesResponse.status === 200) {
      setVoiceNoteList(getVoiceSamplesResponse.data.data);
      sessionStorage.setItem(
        "voice_sample_data",
        JSON.stringify(getVoiceSamplesResponse.data.data)
      );
      setLoading((prev) => (prev = false));
    }
  };

  const goBack = () => {
    //history.goBack();id=${d.id}
    history.push(`/PatientSearchVL/${params.org_id}/${params.patient_code}`);
  };

  // const reload = () => {
  //   window.location.reload();
  // };

  const openVoice = (
    surveyId: any,
    voiceSampleID: any,
    flag: any,
    date: any,
    voice_storage_link: any,
    file_name: any
  ) => {
    setSurveyDate(date);
    setSurveyID(surveyId);
    setViewMode(flag);
    setVoiceSampleId(voiceSampleID);
    setVoiceStorageLink(voice_storage_link);
    setFileName(file_name);
    setAddVoiceLabelPopUpDisplay("block");
    // audio = new Audio();
    if (flag === true) {
      getSurveyDetails(surveyId, voiceSampleID);
    }
    if (surveyId === "" && voiceSampleID === "") {
      displayDatePicker(true);
    }
  };

  const getSurveyDetails = async (surveyId: any, voiceSampleId: any) => {
     
      const getSurveyDetailsResponse: AxiosResponse =
        await Api.getSurveyDetails(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          surveyId,
          voiceSampleId
        );

      if (getSurveyDetailsResponse.status === 200) {
        if (getSurveyDetailsResponse.data.data.length !== 0) {
          setPatientLevelOfFunctioning(
            JSON.parse(
              getSurveyDetailsResponse.data.data[0][
                "patients_level_of_functioning"
              ]
            )
          );
          setPatientConfidenceVoice(
            JSON.parse(getSurveyDetailsResponse.data.data[0]["voice_labeling"])
          ); 
        }
      } 
  };

  const saveVoiceLabeling = async () => {
    var updateFlag = true;
    if (surveyDate === "") {
      updateFlag = false;
    }

    if (
      JSON.stringify(patientConfidenceVoice) ===
      JSON.stringify({
        fear: false,
        happy: false,
        anger: false,
        sadness: false,
        neutral: false,
      })
    ) {
      updateFlag = false;
      notify(
        "Please select options for Patient Voice Type / Confidence Level",
        "error"
      );
    }

    if (
      JSON.stringify(patientLevelOfFunctioning) ===
      JSON.stringify({
        emotional: false,
        peer: false,
        executive: false,
        academic: false,
        relational: false,
      })
    ) {
      updateFlag = false;
      notify("Please select options for Patient Level of functioning", "error");
    }

    if (updateFlag === true) {
      const response = await Api.updateVoiceSample(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        surveyId,
        // params["therapist_id"],
        params["patient_code"],
        voiceSampleId!,
        surveyDate!,
        patientConfidenceVoice,
        patientLevelOfFunctioning,
        voice_storage_link
      );
      if (response.data.status === "success") {
        notify(response.data.msg, "success");
        setSaveVoiceLabelPopUp("none");
        setAddVoiceLabelPopUpDisplay("none");
        sessionStorage.removeItem("voice_sample_data");
        sessionStorage.removeItem("org_patients_surveys")
        window.location.reload();
      } else {
        notify(response.data.msg, "error");
        setSaveVoiceLabelPopUp("none");
        setAddVoiceLabelPopUpDisplay("none");
      }
    }
  };
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const onChangeVoice = (e: any) => {
    setPatientLevelOfFunctioning({
      ...patientLevelOfFunctioning,
      [e.target.name]: e.target.value,
    });
    disabledFun();
  };
  const onChangeVoiceType = (e: any) => {
    setPatientConfidenceVoice({
      ...patientConfidenceVoice,
      [e.target.name]: e.target.value,
    });
    disabledFun();
  };
  // const onInputClick = (event: any) => {
  //   event.target.value = "";
  // };

  // const uploadFile = async (file: any) => {
  //   setLoading((prev) => (prev = true));
  //   // let validSize = (file.target.files[0].size / 1024 / 1024).toFixed(2);
  //   // console.log("File size:" + file.target.files[0].size);
  //   var fileName =
  //     params["therapist_id"] +
  //     "_" +
  //     params["patient_id"] +
  //     "_" +
  //     new Date().getTime();

  //   const getSurveyResponse: AxiosResponse = await Api.uploadVoiceSample(
  //     encodeURIComponent(sessionStorage.getItem("token")!.toString()),
  //     file,
  //     fileName
  //   );

  //   if (getSurveyResponse.data.status === "success") {
  //     setLoading((prev) => (prev = false));
  //     notify(getSurveyResponse.data.msg, "info");
  //     openVoice("", "", false, "", getSurveyResponse.data.bucket_link, "");
  //   }
  // };
  return (
    <React.Fragment>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>

      <CenterOfScreen>
        <div style={{ width: "70%" }}>
          <PopUp
            popUpDisplay={popUpDisplayOnAddVoiceLabel}
            popUpContentWidth="60%"
            contentLeft="20%"
            contentTop="3%"
          >
            <div className="border ms-2 me-3 border-1 rounded">
              <div className="row pt-2 ps-3 pe-3">
                <div className="col-10 pb-2 pe-3">
                  <h2 className="tittleStyle">Voice Labeling - {file_name}</h2>
                </div>
                <div className="col-2 text-end ps-3">
                  <span
                    onClick={() => {
                      //close();
                      setVoiceStorageLink("");
                      setPatientLevelOfFunctioning({
                        emotional: false,
                        peer: false,
                        executive: false,
                        academic: false,
                        relational: false,
                      });
                      setPatientConfidenceVoice({
                        fear: false,
                        anger: false,
                        sadness: false,
                        neutral: false,
                        happy: false,
                      });
                      setAddVoiceLabelPopUpDisplay("none");
                      window.location.reload();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      //className="alignRightReset"
                    >
                      <path
                        id="maindiv"
                        d="M22.8749 7.13752C22.3874 6.65002 21.5999 6.65002 21.1124 7.13752L14.9999 13.2375L8.8874 7.12502C8.3999 6.63752 7.6124 6.63752 7.1249 7.12502C6.6374 7.61252 6.6374 8.40002 7.1249 8.88752L13.2374 15L7.1249 21.1125C6.6374 21.6 6.6374 22.3875 7.1249 22.875C7.6124 23.3625 8.3999 23.3625 8.8874 22.875L14.9999 16.7625L21.1124 22.875C21.5999 23.3625 22.3874 23.3625 22.8749 22.875C23.3624 22.3875 23.3624 21.6 22.8749 21.1125L16.7624 15L22.8749 8.88752C23.3499 8.41252 23.3499 7.61252 22.8749 7.13752V7.13752Z"
                        fill="#0A4364"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="row pt-1 ps-3 pe-1">
                <div>
                  <AudioPlayer
                    src={voice_storage_link}
                    onPlayError={async () => {
                      if (voice_storage_link !== "") {
                        notify("Link Expired", "error");

                        const responseLogout: AxiosResponse =
                          await Api.postLogout(
                            sessionStorage.getItem("token")!.toString(),
                            {
                              userId: sessionStorage.getItem("userId"),
                            }
                          );
                          console.log(responseLogout)
                      }
                    }}
                    // Try other props!
                  />
                </div>
              </div>
              {displayDate === true ? (
                <div className="border-top">
                  <div className="row pt-4 ps-5">
                    <div className="col-md-4">
                      <DatePicker
                        maxDate={new Date()}
                        selected={selectedSurveyDate}
                        onChange={(value: any) => {
                          // console.log(value);
                          setSelectedSurveyDate(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div>
                <div className="m-2 pt-2">
                  <div
                    className="row"
                    style={{ overflowY: "auto", height: "65vh" }}
                  >
                    <div className="row pt-4 ps-5 ">
                      <h5 className="fontType">Patient Level of functioning</h5>
                      <table
                        className="table table-bordered table-striped tableCollapse pt-0 p-0"
                        style={{ width: "inherit" }}
                      >
                        <thead>
                          <tr>
                            <th className="p-2"></th>
                            <th>Extremely Low</th>
                            <th>Low Functioning</th>
                            <th>Age Appropriate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2">Emotional Functioning</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"emotional"}
                                key={"emotional_exteremly_low"}
                                value="exteremly_low"
                                checked={
                                  patientLevelOfFunctioning.emotional ===
                                  "exteremly_low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"emotional"}
                                key={"emotional_low"}
                                value="low"
                                checked={
                                  patientLevelOfFunctioning.emotional === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"emotional"}
                                key={"emotional_normal"}
                                value="normal"
                                checked={
                                  patientLevelOfFunctioning.emotional ===
                                  "normal"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2">
                              Interpersonal/Peer Functioning
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"peer"}
                                key={"peer_exteremly_low"}
                                value="exteremly_low"
                                checked={
                                  patientLevelOfFunctioning.peer ===
                                  "exteremly_low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"peer"}
                                key={"peer_low"}
                                value="low"
                                checked={
                                  patientLevelOfFunctioning.peer === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"peer"}
                                key={"peer_normal"}
                                value="normal"
                                checked={
                                  patientLevelOfFunctioning.peer === "normal"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2">
                              Cognitive/Executive Functioning
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"executive"}
                                key={"executive_exteremly_low"}
                                value="exteremly_low"
                                checked={
                                  patientLevelOfFunctioning.executive ===
                                  "exteremly_low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"executive"}
                                key={"executive_low"}
                                value="low"
                                checked={
                                  patientLevelOfFunctioning.executive === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"executive"}
                                key={"executive_normal"}
                                value="normal"
                                checked={
                                  patientLevelOfFunctioning.executive ===
                                  "normal"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                          </tr>
                          {/* <tr>
                            <td className="p-2">Academic Functioning</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"academic"}
                                key={"academic_exteremly_low"}
                                value="exteremly_low"
                                checked={
                                  patientLevelOfFunctioning.academic ===
                                  "exteremly_low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"academic"}
                                key={"academic_low"}
                                value="low"
                                checked={
                                  patientLevelOfFunctioning.academic === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"academic"}
                                key={"academic_normal"}
                                value="normal"
                                checked={
                                  patientLevelOfFunctioning.academic ===
                                  "normal"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                          </tr> */}
                          <tr>
                            <td className="p-2">
                              Familial Relational Functioning
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"relational"}
                                key={"relational_exteremly_low"}
                                value="exteremly_low"
                                checked={
                                  patientLevelOfFunctioning.relational ===
                                  "exteremly_low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"relational"}
                                key={"relational_low"}
                                value="low"
                                checked={
                                  patientLevelOfFunctioning.relational === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"relational"}
                                key={"relational_normal"}
                                value="normal"
                                checked={
                                  patientLevelOfFunctioning.relational ===
                                  "normal"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoice}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row  pt-4 ps-5">
                      <h5 className="fontType">
                        Patient Voice Type / Confidence Level
                      </h5>
                      {/* <div className="ps-4 pe-0"> */}
                      <table className="table table-bordered  table-striped tableCollapse ">
                        <thead>
                          <tr>
                            <th className="p-2"></th>
                            <th className="p-2">None</th>
                            <th className="p-2">Low</th>
                            <th className="p-2">Medium</th>
                            <th className="p-2">High</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2">Fear</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"fear"}
                                key={"fear_none"}
                                value="none"
                                checked={patientConfidenceVoice.fear === "none"}
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"fear"}
                                key={"fear_low"}
                                value="low"
                                checked={patientConfidenceVoice.fear === "low"}
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"fear"}
                                key={"fear_medium"}
                                value="medium"
                                checked={
                                  patientConfidenceVoice.fear === "medium"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"fear"}
                                key={"fear_high"}
                                value="high"
                                checked={patientConfidenceVoice.fear === "high"}
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2">Anger</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"anger"}
                                key={"anger_none"}
                                value="none"
                                checked={
                                  patientConfidenceVoice.anger === "none"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"anger"}
                                key={"anger_low"}
                                value="low"
                                checked={patientConfidenceVoice.anger === "low"}
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"anger"}
                                key={"anger_medium"}
                                value="medium"
                                checked={
                                  patientConfidenceVoice.anger === "medium"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"anger"}
                                key={"anger_high"}
                                value="high"
                                checked={
                                  patientConfidenceVoice.anger === "high"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2">Sadness</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"sadness"}
                                key={"sadness_none"}
                                value="none"
                                checked={
                                  patientConfidenceVoice.sadness === "none"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"sadness"}
                                key={"sadness_low"}
                                value="low"
                                checked={
                                  patientConfidenceVoice.sadness === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"sadness"}
                                key={"sadness_medium"}
                                value="medium"
                                checked={
                                  patientConfidenceVoice.sadness === "medium"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"sadness"}
                                key={"sadness_high"}
                                value="high"
                                checked={
                                  patientConfidenceVoice.sadness === "high"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2">Neutral</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"neutral"}
                                key={"neutral_none"}
                                value="none"
                                checked={
                                  patientConfidenceVoice.neutral === "none"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"neutral"}
                                key={"neutral_low"}
                                value="low"
                                checked={
                                  patientConfidenceVoice.neutral === "low"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"neutral"}
                                key={"neutral_medium"}
                                value="medium"
                                checked={
                                  patientConfidenceVoice.neutral === "medium"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"neutral"}
                                key={"neutral_high"}
                                value="high"
                                checked={
                                  patientConfidenceVoice.neutral === "high"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2">Happy</td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"happy"}
                                key={"happy_none"}
                                value="none"
                                checked={
                                  patientConfidenceVoice.happy === "none"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"happy"}
                                key={"happy_low"}
                                value="low"
                                checked={patientConfidenceVoice.happy === "low"}
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"happy"}
                                key={"happy_medium"}
                                value="medium"
                                checked={
                                  patientConfidenceVoice.happy === "medium"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                className="radioBtn form-check-input"
                                name={"happy"}
                                key={"happy_high"}
                                value="high"
                                checked={
                                  patientConfidenceVoice.happy === "high"
                                }
                                disabled={viewMode}
                                onChange={onChangeVoiceType}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {viewMode !== true ? (
                      <div>
                        <div className="ps-4 pt-2 pb-3">
                          <CustomButton
                            buttonName="Submit"
                            disabled={disabledFlag}
                            OnClickHandler={async (e) => {
                              setSaveVoiceLabelPopUp("block");
                            }}
                          />
                          <span className="ps-4">
                            <CustomButton
                              disabled={false}
                              buttonName={`${""}Close`}
                              OnClickHandler={async (e) => {
                                setPatientLevelOfFunctioning({
                                  emotional: false,
                                  peer: false,
                                  executive: false,
                                  academic: false,
                                  relational: false,
                                });
                                setPatientConfidenceVoice({
                                  fear: false,
                                  anger: false,
                                  sadness: false,
                                  neutral: false,
                                  happy: false,
                                });
                                setAddVoiceLabelPopUpDisplay("none");
                                window.location.reload();
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </PopUp>
          <PopUp
            popUpDisplay={saveVoiceLabelPopUp}
            popUpContentWidth="28%"
            contentLeft="30%"
            contentTop="20%"
          >
            <span className="svgAlign">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                onClick={() => {
                  setSaveVoiceLabelPopUp("none");
                }}
              >
                <path
                  d="M22.8749 7.13752C22.3874 6.65002 21.5999 6.65002 21.1124 7.13752L14.9999 13.2375L8.8874 7.12502C8.3999 6.63752 7.6124 6.63752 7.1249 7.12502C6.6374 7.61252 6.6374 8.40002 7.1249 8.88752L13.2374 15L7.1249 21.1125C6.6374 21.6 6.6374 22.3875 7.1249 22.875C7.6124 23.3625 8.3999 23.3625 8.8874 22.875L14.9999 16.7625L21.1124 22.875C21.5999 23.3625 22.3874 23.3625 22.8749 22.875C23.3624 22.3875 23.3624 21.6 22.8749 21.1125L16.7624 15L22.8749 8.88752C23.3499 8.41252 23.3499 7.61252 22.8749 7.13752V7.13752Z"
                  fill="#0A4364"
                />
              </svg>
            </span>

            <h4 className="topHeaderTitle textCenterHeading">
              Save responses?
            </h4>
            <div className="row" style={{ justifyContent: "flex-end" }}>
              <div className="col-md-5">
                <CustomButton
                  disabled={false}
                  buttonName="Save"
                  OnClickHandler={async (e) => {
                    saveVoiceLabeling();
                    //submitData();
                  }}
                  logo={doneIcon}
                />
              </div>
              <div className="col-md-6">
                <CustomButton
                  disabled={false}
                  buttonName="Keep working"
                  OnClickHandler={async (e) => {
                    setSaveVoiceLabelPopUp("none");
                  }}
                  logo={backButton}
                />
              </div>
            </div>
          </PopUp>
          {loading === false ? (
            <div>
              <div className="row p-3 m-4">
                <h2> </h2>
              </div>

              <div
                className="backGroundWhite"
                style={{ borderRadius: "10px", padding: "2%" }}
              >
                <div className="">
                  <div
                    className="container"
                    // style={{ overflowY: "scroll", height: "57vh" }}
                  >
                    <div className="row text-start">
                      <div className="col-md-1 p-2">
                        <img
                          src={backButton}
                          alt="Previous page arrow"
                          onClick={goBack}
                          className="backArrow"
                        ></img>
                      </div>
                    </div>
                    <div className="container divVoiceSample">
                      <div className="row text-center">
                        <h4>
                          Patient Code: {params.patient_code}&nbsp; &nbsp; Date:{" "}
                          {params.date}
                        </h4>
                      </div>
                      <div className="row d-flex flex-column align-items-center">
                        {voiceNoteListArr.length !== 0 ? (
                          <>
                            {voiceNoteListArr.map((item: any, index: any) => {
                              return (
                                <>
                                  {/* <div className="col-4"></div> */}
                                  {/* {alert(item)} */}
                                  <div className="col-4 bgColor">
                                    {item.voice_type === "original" ? (
                                      <span>
                                        {item.voice_type[0].toUpperCase() +
                                          item.voice_type.substring(1)}
                                      </span>
                                    ) : (
                                      <span>
                                        {item.voice_type[0].toUpperCase() +
                                          item.voice_type.substring(1) +
                                          " " +
                                          index}
                                      </span>
                                    )}

                                    <span className="alignRight">
                                      {item.ownUser === true &&
                                      item.voice_sample_id !== null ? (
                                        <i
                                          className="fa fa-eye paddingLeft25"
                                          title="View"
                                          onClick={() => {
                                            openVoice(
                                              item.survey_voice_sample_id,
                                              item.voice_labeling_id,
                                              true,
                                              item.Date,
                                              item.split_voice_links,
                                              item.voice_type === "original"
                                                ? item.voice_type[0].toUpperCase() +
                                                    item.voice_type.substring(1)
                                                : item.voice_type[0].toUpperCase() +
                                                    item.voice_type.substring(
                                                      1
                                                    ) +
                                                    " " +
                                                    index
                                            );
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-plus"
                                          title="Add"
                                          onClick={() => {
                                            openVoice(
                                              item.survey_id,
                                              item.survey_voice_sample_id,
                                              false,
                                              item.Date,
                                              item.split_voice_links,
                                              item.voice_type === "original"
                                                ? item.voice_type[0].toUpperCase() +
                                                    item.voice_type.substring(1)
                                                : item.voice_type[0].toUpperCase() +
                                                    item.voice_type.substring(
                                                      1
                                                    ) +
                                                    " " +
                                                    index
                                            );
                                          }}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  {/* <div className="col-4"></div> */}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="row text-center">
                              <span className="NoDataColor">
                                No Voice-Sample taken for this survey
                              </span>
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </CenterOfScreen>
    </React.Fragment>
  );
};

export default VoiceLabeling;
