import { AxiosResponse } from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Api } from "../Api/ApiClient";
import { getUsers, getOrganizations } from "../Api/supervisorResponse";
import { getUserData } from "../Api/responsesStructure";
import CenterOfScreen from "../Utils/CenterOfScreen";
import NavBarCont from "../Utils/NavBarCont";
import { setNavItems } from "../Utils/NavItems";
import SearchBarTable from "../Utils/SearchBarTable";
import Spinner from "../Utils/Spinner";
import "../ComponentCSS/SuperAdminUsers.css";
import backIcon from "../Assets/arrow_back-dark.svg";
import { useForm } from "react-hook-form";
import { getSitesResponse } from "../Api/SuperAdminResponses";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getSupervisors } from "../Api/GetSupervisiors";
import { Property } from "csstype";
import { validateEmail } from "./Login";
import { users } from "../Api/users";
import { toast } from "react-toastify";
// import { Form } from "react-bootstrap";
var getSites: getSitesResponse;

const notify = (message: string, type: any) => {
  if (type == "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 2500,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.info(message, {
      position: "top-right",
      autoClose: 2000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
interface tableOfUsers {
  Role?: string;
  "First Name"?: string;
  "Last Name"?: string;
  Email?: string;
  Status?: string;
  Action?: ActionType;
}

interface ActionType {
  fName?: string;
  tCode?: string;
  LName?: string;
  email?: string;
  status?: string;
  sites?: string;
  role?: string;
  dob?: string;
  user_status?: string;
  test_user?: string;
}
interface ActionFormProps {
  formData: ActionType;
  setCancelState: React.Dispatch<React.SetStateAction<boolean>>;
}
// export interface UserMetaPost {
//   therapistId?: string;
//   supervisorId?: string;
//   superAdminId?: string;
//   orgId?: string;
//   statusFlag?: string;
//   status?: string;
// }

export interface UpdateUserPost {
  role?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  dob?: string;
  userMeta?: {};
  sites?: string;
  role_id?: string;
  user_id?: string;
  user_status?: string;
  user_code?: string;
  test_user?: string;
  org_id?: string;
}

interface NewFormProps {
  setCancelState: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface NewUserPost {
  role?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  dob?: string;
  tenantId?: string;
  type?: string;
  userMeta?: {};
  sites?: string;
  //  sid?: string;
  user_id?: string;
  test_user?: string;
  role_id?: string;
  user_code?: string;
  org_id?: string;
  logged_in_user?: string;
}
// export interface UserMetaPostNew {
//   therapistId?: string;
//   supervisorId?: string;
//   superAdminId?: string;
//   UMId?: string;
//   voiceLabeling?: string;
//   spAdminId?: string;
//   orgId?: string;
// }

var BaseTable: tableOfUsers[];

const NewForm: React.FC<NewFormProps> = ({ setCancelState }) => {
  const [getSupervisorsForOrg, setGetSupervisorsForOrg] = useState<users>();
  const [supervisorsVisibility, setSupervisorsVisibility] =
    useState<Property.Display>("none");
  const firstNameRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const lastNameRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const emailRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const codeRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const testRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);

  const rolRef: React.RefObject<HTMLSelectElement> =
    useRef<HTMLSelectElement>(null);
  const supsRef: React.RefObject<HTMLSelectElement> =
    useRef<HTMLSelectElement>(null);
  var objToPostNewUser: NewUserPost = { userMeta: {} };
  const [checkboxValue, setCheckboxValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [rolesList, setRoleList] = useState<any>();
  useEffect(() => {
    getSupervisors();
  }, []);

  useEffect(() => {
    getRoleList();
  }, []);

  const getSupervisors = async () => {
    try {
      const getSupervisorsResponse: AxiosResponse = await Api.users(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("orgId")!.toString(),
        "supervisor"
      );

      const getSupervisorsData: users = getSupervisorsResponse.data;
      setGetSupervisorsForOrg((prev) => (prev = getSupervisorsData));
      // alert(JSON.stringify(getSupervisorsData.success.data));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const getRoleList = async () => {
    try {
      const getRoleListResponse: AxiosResponse = await Api.getRolesList(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("Role")!,
        sessionStorage.getItem("orgId")!.toString()
      );

      setLoading((prev) => (prev = false));
      const getRoleListResponseData = getRoleListResponse.data;
      setRoleList(getRoleListResponseData.success.data.roles);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const inputHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id: string = e.target.id;

    switch (id) {
      case "fName":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser.test_user = testRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];

        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostNewUser.logged_in_user = sessionStorage.getItem("userId")!;
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;

        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          objToPostNewUser.user_id = supsRef.current!.value;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }

        break;
      case "Email":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.test_user = testRef.current!.value;
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostNewUser.logged_in_user = sessionStorage.getItem("userId")!;
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;

        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          objToPostNewUser.user_id = supsRef.current!.value;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }
        break;
      case "checkbox":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.test_user = testRef.current!.value;
        // objToPostNewUser.test_user =(e.target as HTMLInputElement).value
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;
        // objToPostNewUser.logged_in_user =sessionStorage.getItem("userId")!;
        // objToPostNewUser.role_id ="1";
        // if(rolRef.current!.value=="Therapist"){
        //   objToPostNewUser.role_id ="1";
        // }
        // else if(rolRef.current!.value=="Admin"){
        //   objToPostNewUser.role_id ="5";
        // }

        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;

        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          // objToPostNewUser.user_id = supsRef.current!.value;
          objToPostNewUser.user_id = sessionStorage.getItem("supervisorId")!;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }
        break;
      case "Code":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser.test_user = testRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostNewUser.logged_in_user = sessionStorage.getItem("userId")!;
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;

        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          objToPostNewUser.user_id = supsRef.current!.value;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }
        break;
      case "LName":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser.test_user = testRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostNewUser.logged_in_user = sessionStorage.getItem("userId")!;
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          objToPostNewUser.user_id = supsRef.current!.value;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }
        break;
      case "drpRole":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser.test_user = testRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostNewUser.logged_in_user = sessionStorage.getItem("userId")!;
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          objToPostNewUser.user_id = supsRef.current!.value;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }
        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          setSupervisorsVisibility((prev) => (prev = "Block"));
          objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        } else {
          setSupervisorsVisibility((prev) => (prev = "none"));
          objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        }
        break;
      case "drpSups":
        objToPostNewUser.firstName = firstNameRef.current!.value;
        objToPostNewUser.lastName = lastNameRef.current!.value;
        objToPostNewUser.email = emailRef.current!.value;
        objToPostNewUser.user_code = codeRef.current!.value;
        objToPostNewUser.test_user = testRef.current!.value;
        objToPostNewUser!.dob = moment().toISOString();
        objToPostNewUser.middleName = " ";
        objToPostNewUser.mobile = " ";
        // objToPostNewUser.userMeta!.UMId = " ";
        // objToPostNewUser.userMeta!.voiceLabeling = "undefined";
        // objToPostNewUser.userMeta!.spAdminId = "undefined";
        objToPostNewUser.tenantId = sessionStorage.getItem("TenantId")!;
        objToPostNewUser.type = "Tenant User";
        objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        objToPostNewUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostNewUser.org_id = sessionStorage.getItem("orgId")!;

        objToPostNewUser.logged_in_user = sessionStorage.getItem("userId")!;
        objToPostNewUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;

        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          // objToPostNewUser.userMeta!.therapistId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.supervisorId = "";
          objToPostNewUser.user_id = supsRef.current!.value;
        } else {
          // objToPostNewUser.userMeta!.supervisorId = codeRef.current!.value;
          // objToPostNewUser.userMeta!.superAdminId = "";
          // objToPostNewUser.userMeta!.therapistId = "";
          objToPostNewUser.user_id = "null";
        }
        if (rolRef.current!.value.split(",")[0] === "Therapist") {
          setSupervisorsVisibility((prev) => (prev = "visible"));
          objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        } else {
          setSupervisorsVisibility((prev) => (prev = "visible"));
          objToPostNewUser.role = rolRef.current!.value.split(",")[0];
        }
        break;
    }
  };
  let userCode = "";
  const onSaveClickHandler = async () => {
    // alert("res"+ JSON.stringify(objToPostNewUser))
    if(objToPostNewUser.user_code == ""  || objToPostNewUser.user_code == null || objToPostNewUser.user_code == undefined){
      notify("User code is mandatory","error");
    }
    else if(objToPostNewUser.firstName == ""  || objToPostNewUser.firstName == null || objToPostNewUser.firstName == undefined){
      notify("First Name is mandatory","error");
    }
    else if(objToPostNewUser.lastName == ""  || objToPostNewUser.lastName == null || objToPostNewUser.lastName == undefined){
      notify("Last Name is mandatory","error");
    }
    else if(objToPostNewUser.email == ""  || objToPostNewUser.email == null || objToPostNewUser.email == undefined){
      notify("Email is mandatory","error");
    }
    else if(objToPostNewUser.role == ""  || objToPostNewUser.role == null || objToPostNewUser.role == undefined){
      notify("Role is mandatory","error");
    }
    else{
    const createUserResponse: AxiosResponse = await Api.createUserPostList(
      objToPostNewUser,
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      sessionStorage.getItem("TenantId")!,
      userCode
    );
    createUserResponse.data.success === undefined
      ? notify(createUserResponse.data.error.msg, "error")
      : notify(createUserResponse.data.success.msg, "info");
      if(createUserResponse.data.success != undefined){
        window.location.reload()
      }
      
    }
    // setDisplayMainTable((prev) => (prev = true));
    // validateEmail(objToPostNewUser.email!) === true
    //   ? console.log(JSON.stringify(objToPostNewUser))
    //   : alert("either fields are empty and not valid");

    //alert(JSON.stringify(objToPostNewUser));
  };
  const handleCheckBoxChange = (e: any, ref: any) => {
    if (e.target.checked) {
      ref.current = { value: e.target.value };
      inputHandler(e);
    }
  };
  return (
    <div
      className="skyBlue p-3"
      style={{
        borderRadius: "12px",
      }}
    >
      {loading === false ? (
        <>
          <p className="Action_Title text-center">New User</p>
          <form name="myForms">
            <div id="form-Div" className="ps-1 pe-1 pt-2">
              <div className="row">
                <div id="organizationName">
                  <p>Organization Name*</p>
                  <input
                    id="orgName"
                    //ref={emailRef}
                    value={sessionStorage.getItem("domain")!.toString()}
                    disabled={true}
                    className="boxesClass border-0"
                    style={{
                      backgroundColor: "rgb(208 231 234)",
                      color: "black",
                    }}
                    required={true}
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                  ></input>
                </div>

                <div className="col-6 pt-3">
                  <div id="dropDownHolder">
                    <p>Role*</p>
                    <select
                      id="drpRole"
                      className="boxesClass skyBlue"
                      ref={rolRef}
                      required={true}
                      onChange={(e) => {
                        inputHandler(e);
                      }}
                      style={{ width: "100%" }}
                    >
                      {rolesList &&
                        rolesList.map((items: any) => (
                          <option value={`${items.role},${items.role_id}`}>
                            {items.role}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div id="inputBox holder" className="pt-3 form-group">
                    <p>First Name*</p>
                    <input
                      className=" form-control boxesClass border border-white"
                      id="fName"
                      ref={firstNameRef}
                      placeholder="John"
                      required={true}
                      onChange={(e) => {
                        inputHandler(e);
                      }}
                      readOnly={false}
                      //placeholder={formData.fName}
                    ></input>
                    <span className="text-danger" id="Fname"></span>
                  </div>
                  <div id="inputBox holder" className="pt-3 ">
                    <p>Email*</p>
                    <input
                      id="Email"
                      type="email"
                      ref={emailRef}
                      className="boxesClass border border-white"
                      placeholder={`abc@${sessionStorage.getItem("domain")!}`}
                      required={true}
                      onChange={(e) => {
                        inputHandler(e);
                      }}
                    ></input>
                    <span className="text-danger" id="email"></span>
                  </div>
                  <div
                    id="dropDownHolder"
                    className="pt-3"
                    style={{ display: `${supervisorsVisibility}` }}
                  >
                    <p>Supervisors*</p>
                    <select
                      id="drpSups"
                      ref={supsRef}
                      className="boxesClass skyBlue"
                      required={true}
                      onChange={(e) => {
                        inputHandler(e);
                      }}
                      style={{ width: "100%" }}
                    >
                      {getSupervisorsForOrg &&
                        getSupervisorsForOrg.success.data!.map((item, key) => (
                          <React.Fragment>
                            <option value={item.user_id} key={key++}>
                              {item.first_name}
                            </option>
                            {/* <option value="Inactive">{item.}</option> */}
                          </React.Fragment>
                        ))}
                    </select>
                  </div>
                  {/* <div id="buttonHolder" className="pt-5 buttonCenter">
              <div
                className="buttonAction p-3 text-center"
                onClick={onSaveClickHandler}
              >
                Save
              </div>
            </div> */}
                </div>
                <div className="col-6 pt-3">
                  <div id="inputBox holder" style={{ visibility: "visible" }}>
                    <p>User Code*</p>
                    <input
                      id="Code"
                      ref={codeRef}
                      className="boxesClass"
                      required={true}
                      onChange={(e) => {
                        inputHandler(e);
                      }}
                    ></input>
                    <span className="text-danger" id="code"></span>
                  </div>
                  <div id="inputBox holder" className="pt-3">
                    <p>Last Name*</p>
                    <input
                      id="LName"
                      ref={lastNameRef}
                      className="boxesClass border border-white"
                      placeholder="Fernandes"
                      required={true}
                      onChange={(e) => {
                        inputHandler(e);
                      }}
                    ></input>
                    <span className="text-danger" id="lName"></span>
                  </div>
                  {/* <div
              id="dropDownHolder"
              className="pt-3"
              style={{ visibility: `${supervisorsVisibility}` }}
              >
              <p>Supervisors*</p>
              <select
                id="drpSups"
                ref={supsRef}
                className="boxesClass skyBlue"
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
                style={{ width: "100%" }}
              >
                {getSupervisorsForOrg &&
                  getSupervisorsForOrg.success.data?.map((item, key) => (
                    <React.Fragment>
                      <option value={item.supervisor_id} key={key++}>
                        {item.first_name}
                      </option>
                      {/* <option value="Inactive">{item.}</option> */}
                  {/* </React.Fragment> */}
                  {/* ))} */}
                  {/* </select> */}
                  {/* </div> */}
                  <div id="inputBox holder" className="pt-3 ">
                    <p>User Type*</p>
                    {/* <Checkbox
        label="My Value"
        id="checkbox"
        type="checkbox"
        ref={testRef}
        className="boxesClass checkBoxStyle"
        checked={checkedOne}
        onChange={(e:any) => {
          updateOne         
          inputHandler(e);
        }}
        
      /> */}

                    {/* <select
                id="drpRole"
                className="boxesClass skyBlue"
                ref={testRef}
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
                style={{ width: "100%" }}
              >
                <option value={0}>Real User</option>
                <option value={1}>Test User</option>
              </select> */}
                    <div>
                      <div className="d-flex">
                        <input
                          value={0}
                          name="testUser"
                          type="radio"
                          id="checkbox"
                          ref={testRef}
                          onClick={(e) => {
                            handleCheckBoxChange(e, testRef);
                          }}
                        />
                        Real
                        <input
                          value={1}
                          name="testUser"
                          type="radio"
                          id="checkbox"
                          ref={testRef}
                          onClick={(e) => {
                            handleCheckBoxChange(e, testRef);
                          }}
                        />
                        Test
                        <input
                          value={2}
                          name="testUser"
                          type="radio"
                          id="checkbox"
                          ref={testRef}
                          onClick={(e) => {
                            handleCheckBoxChange(e, testRef);
                          }}
                        />{" "}
                        Both
                      </div>
                    </div>

                    {/* <div id="buttonHolder" className="pt-5 buttonCenter">
              <div
                className="buttonAction p-3 text-center"
                onClick={() => {
                  //setMainDiv((prev) => (prev = true));
                  setCancelState((prev) => (prev = true));
                }}
              >
                Cancel
           </div>
            </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pt-3">
                  <div id="buttonHolder" className="pt-5 buttonCenter">
                    <div
                      className="buttonAction p-3 text-center"
                      onClick={onSaveClickHandler}
                    >
                      Save
                    </div>
                  </div>
                </div>
                <div className="col-6 pt-3">
                  <div id="buttonHolder" className="pt-5 buttonCenter">
                    <div
                      className="buttonAction p-3 text-center"
                      onClick={() => {
                        //setMainDiv((prev) => (prev = true));
                        setCancelState((prev) => (prev = true));
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-6 pt-3">
              <div id="buttonHolder" className="pt-5 buttonCenter">
              <div
                className="buttonAction p-3 text-center"
                onClick={() => {
                  //setMainDiv((prev) => (prev = true));
                  setCancelState((prev) => (prev = true));
                }}
              >
                Cancel
              </div>
            </div>
        </div> */}
            </div>
          </form>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

///Action component
const ActionForm: React.FC<ActionFormProps> = ({
  formData,
  setCancelState,
}) => {
  const firstNameRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const lastNameRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const emailRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const codeRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const testRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const rolRef: React.RefObject<HTMLSelectElement> =
    useRef<HTMLSelectElement>(null);
  const statusRef: React.RefObject<HTMLSelectElement> =
    useRef<HTMLSelectElement>(null);
  var obj: ActionType = formData;
  var objToPostUpdateUser: UpdateUserPost = { userMeta: {} };
  const [rolesList, setRoleList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkingRadio, setCheckingRadio] = useState<string>();
  useEffect(() => {
    firstNameRef.current!.value = obj.fName!;
    lastNameRef.current!.value = obj.LName!;
    codeRef.current!.value = obj.tCode!;
    rolRef.current!.value = obj.role!;
    testRef.current!.value = obj.test_user!;
    statusRef.current!.value = obj.status!;
    setCheckingRadio(obj.test_user);
    preProcessObjToPost(obj.role!);
    // getRolesApiCall();
  }, []);
  useEffect(() => {
    getRoleList();
  }, []);
  const preProcessObjToPost = (role: string) => {
    // objToPostUpdateUser.role =
    //   role === "Therapist" ? "Therapist" : "Supervisor";
    objToPostUpdateUser.middleName = "";
    objToPostUpdateUser.firstName = obj.fName;
    objToPostUpdateUser.lastName = obj.LName;
    objToPostUpdateUser.sites = obj.sites;
    objToPostUpdateUser.mobile = "";
    objToPostUpdateUser.dob = obj.dob!;
    objToPostUpdateUser.email = obj.email!;

    objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
    objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
    objToPostUpdateUser.user_status = statusRef.current!.value;
    objToPostUpdateUser.userMeta = {};
    // objToPostUpdateUser.user_code=obj.user_code;
    objToPostUpdateUser.test_user = testRef.current!.value;

    objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
    objToPostUpdateUser.user_code = codeRef.current!.value;
    // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
    // objToPostUpdateUser.userMeta!.status = obj.status!.toLowerCase();
    // objToPostUpdateUser.userMeta!.statusFlag = "true";
    // objToPostUpdateUser.userMeta!.superAdminId = "undefined";
    // objToPostUpdateUser.userMeta!.therapistId =
    //   obj.role === "Therapist" ? obj.tCode : "undefined";
    // objToPostUpdateUser.userMeta!.supervisorId =
    //   obj.role === "Therapist" ? "undefined" : obj.tCode;
  };
  const getRoleList = async () => {
    try {
      const getRoleListResponse: AxiosResponse = await Api.getRolesList(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("Role")!,
        sessionStorage.getItem("orgId")!.toString()
      );

      setLoading((prev) => (prev = false));
      const getRoleListResponseData = getRoleListResponse.data;
      setRoleList(getRoleListResponseData.success.data.roles);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const inputHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id: string = e.target.id;

    switch (id) {
      case "fName":
        //alert("fname");
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
        // objToPostUpdateUser.userMeta!.therapistId =
        //   obj.role === "Therapist" ? codeRef.current!.value : "undefined";
        // objToPostUpdateUser.userMeta!.supervisorId =
        //   obj.role === "Therapist" ? "undefined" : codeRef.current!.value;
        // objToPostUpdateUser.userMeta!.status =
        //   statusRef.current!.value.toLocaleLowerCase();
        objToPostUpdateUser.user_status = statusRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        //////
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.sites = obj.sites;
        objToPostUpdateUser.dob = obj.dob!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.userMeta!.statusFlag = "true";
        // objToPostUpdateUser.userMeta!.superAdminId = "undefined";

        break;
      case "Email":
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
        // objToPostUpdateUser.userMeta!.therapistId =
        //   obj.role === "Therapist" ? codeRef.current!.value : "undefined";
        // objToPostUpdateUser.userMeta!.supervisorId =
        //   obj.role === "Therapist" ? "undefined" : codeRef.current!.value;
        // objToPostUpdateUser.userMeta!.status =
        //   statusRef.current!.value.toLocaleLowerCase();
        objToPostUpdateUser.user_status = statusRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        //////
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.sites = obj.sites;
        objToPostUpdateUser.dob = obj.dob!;
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.userMeta!.statusFlag = "true";
        // objToPostUpdateUser.userMeta!.superAdminId = "undefined";
        break;
      case "Code":
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
        // objToPostUpdateUser.userMeta!.therapistId =
        //   obj.role === "Therapist" ? codeRef.current!.value : "undefined";
        // objToPostUpdateUser.userMeta!.supervisorId =
        //   obj.role === "Therapist" ? "undefined" : codeRef.current!.value;
        // objToPostUpdateUser.userMeta!.status =
        objToPostUpdateUser.user_status = statusRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        //////
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.sites = obj.sites;
        objToPostUpdateUser.dob = obj.dob!;
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.userMeta!.statusFlag = "true";
        // objToPostUpdateUser.userMeta!.superAdminId = "undefined";
        break;
      case "LName":
        //alert("lname");
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
        // objToPostUpdateUser.userMeta!.therapistId =
        //   obj.role === "Therapist" ? codeRef.current!.value : "undefined";
        // objToPostUpdateUser.userMeta!.supervisorId =
        //   obj.role === "Therapist" ? "undefined" : codeRef.current!.value;
        // objToPostUpdateUser.userMeta!.status =
        //   statusRef.current!.value.toLocaleLowerCase();
        objToPostUpdateUser.user_status = statusRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        //////
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.sites = obj.sites;
        objToPostUpdateUser.dob = obj.dob!;
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.userMeta!.statusFlag = "true";
        // objToPostUpdateUser.userMeta!.superAdminId = "undefined";
        break;
      case "checkbox":
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        // objToPostUpdateUser.test_user = checkingRadio
        // objToPostUpdateUser.test_user =(e.target as HTMLInputElement).value
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser!.dob = moment().toISOString();
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.mobile = " ";

        // objToPostUpdateUser.userMeta!.UMId = " ";
        // objToPostUpdateUser.userMeta!.voiceLabeling = "undefined";
        // objToPostUpdateUser.userMeta!.spAdminId = "undefined";
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        // objToPostUpdateUser.logged_in_user =sessionStorage.getItem("userId")!;
        // objToPostUpdateUser.role_id ="1";
        // if(rolRef.current!.value=="Therapist"){
        //   objToPostUpdateUser.role_id ="1";
        // }
        // else if(rolRef.current!.value=="Admin"){
        //   objToPostUpdateUser.role_id ="5";
        // }

        // objToPostUpdateUser.type = "Tenant User";
        objToPostUpdateUser.role = rolRef.current!.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current!.value.split(",")[1];
        objToPostUpdateUser.sites = getSites.success.data.sites![0]._id;
        // objToPostNewUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;

        break;
      case "drpRole":
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
        // objToPostUpdateUser.userMeta!.therapistId =
        //   obj.role === "Therapist" ? codeRef.current!.value : "undefined";
        // objToPostUpdateUser.userMeta!.supervisorId =
        //   obj.role === "Therapist" ? "undefined" : codeRef.current!.value;
        // objToPostUpdateUser.userMeta!.status =
        //   statusRef.current!.value.toLocaleLowerCase();
        objToPostUpdateUser.user_status = statusRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        //////
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.sites = obj.sites;
        objToPostUpdateUser.dob = obj.dob!;
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.userMeta!.statusFlag = "true";
        // objToPostUpdateUser.userMeta!.superAdminId = "undefined";
        break;
      case "drpStatus":
        objToPostUpdateUser.firstName = firstNameRef.current!.value;
        objToPostUpdateUser.lastName = lastNameRef.current!.value;
        objToPostUpdateUser.role = rolRef.current?.value.split(",")[0];
        objToPostUpdateUser.role_id = rolRef.current?.value.split(",")[1];
        // objToPostUpdateUser.userMeta!.therapistId =
        //   obj.role === "Therapist" ? codeRef.current!.value : "undefined";
        // objToPostUpdateUser.userMeta!.supervisorId =
        //   obj.role === "Therapist" ? "undefined" : codeRef.current!.value;
        // objToPostUpdateUser.userMeta!.status =
        //   statusRef.current!.value.toLocaleLowerCase();
        objToPostUpdateUser.user_status = statusRef.current!.value;
        objToPostUpdateUser.email = emailRef.current!.value;
        objToPostUpdateUser.test_user = obj.test_user;
        //////
        objToPostUpdateUser.middleName = " ";
        objToPostUpdateUser.sites = obj.sites;
        objToPostUpdateUser.dob = obj.dob!;
        objToPostUpdateUser.org_id = sessionStorage.getItem("orgId")!;
        objToPostUpdateUser.user_code = codeRef.current!.value;
        objToPostUpdateUser.userMeta = {};
        objToPostUpdateUser.mobile = " ";
        // objToPostUpdateUser.userMeta!.orgId = sessionStorage.getItem("orgId")!;
        // objToPostUpdateUser.userMeta!.statusFlag = "true";
        // objToPostUpdateUser.userMeta!.superAdminId = "undefined";
        break;
    }
  };

  const onUpdateClickHandler = async () => {
    // alert(JSON.stringify(objToPostUpdateUser));

    const response: AxiosResponse = await Api.updateUserPostList(
      objToPostUpdateUser,
      sessionStorage.getItem("TenantId")!,
      encodeURIComponent(sessionStorage.getItem("token")!.toString())
    );
    response.data.status === undefined
    // ? alert("try again")
    // : alert(JSON.stringify(response.data.success));
    ? notify(response.data.error.msg, "error")
    : notify(response.data.msg, "info");
  window.location.reload();
    // alert(JSON.stringify(response.data.msg));
    // response.data.success === undefined
    //   ? notify(response.data.error.msg, "error")
    //   : notify(response.data.msg, "info");
    // ? alert("try again")
    // : alert(JSON.stringify(response.data.success));
  };
  const handleCheckBoxChange = (e: any) => {
    inputHandler(e);
  };
  return (
    <div
      className="skyBlue p-3"
      style={{
        borderRadius: "12px",
      }}
    >
      <p className="Action_Title text-center">User Details</p>

      <div id="form-Div" className="ps-4 pe-4 pt-4">
        <div className="row">
          <div className="col-6">
            <div id="dropDownHolder">
              <p>Role*</p>
              <select
                id="drpRole"
                className="boxesClass skyBlue"
                ref={rolRef}
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
                style={{ width: "100%" }}
              >
                {rolesList &&
                        rolesList.map((items: any) => (
                          <option value={`${items.role}, ${items.role_id}`}>
                            {items.role}
                          </option>
                        ))}
              </select>
            </div>
            <div id="inputBox holder" className="pt-3">
              <p>First Name*</p>
              <input
                className="boxesClass inputPlaceholder"
                id="fName"
                ref={firstNameRef}
                // value={obj.fName!}
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
                readOnly={false}
                //placeholder={formData.fName}
              ></input>
            </div>
            <div id="inputBox holder" className="pt-3 ">
              <p>Email*</p>
              <input
                id="Email"
                ref={emailRef}
                value={obj.email}
                disabled={true}
                className="boxesClass border-0 inputPlaceholder"
                style={{ backgroundColor: "rgb(208 231 234)", color: "black" }}
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
              ></input>
            </div>
            <div id="inputBox holder" className="pt-3 ">
              {/* <p>Test User*</p> */}
              <p>User Type</p>

              {/* <select
              id="drpRole"
              className="boxesClass skyBlue"
              ref={testRef}
              required={true}
              onChange={(e) => {
                inputHandler(e);
              }}
              style={{ width: "100%" }}
            >
              <option value={0}>Real User</option>
              <option value={0}>Test User</option>
            </select> */}

              <div>
                <div className="d-flex">
                  {checkingRadio === "0" ? (
                    <input
                      value={0}
                      name="testUser"
                      type="radio"
                      id="checkbox"
                      ref={testRef}
                      onClick={(e) => {
                        obj.test_user = "";
                        setCheckingRadio(obj.test_user);
                        handleCheckBoxChange(e);
                      }}
                      checked
                    />
                  ) : (
                    <input
                      value={0}
                      name="testUser"
                      type="radio"
                      id="checkbox"
                      ref={testRef}
                      onClick={(e) => {
                        obj.test_user = "0";
                        handleCheckBoxChange(e);
                      }}
                    />
                  )}
                  Real
                  {checkingRadio === "1" ? (
                    <input
                      value={1}
                      name="testUser"
                      type="radio"
                      id="checkbox"
                      ref={testRef}
                      onClick={(e) => {
                        obj.test_user = "";
                        setCheckingRadio(obj.test_user);
                        handleCheckBoxChange(e);
                      }}
                      checked
                    />
                  ) : (
                    <input
                      value={1}
                      name="testUser"
                      type="radio"
                      id="checkbox"
                      ref={testRef}
                      onClick={(e) => {
                        obj.test_user = "1";
                        handleCheckBoxChange(e);
                      }}
                    />
                  )}
                  Test
                  {checkingRadio === "2" ? (
                    <input
                      value={2}
                      name="testUser"
                      type="radio"
                      id="checkbox"
                      ref={testRef}
                      onClick={(e) => {
                        obj.test_user = "";
                        setCheckingRadio(obj.test_user);
                        handleCheckBoxChange(e);
                      }}
                      checked
                    />
                  ) : (
                    <input
                      value={2}
                      name="testUser"
                      type="radio"
                      id="checkbox"
                      ref={testRef}
                      onClick={(e) => {
                        obj.test_user = "2";
                        handleCheckBoxChange(e);
                      }}
                    />
                  )}
                  Both
                </div>
              </div>
            </div>
            <div id="buttonHolder" className="pt-4 buttonCenter">
              <div
                className="buttonAction p-3 text-center text_color_SkyBlue"
                onClick={onUpdateClickHandler}
              >
                Update
              </div>
            </div>
          </div>
          <div className="col-6">
            <div id="inputBox holder">
              <p>User Code*</p>
              <input
                id="Code"
                ref={codeRef}
                disabled={true}
                className="boxesClass inputPlaceholder"
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
              ></input>
            </div>
            <div id="inputBox holder" className="pt-3">
              <p>Last Name*</p>
              <input
                id="LName"
                ref={lastNameRef}
                className="boxesClass inputPlaceholder"
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
              ></input>
            </div>
            <div id="dropDownHolder" className="pt-3">
              <p>Status*</p>
              <select
                id="drpStatus"
                ref={statusRef}
                className="boxesClass skyBlue"
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
                style={{ width: "100%" }}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>{" "}
            <div id="buttonHolder" className="pt-5 buttonCenter">
              <div></div>
            </div>
            <div id="buttonHolder" className="pt-5 buttonCenter">
              <div
                className="buttonAction p-3 text-center text_color_SkyBlue"
                onClick={() => {
                  //setMainDiv((prev) => (prev = true));
                  setCancelState((prev) => (prev = true));
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

///////////////////////Main
const SuperAdminUsers: React.FC = () => {
  const [table, setTable] = useState<tableOfUsers[]>([{}]);
  const [loading, setLoading] = useState<boolean>(true);
  const [displayMainTable, setDisplayMainTable] = useState<boolean>(true);
  const [displayAction, setDisplayAction] = useState<boolean>(false);
  const [actionJson, setActionJson] = useState<ActionType>();
  const history = useHistory();
  var getUsersData: getUsers;
  var getUserResponse: getUserData;
  var getOrganizations: getOrganizations;

  var tableData: tableOfUsers[] = [];

  useEffect(() => {
    getUsersGetOrganization();
  }, []);

  const getUsersGetOrganization = async () => {
    const getOrganizationsResponse: AxiosResponse = await Api.getOrganizations(
      encodeURIComponent(sessionStorage.getItem("token")!.toString())
    );
    getOrganizations = getOrganizationsResponse.data;

    console.log(
      "Get organization response=" +
        JSON.stringify(getOrganizationsResponse.data)
    );

    const getUsersResponse: AxiosResponse = await Api.users(
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      sessionStorage.getItem("orgId")!.toString(),
      "spadmin"
    );

    //  getUsersData = getUsersResponse.data;
    getUserResponse = getUsersResponse.data;

    console.log("Get Users response" + JSON.stringify(getUsersResponse.data));

    const getSitesResponse: AxiosResponse = await Api.getSites(
      sessionStorage.getItem("TenantId")!.toString(),
      encodeURIComponent(sessionStorage.getItem("token")!.toString())
    );

    getSites = getSitesResponse.data;
    console.log("Sites=" + JSON.stringify(getSites.success.data.sites![0]));
    getSites.success !== undefined
      ? preProcessTableJsonArray(
          getUserResponse,
          getOrganizations,
          getSites.success.data.sites![0]._id
        )
      : history.push("/");
  };

  const searchStringHandler = (searchString: string) => {
    const byQuery = (searchValue: string) => (item: tableOfUsers) =>
      !searchString ||
      item["First Name"]?.toLowerCase().includes(searchValue.toLowerCase());

    const filteredList = table!.filter(byQuery(searchString));

    if (filteredList != null || filteredList != undefined) {
      console.log("IN IF" + JSON.stringify(filteredList));
      console.log("BASETABLE" + JSON.stringify(BaseTable));
      console.log("Search===" + searchString.length);
      filteredList.length != 0
        ? searchString.length === 0
          ? setTable((prev) => (prev = BaseTable))
          : setTable((prev) => (prev = filteredList))
        : setTable((prev) => (prev = BaseTable));
    } else {
      alert("no such record");
    }
  };

  const preProcessTableJsonArray = (
    users: getUserData,
    organization: getOrganizations,
    siteId: string
  ) => {
    if (users.success !== undefined && organization.status === "success") {
      setLoading((prev) => (prev = false));
      console.log("Checking users data" + JSON.stringify(users.success.data));

      for (let i = 0; i < users.success.data.length; i++) {
        for (let j = i; j <= i; j++) {
          tableData.push({
            Role: users.success!.data[i].role,
            // "full name": users.success!.data[i].full_name,
            "First Name": users.success!.data[i].first_name,
            "Last Name": users.success!.data[i].last_name,
            Email: users.success!.data[i].email,
            Status: users.success!.data[i].user_status,
            Action:
              users.success!.data[i].user_status === ""
                ? {}
                : {
                    LName: users.success!.data[i].last_name,
                    fName: users.success!.data[i].first_name,
                    email: users.success!.data[i].email,
                    tCode:
                      users.success!.data[i].user_code !== undefined || null
                        ? users.success!.data[i].user_code.toString()!
                        : users.success!.data[i].role_id!.toString()!,

                    status: users.success!.data[i].user_status.toString()!,
                    sites: siteId,
                    role: users.success!.data[i].role.toString()!,
                    dob: moment().toISOString(),
                    test_user:users.success!.data[i].test_user.toString()!
                  },
          });
        }
      }
    }
    setTable((prevData) => (prevData = tableData));
    BaseTable = tableData;
    console.log("TABLE ARRAY=" + JSON.stringify(tableData));
  };

  const OnActionClick = (actionValue: any) => {
    //alert("Action Data=" + JSON.stringify(actionValue));
    const ActionDataHolder: ActionType = actionValue;
    setActionJson((prev) => (prev = ActionDataHolder));
    setDisplayMainTable((prev) => (prev = false));
  };

  return (
    <div>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <CenterOfScreen>
        <div style={{ width: "70%", paddingTop: "5%" }}>
          {displayMainTable === true ? (
            <div id="Main table">
              {loading === true ? (
                <Spinner />
              ) : table != null || table != undefined ? (
                <SearchBarTable
                  spAdminUser={false}
                  Table={table}
                  tableBodyHeight="47vh"
                  TableHeight="55vh"
                  showNewButton={true}
                  placeHolderValue="Users"
                  onNewClickHandler={() => {
                    setDisplayMainTable((prev) => (prev = false));
                    setDisplayAction((prev) => (prev = false));
                  }}
                  SearchStringFunction={(searchStringValue: string) => {
                    searchStringHandler(searchStringValue);
                  }}
                  onActionClickHandler={(actionJson: any) => {
                    setDisplayAction((prev) => (prev = true));
                    OnActionClick(actionJson);
                  }}
                />
              ) : (
                <Spinner />
              )}
            </div>
          ) : displayAction === true ? (
            <div
              id="action table"
              className="backGroundWhite p-2 ps-3 pe-3 pb-3 "
              style={{
                borderRadius: "12px",
                cursor: "pointer",
              }}
            >
              <img
                src={backIcon}
                alt="go back to main div"
                onClick={() => {
                  setDisplayMainTable((prev) => (prev = true));
                  getUsersGetOrganization();
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ActionForm
                  formData={actionJson!}
                  setCancelState={setDisplayMainTable}
                />
              </div>
            </div>
          ) : (
            <div
              id="action table"
              className="backGroundWhite p-2 ps-3 pe-3 pb-3 "
              style={{
                borderRadius: "12px",
                cursor: "pointer",
              }}
            >
              <img
                src={backIcon}
                alt="go back to main div"
                onClick={() => {
                  setDisplayMainTable((prev) => (prev = true));
                  getUsersGetOrganization();
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NewForm setCancelState={setDisplayMainTable} />
              </div>
            </div>
          )}
        </div>
      </CenterOfScreen>
    </div>
  );
};

export default SuperAdminUsers;
