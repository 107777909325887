import Highcharts from "highcharts";

import { useEffect } from "react";

import ContainerDiv from "./ContainerDiv";

// export class SfssLineChart extends Component {
//   constructor(props) {
//     super(props);
//     // this.therapistValue = props.therapistChartValue
//     this.state = {
//       series: [
//         {
//           data: props.chartValue.therapistArr,
//           name: "Therapist",
//         },
//         {
//           data: props.chartValue.caregiverArr,
//           name: "Caregiver",
//         },
//         {
//           data: props.chartValue.patientArr,
//           name: "Patient",
//         },
//       ],
//     };
//   }

//   highChartsRender() {
//     Highcharts.chart("containerForhigh", {
//       chart: {
//         type: "spline",
//       },
//       title: {
//         text: "",
//       },
//       yAxis: {
//         title: {
//           text: "Scores",
//         },
//       },

//       xAxis: {
//         accessibility: {
//           rangeDescription: "Range: august to may 2021",
//         },
//         type: "datetime",
//         // tickInterval: 24 * 3600 * 1000,
//         //     min: Date.UTC(parseInt(this.arrDates[0].split('/')[2]), (parseInt(this.arrDates[0].split('/')[0]) - 1), parseInt(this.arrDates[0].split('/')[1])),
//         //     max: Date.UTC(parseInt(this.arrDates[this.arrDates.length - 1].split('/')[2]), (parseInt(this.arrDates[this.arrDates.length - 1].split('/')[0]) - 1), parseInt(this.arrDates[this.arrDates.length - 1].split('/')[1]))
//         //
//       },

//       legend: {
//         layout: "horizontal",
//         align: "center",
//         verticalAlign: "bottom",
//       },

//       plotOptions: {
//         series: {
//           label: {
//             connectorAllowed: false,
//           },
//           pointStart: 2010,
//         },
//       },

//       series: this.state.series,
//       // {
//       //     data: props.value.therapistValue,
//       //     name: "Therapist",
//       //   },
//       //   series: [
//       //     {
//       //       data: props.chartValue.therapistArr,
//       //       name: "Therapist",
//       //     },
//       //     {
//       //       data: props.chartValue.caregiverArr,
//       //       name: "Caregiver",
//       //     },
//       //     {
//       //       data: props.chartValue.patientArr,
//       //       name: "Patient",
//       //     },
//       //   ],
//       responsive: {
//         rules: [
//           {
//             condition: {
//               maxWidth: 400,
//             },
//             chartOptions: {
//               legend: {
//                 layout: "horizontal",
//                 align: "center",
//                 verticalAlign: "bottom",
//               },
//             },
//           },
//         ],
//       },
//     });
//   }

//   shouldComponentUpdate(nextProps,nextState) {
//    return this.highChartsRender();
//   }

//   render() {
//     return (
//       // <div className="row h-75 p-2" style={{height:"50vh"}}>
//       //  <div id="container">
//       //  </div>
//       //  </div>
//       <ContainerDiv widthInPercentage="100%" height="25vh">
//         <div className="row">
//           <div
//             id="containerForhigh"
//             className="  "
//             style={{ width: "950px", height: "390px" }}
//           ></div>
//         </div>
//       </ContainerDiv>
//     );
//   }
// }

const SfssLineChart = (props) => {
  // console.log("============values============="+JSON.stringify(props.chartValue))
  var series = [
    {
      data: props.chartValue.therapistArr,
      name: "Therapist",
    },
    {
      data: props.chartValue.caregiverArr,
      name: "Caregiver",
    },
    {
      data: props.chartValue.patientArr,
      name: "Patient",
    },
  ];
  var tickInterval;
  
  // if (props.chartValue.arrDates.length > 5) {
  //   tickInterval = 360 * 3600 * 1000; // 15 days gap
  // } else {
  //   tickInterval = 24 * 3600 * 1000; // 1 days gap
  // }
  
  if (props.chartValue.selectedMonth===3) {
    // alert("3")
    console.log("3 mon.====="+props.chartValue.arrDates.length)
    tickInterval =192 * 3600 * 1000; // 8 days gap
  } 
  else if (props.chartValue.selectedMonth===6) {
    // alert("6")
    tickInterval = 360 * 3600 * 1000; // 15 days gap
  }
  else if (props.chartValue.selectedMonth===9){
    // alert("9")
    tickInterval = 720 * 3600 * 1000; // 30 days gap
  }
  else{
    // alert("no")
  }
  const highChartsRender = () => {
    // var minDate = new Date();
    // var year = new Date().getFullYear().toString();
    // minDate.setDate(minDate.getDate() - 180);
    // year = minDate.getFullYear() + " - " + year;

    Highcharts.chart("lineChartContainer", {
      chart: {
        type: "spline",
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: "Scores",
        },
        min: 0
      },

      xAxis: {
        type: 'datetime',
        // dateTimeLabelFormats: {
        //   // millisecond: '%H:%M:%S.%L',
        //   // second: '%H:%M:%S',
        //   // minute: '%H:%M',
        //   // hour: '%H:%M',
        //   day: '%b. %e',
        //   // day: '%d %b %Y',
        //   // day:'%a %d %b %H:%M:%S',
        //   // week: '%e. %b',
        //   month: '%b \'%y',
        //   year: '%Y'
        // },
        labels: {
          rotation: -45,
          formatter: function() {
            return Highcharts.dateFormat('%m/%d/%y',
                                          this.value);
          }
        },
        title: {
          text: 'Date'
        },
        // accessibility: {
        //   rangeDescription: "Range: august to may 2021",
        // },
        //  categories:["07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/21/21","07/30/21","07/30/21","08/03/21","08/03/21","08/03/21","08/03/21","08/03/21","08/03/21","09/01/21","09/13/21"],
        //  tickInterval: 5,
        //type: "datetime",
        tickInterval: tickInterval,
        // min: "1. Aug",
        // max:"13. Sep"
        // min:  props.chartValue.therapistArr[0].therapistDate,
        //  max: new Date().getTime(),
        // min: Date.UTC(2021, 9,1),
        // max: Date.UTC(2021, 10, 1),
        //  tickInterval: 360 * 3600 * 1000,
        // min: moment).format("MM/DD/YY"),
        // min:props.chartValue.arrDates[0],
        // max:props.chartValue.arrDates[props.chartValue.arrDates.length]
        // max: moment(
        //   props.chartValue.arrDates[pArops.chartValue.arrDates.length - 1]
        // ).format("MM/DD/YY"),
        //
        // labels: {
        //   format: "{value:%d%MMM}",
        // }
        // pointStart: Date.UTC(min)
      },

      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
      },

      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
          dataLabels: {
            enabled: false,
          },
          label: {
            connectorAllowed: false,
          },
          // pointStart: 2020,
        },
      },

      series: series,
      // {
      //     data: props.value.therapistValue,
      //     name: "Therapist",
      //   },

      // responsive: {
      //   rules: [
      //     {
      //       condition: {
      //         maxWidth: 400,
      //       },
      //       chartOptions: {
      //         legend: {
      //           layout: "horizontal",
      //           align: "center",
      //           verticalAlign: "bottom",
      //         },
      //       },
      //     },
      //   ],
      // },
    });
  };

  useEffect(() => {
    highChartsRender();
  });

  return (
    <ContainerDiv widthInPercentage="100%" height="60vh">
      <div className="row">
        <div
          id="lineChartContainer"
          className="  "
          style={{ width: "100%", height: "390px" }}
        ></div>
      </div>
    </ContainerDiv>
  );
};

export default SfssLineChart;
