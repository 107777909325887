 
import { AxiosResponse } from "axios"; 
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";
import { Api } from "../Api/ApiClient";
import {
  getClinicallyAtRiskPatient,
  getSurveyCompletionSupervisor,
  getUpcomingSurvey,
  tableJson,
} from "../Api/supervisorResponse";
import BubbleChart from "../Utils/bubbleChart";
import CenterOfScreen from "../Utils/CenterOfScreen";
import ContainerDiv from "../Utils/ContainerDiv";
import DonutChart from "../Utils/DonutChart";
import NavBarCont from "../Utils/NavBarCont";
import { setNavItems } from "../Utils/NavItems";
import Spinner from "../Utils/Spinner";
import TableContainer from "../Utils/TableContainer"; 
export interface checkSessionTypes {
  status: string;
  msg: string;
}

const SuperVisorDashboard: React.FC = () => {
  const [
    surveyCompletionForSupervisorData,
    setSurveyCompletionForSupervisorData,
  ] = useState<getSurveyCompletionSupervisor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  var getUpcomingSurveyDataResponse: getUpcomingSurvey[] = [];
  var getClinicallyAtRiskPatients: getClinicallyAtRiskPatient[] = [];
  // const [getUpcomingSurveyDataResponse, setGetUpcomingSurveyDataResponse] = useState<getUpcomingSurvey[]>([]);
  // const [getClinicallyAtRiskPatients, setGetClinicallyAtRiskPatients] = useState<getClinicallyAtRiskPatient[]>([]);
  const [tableJsonArray, settableJsonArray] = useState<tableJson[]>([]);
  const history = useHistory();
  const [clinicallyAtRiskPatientCount, setClinicallyAtRiskPatientsCount] =
    useState<any>(0);
  var thDataArr: any[] = [];
  var ptDataArr: any[] = [];
  var cgDataArr: any[] = [];
  let surveyCompletionData: any = [];  

  const [bubbleChartSeries, setBubbleChartSeries] = useState<any>();
  useEffect(() => {
    if (sessionStorage.getItem("surveyCompletion") !== undefined && sessionStorage.getItem("surveyCompletion") !== null) {
      surveyCompletionData = sessionStorage.getItem("surveyCompletion");
      setSurveyCompletionForSupervisorData(
        (prvData: any) => (prvData = JSON.parse(surveyCompletionData))
      );
    } else {
      getDetails();
    }
    // if (
    //   sessionStorage.getItem("bubbleChartSeries") != undefined &&
    //   sessionStorage.getItem("tableJSON") != undefined &&
    //   sessionStorage.getItem("clinicallyAtRiskPatientsCount") != undefined
    // ) {
    //   bubbleChartSeriesData = sessionStorage.getItem("bubbleChartSeries");
    //   tableJSONData = sessionStorage.getItem("tableJSON");
    //   clinicalCount = sessionStorage.getItem("clinicallyAtRiskPatientsCount");
    //   setBubbleChartSeries(
    //     (prevData: { therapistArr: any; caregiverArr: any; patientArr: any }) =>
    //       (prevData = JSON.parse(bubbleChartSeriesData))
    //   );
    //   settableJsonArray((prevData) => (prevData = JSON.parse(tableJSONData)));
    //     setClinicallyAtRiskPatientsCount(
    //     (counterData: any) => (counterData = clinicalCount)
    //   );
    //   setLoading((prev) => (prev = false));
    // } else {
    //   getTableDetails();
    // }
    getTableDetails();
  }, []);

  const getDetails = async () => {
    try {
      const response: AxiosResponse = await Api.getSurveyCompletionCount(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("supervisorId")!.toString()
      );

      if (response.status === 200) {
        sessionStorage.setItem(
          "surveyCompletion",
          JSON.stringify(response.data)
        );
        setSurveyCompletionForSupervisorData(
          (prvData: any) => (prvData = response.data)
        );

        console.log("details" + JSON.stringify(response.data));
      } else {
        history.goBack();
        // alert("Token expired");
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getTableDetails = async () => {
    try {
      const getUpcomingSurveysResponse: AxiosResponse =
        await Api.getUpcomingSurveysAndPhq9SurveyList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          sessionStorage.getItem("supervisorCode")!.toString(),
          // "TEST197610",
          "user_code",
          "sfss"
        );

      const getClinicallyAtRiskPatientsResponse: AxiosResponse =
        await Api.getClinicallyAtRiskPatientsList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          sessionStorage.getItem("Role")!.toString(),
          sessionStorage.getItem("supervisorId")!.toString()
        );

      getClinicallyAtRiskPatients =
        getClinicallyAtRiskPatientsResponse.data.data;
      // setGetClinicallyAtRiskPatients(getClinicallyAtRiskPatientsResponse.data.data)
      // alert(JSON.stringify(getUpcomingSurveysResponse))
      if (
        getUpcomingSurveysResponse.status === 200 &&
        getClinicallyAtRiskPatientsResponse.status === 200
      ) {
        setLoading((prev) => (prev = false));
        getUpcomingSurveyDataResponse = getUpcomingSurveysResponse.data.data;
        getUpcomingSurveyDataResponse.map((item) => {
          // alert(JSON.stringify(item))
          return getClinicallyAtRiskPatients.map((value) =>
            value.patient_id === item.patient_id
              ? parseInt(value.Therapist_Total) >= 65 ||
                parseInt(value.Patient_Total) >= 65 ||
                parseInt(value.CG_Total) >= 65 ||
                value.ace_score >= 3 ||
                parseInt(value.phq9_score) >= 16
                ? (item.highRisk = true)
                : (item.highRisk = false)
              : true
          );
        });

        var table: tableJson[] = [];
        for (let i = 0; i < getUpcomingSurveyDataResponse.length; i++) {
          // for (let j = i; j <= i; j++) {
          if (getUpcomingSurveyDataResponse[i].highRisk === true) {
            table.push({
              Patient: {
                name: getUpcomingSurveyDataResponse[i].name,
                pid: getUpcomingSurveyDataResponse[i].patient_id,
                tid: getUpcomingSurveyDataResponse[i].therapist_id,
                warning: "high",
              },
              Therapist: getUpcomingSurveyDataResponse[i].therapistName,
              "Next Survey": moment(
                getUpcomingSurveyDataResponse[i].UpComing_DateRange
              ).format("MM/DD/YY"),
            });
          } else {
            table.push({
              Patient: {
                name: getUpcomingSurveyDataResponse[i].name,
                pid: getUpcomingSurveyDataResponse[i].patient_id,
                tid: getUpcomingSurveyDataResponse[i].therapist_id,
                warning: "low",
              },
              Therapist: getUpcomingSurveyDataResponse[i].therapistName,
              "Next Survey": moment(
                getUpcomingSurveyDataResponse[i].UpComing_DateRange
              ).format("MM/DD/YY"),
            });
          }
          //}
        }
        var num = 0;
        for (let i = 0; i < getClinicallyAtRiskPatients.length; i++) {
          var sname = "";
          if (getClinicallyAtRiskPatients[i]["name"].split(" ").length > 1) {
            sname =
              getClinicallyAtRiskPatients[i]["name"].split(" ")[0].charAt(0) +
              "" +
              getClinicallyAtRiskPatients[i]["name"].split(" ")[1].charAt(0);
          } else {
            sname = getClinicallyAtRiskPatients[i]["name"]
              .split(" ")[0]
              .charAt(0);
          }
          if (
            parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"]) >= 65 ||
            parseInt(getClinicallyAtRiskPatients[i]["CG_Total"]) >= 65 ||
            parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]) >= 65
          ) {
            // alert("Therapist_Total====="+parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"])+" CG_Total======="+parseInt(getClinicallyAtRiskPatients[i]["CG_Total"])+" Patient_Total======="+parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]))
            num = num + 1;
            // setClinicallyAtRiskPatientsCount(num);
            // setClinicallyAtRiskPatientsCount(
            //   // (prevCount: number) => prevCount + 1
            //   (prevCount: number) => prevCount
            // );
            if(parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"]) >= 65){
              thDataArr.push({
                x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                y: parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"], 0),
                z: 60,
                shortName: sname,
                fullName: getClinicallyAtRiskPatients[i]["name"],
                scoreType: "Therapist",
              });
            }
            if(parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]) >= 65){
              ptDataArr.push({
                x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                y: parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"], 0),
                z: 60,
                shortName: sname,
                fullName: getClinicallyAtRiskPatients[i]["name"],
                scoreType: "Patient",
              });
            }
            if(parseInt(getClinicallyAtRiskPatients[i]["CG_Total"]) >= 65){
              cgDataArr.push({
                x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                y: parseInt(getClinicallyAtRiskPatients[i]["CG_Total"], 0),
                z: 60,
                shortName: sname,
                fullName: getClinicallyAtRiskPatients[i]["name"],
                scoreType: "Caregiver",
              });
            }
            
            
          }
        }
        setClinicallyAtRiskPatientsCount(num);
        sessionStorage.setItem(
          "clinicallyAtRiskPatientsCount",
          JSON.stringify(num)
        );
        setBubbleChartSeries(
          (prevData: {
            therapistArr: any;
            caregiverArr: any;
            patientArr: any;
          }) =>
            (prevData = {
              therapistArr: thDataArr,
              caregiverArr: cgDataArr,
              patientArr: ptDataArr,
            })
        );
        sessionStorage.setItem(
          "bubbleChartSeries",
          JSON.stringify({
            therapistArr: thDataArr,
            caregiverArr: cgDataArr,
            patientArr: ptDataArr,
          })
        );

        settableJsonArray(
          (prevData) =>
            (prevData = Object.values(table).sort((a, b) =>
              a.Patient!.warning.localeCompare(b.Patient!.warning)
            ))
        );
        sessionStorage.setItem(
          "tableJSON",
          JSON.stringify(
            Object.values(table).sort((a, b) =>
              a.Patient!.warning.localeCompare(b.Patient!.warning)
            )
          )
        );

        //console.log("details table" + JSON.stringify(table));
      } else {
        //alert("Token expired");
        history.goBack();
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>

      <CenterOfScreen>
        <div style={{ width: "85%", paddingTop: "5%" }}>
          {loading === false ? (
            surveyCompletionForSupervisorData.length !== 0 ? (
              <div className="pt-3">
                <ContainerDiv widthInPercentage="100%" height="">
                  <div
                    className="row pt-3"
                    style={{ position: "relative", top: "0px" }}
                  >
                    <div className="col-md-6" style={{ height: "65vh" }}>
                      {/* <ContainerDiv widthInPercentage="100%" height="28vh"> */}
                      <>
                        <div
                          className="row pt-2 text-start ps-1"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="col-md-6 ">
                            <p className="Header_Text">
                              {sessionStorage.getItem("userName")}
                            </p>

                            <div className="row">
                              <p className="subHeader text-start ">
                                Overall Supervisor Survey Completion Rate:
                              </p>
                            </div>
                            <div className="row">
                              <div
                                className="col-md-6 "
                                style={{ color: " #71D016" }}
                              >
                                required:
                                {
                                  surveyCompletionForSupervisorData[0]
                                    ?.recom_count
                                }
                              </div>
                              <div
                                className="col-md-6"
                                style={{ color: " #71D016" }}
                              >
                                completed:
                                {
                                  surveyCompletionForSupervisorData[0]
                                    ?.actual_count
                                }
                              </div>
                            </div>
                          </div>
                          {/* <div style={{justifyContent:"center"}}> */}
                          {/* <div className="col-md-6"> */}
                            {surveyCompletionForSupervisorData[0]
                              .Survey_Completion !== undefined || null ? (
                              <div className="col-md-5 col-lg-5 col-sm-5 ">
                                <DonutChart
                                  value={
                                    surveyCompletionForSupervisorData[0]
                                      .Survey_Completion
                                  }
                                />
                              </div>
                            ) : (
                              <><span>No Data Available</span></>
                            )}
                          </div>
                        {/* </div> */}
                        <div
                          className="row p-3 mt-3 me-2 sfss-outer-block"
                          style={{
                            backgroundColor: "white",
                            height: "48vh",
                          }}
                        >
                          <>
                            <div className="col-md-12">
                              <p className="Header_Text text-start">
                                High Risk Patients (SFSS):
                                <span style={{ color: " #71D016" }}>
                                  &nbsp; &nbsp;{clinicallyAtRiskPatientCount}
                                </span>
                              </p>
                              {clinicallyAtRiskPatientCount > 0 &&
                                bubbleChartSeries !== undefined ? (
                                <BubbleChart value={bubbleChartSeries} />
                              ) : (
                                <span className="NoDataColor1">
                                  No Data Available
                                </span>
                              )}
                            </div>
                          </>
                        </div>
                      </>
                      {/* </ContainerDiv> */}

                      {/* <div className="row p-2">
                      <ContainerDiv widthInPercentage="100%" height="34vh">
                        <div
                          className="row"
                          style={{
                            backgroundColor: "white",
                            height: "inherit",
                          }}
                        >
                          <>
                            <div className="col-md-12">
                              <p className="Header_Text text-start">
                                High Risk Patients (SFSS):
                                <span style={{ color: " #71D016" }}>
                                  &nbsp; &nbsp;0
                                </span>
                              </p>
                            </div>
                          </>
                        </div>
                      </ContainerDiv>
                    </div> */}
                    </div>
                    <div
                      className="col-md-6 pt-3 "
                      style={{ backgroundColor: "white", height: "80vh" }}
                    >
                      <div style={{ width: "100%" }} className="Header_Text ">
                        <p>Upcoming Surveys</p>
                        <p>(High Risk)</p>
                        <div style={{ width: "100%" }}>
                          {tableJsonArray.length !== 0 ? (
                            <TableContainer
                              JsonArray={tableJsonArray}
                              tBodyHeight="65vh"
                              height="65vh"
                              // columnToShow={["Patient","Next Survey"]}
                            />
                          ) : (
                              <span className="NoDataColor1">
                                
                              No Data Available
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ContainerDiv>
              </div>
            ) : (
              <>No data found</>
            )
          ) : (
            <Spinner/>
          )}
        </div>
      </CenterOfScreen>
    </>
  );
};

export default SuperVisorDashboard;
