import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Api } from "../Api/ApiClient";
import {
  getClinicallyAtRiskPatient,
  getSurveyCompletionSupervisor,
  tableJson,
} from "../Api/supervisorResponse";
import CenterOfScreen from "../Utils/CenterOfScreen";
import ContainerDiv from "../Utils/ContainerDiv";
import DonutChart from "../Utils/DonutChart";
import BubbleChart from "../Utils/bubbleChart";
import NavBarCont from "../Utils/NavBarCont";
import { toast } from "react-toastify";
import TableContainer from "../Utils/TableContainer";
import Spinner from "../Utils/Spinner";
import { setNavItems } from "../Utils/NavItems";

const UMDashboard: React.FC = () => {
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [surveyCompletionForUMData, setSurveyCompletionForUMData] = useState<
    getSurveyCompletionSupervisor[]
  >([]);
  var getClinicallyAtRiskPatients: getClinicallyAtRiskPatient[] = [];
  const [loading, setLoading] = useState<boolean>(true);

  const [tableJsonArray, settableJsonArray] = useState<tableJson[]>([]);
  const history = useHistory();
  const [clinicallyAtRiskPatientCount, setClinicallyAtRiskPatientsCount] =
    useState<any>(0);
  var thDataArr: any[] = [];
  var ptDataArr: any[] = [];
  var cgDataArr: any[] = [];
  const [bubbleChartSeries, setBubbleChartSeries] = useState<any>();
  // const location = useLocation();

  useEffect(() => {
    getDetails();
    getTableDetails();
  }, []);

  const getDetails = async () => {
    try {
      const response: AxiosResponse = await Api.getSurveyCompletionCount(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        "",
        "60",
        sessionStorage.getItem("PayerOrgName")!.toString()
      );

      if (response.status === 200) {
        setSurveyCompletionForUMData(
          (prvData: any) => (prvData = response.data)
        );

        // console.log("details" + JSON.stringify(response.data));
      } else {
        notify("session Expired", "error");
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getTableDetails = async () => {
    try {
      const getClinicallyAtRiskPatientsResponse: AxiosResponse =
        await Api.getClinicallyAtRiskPatientsList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          sessionStorage.getItem("Role")!.toString(),
          sessionStorage.getItem("PayerOrgName")!.toString(),
          sessionStorage.getItem("userId")!.toString()
        );

      getClinicallyAtRiskPatients =
        getClinicallyAtRiskPatientsResponse.data.data;

      if (getClinicallyAtRiskPatientsResponse.status === 200) {
        setLoading((prev) => (prev = false));
        getClinicallyAtRiskPatients.map((item) => {
          return getClinicallyAtRiskPatients.map((value) =>
            parseInt(value.Therapist_Total) >= 65 ||
            parseInt(value.Patient_Total) >= 65 ||
            parseInt(value.CG_Total) >= 65 ||
            value.ace_score >= 3 ||
            parseInt(value.phq9_score) >= 16
              ? (item.highRisk = true)
              : (item.highRisk = false)
          );
        });

        var table: tableJson[] = [];
        var num = 0;
        for (let i = 0; i < getClinicallyAtRiskPatients.length; i++) {
          //for (let j = i; j <= i; j++) {
          if (getClinicallyAtRiskPatients[i].highRisk === true) {
            let strPatientCode = `/patientDetails/${getClinicallyAtRiskPatients[i].patient_id}/${getClinicallyAtRiskPatients[i].therapist_id}`;
            table.push({
              Patient: {
                name: (
                  <a href={strPatientCode}>
                    {getClinicallyAtRiskPatients[i].name}
                  </a>
                ),
                pid: getClinicallyAtRiskPatients[i].patient_id,
                tid: getClinicallyAtRiskPatients[i].therapist_id,
                warning: "high",
              },
              Agency: getClinicallyAtRiskPatients[i].agency_name,
              Therapist: getClinicallyAtRiskPatients[i].Therapist_Name,
            });
          } else {
            // let strPatientCode = `/patientDetails/${getClinicallyAtRiskPatients[i].patient_id}/${getClinicallyAtRiskPatients[i].therapist_id}`;
            table.push({
              Patient: {
                name: getClinicallyAtRiskPatients[i].name,
                pid: getClinicallyAtRiskPatients[i].patient_id,
                tid: getClinicallyAtRiskPatients[i].therapist_id,
                warning: "low",
              },
              Agency: getClinicallyAtRiskPatients[i].agency_name,
              Therapist: getClinicallyAtRiskPatients[i].Therapist_Name,
            });
          }
          // alert(JSON.stringify(table))
          var sname = "";
          if (getClinicallyAtRiskPatients[i]["name"].split(" ").length > 1) {
            sname =
              getClinicallyAtRiskPatients[i]["name"].split(" ")[0].charAt(0) +
              "" +
              getClinicallyAtRiskPatients[i]["name"].split(" ")[1].charAt(0);
          } else {
            sname = getClinicallyAtRiskPatients[i]["name"]
              .split(" ")[0]
              .charAt(0);
          }
          if (
            parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"]) >= 65 ||
            parseInt(getClinicallyAtRiskPatients[i]["CG_Total"]) >= 65 ||
            parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]) >= 65
          ) {
            num = num + 1;
            // setClinicallyAtRiskPatientsCount(
            //   (prevCount: number) => prevCount + 1
            // );
            if (
              parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"]) >= 65
            ) {
              // alert("Therapist");
              thDataArr.push({
                x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                y: parseInt( getClinicallyAtRiskPatients[i]["Therapist_Total"],  0),
                z: 60,
                shortName: sname,
                fullName: getClinicallyAtRiskPatients[i]["name"],
                scoreType: "Therapist",
              });
            }
            if (parseInt(getClinicallyAtRiskPatients[i]["CG_Total"]) >= 65) {
              // alert("CG");
              cgDataArr.push({
                x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                y: parseInt(getClinicallyAtRiskPatients[i]["CG_Total"], 0),
                z: 60,
                shortName: sname,
                fullName: getClinicallyAtRiskPatients[i]["name"],
                scoreType: "Caregiver",
              });
            }
            if (
              parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]) >= 65
            ) {
              // alert("Patient");
              ptDataArr.push({
                x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                y: parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"], 0),
                z: 60,
                shortName: sname,
                fullName: getClinicallyAtRiskPatients[i]["name"],
                scoreType: "Patient",
              });
            }
          }

          //}
        }
        
        setBubbleChartSeries(
          (prevData: {
            therapistArr: any;
            caregiverArr: any;
            patientArr: any;
          }) =>
            (prevData = {
              therapistArr: thDataArr,
              caregiverArr: cgDataArr,
              patientArr: ptDataArr,
            })
            
        );
        setClinicallyAtRiskPatientsCount(num);
        settableJsonArray(
          (prevData) =>
            (prevData = Object.values(table).sort((a, b) =>
              a.Patient!.warning.localeCompare(b.Patient!.warning)
            ))
        );
      } else {
        // alert("Token expired");
        history.goBack();
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <div>
        <CenterOfScreen>
          <div style={{ width: "85%", paddingTop: "5%" }}>
            {loading === false ? (
              surveyCompletionForUMData.length !== 0 &&
              tableJsonArray.length !== 0 ? (
                <div className="pt-5">
                  {/* <ContainerDiv widthInPercentage="100%" height="700px"> */}
                  <div
                    className="row p-3"
                    style={{ position: "relative", top: "0px" }}
                  >
                    <div className="col-md-6 p-1">
                      <ContainerDiv widthInPercentage="100%" height="28vh">
                        <>
                          <div
                            className="row p-2 text-start "
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="col-md-6 ">
                              <p className="Header_Text">
                                {sessionStorage.getItem("userName")}
                              </p>

                              <div className="row">
                                <p className="subHeader text-start ">
                                  Survey Completion Rate:
                                </p>
                              </div>
                              <div className="row">
                                <div
                                  className="col-md-6 "
                                  style={{ color: " #71D016" }}
                                >
                                  required:
                                  {surveyCompletionForUMData[0]?.recom_count}
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ color: " #71D016" }}
                                >
                                  completed:
                                  {surveyCompletionForUMData[0]?.actual_count}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <DonutChart
                                value={
                                  surveyCompletionForUMData[0].Survey_Completion
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="row p-3 mt-3 me-2 "
                            style={{
                              backgroundColor: "white",
                              height: "45vh",
                            }}
                          >
                            <> 
                              <div className="col-md-12">
                                <p className="Header_Text text-start ">
                                  High Risk Patients (SFSS):
                                  <span className="RequiredCount">
                                    &nbsp; &nbsp;{clinicallyAtRiskPatientCount}
                                  </span>
                                </p>
                               
                                {clinicallyAtRiskPatientCount > 0 &&
                                bubbleChartSeries !== undefined ? (
                                  <>
                                    <BubbleChart value={bubbleChartSeries} />
                                    {/* {alert(
                                      "=======BubbleChart===========" +
                                        JSON.stringify(bubbleChartSeries)
                                    )} */}
                                  </>
                                ) : (
                                  <>
                                    <span className="NoDataColor1">
                                      No Data Available
                                    </span>
                                  </>
                                )}
                              </div>
                            </>
                          </div>
                        </>
                      </ContainerDiv>
                    </div>
                    <div
                      className="col-lg-6 pt-3 "
                      style={{ backgroundColor: "white", height: "75vh" }}
                    >
                      <div
                        style={{ width: "100%" }}
                        className="Header_Text text-center"
                      >
                        <p>High-Risk Patients</p>
                        <TableContainer
                          tBodyHeight="60vh"
                          JsonArray={tableJsonArray}
                          height="65vh"
                        />
                      </div>
                    </div>
                  </div>
                  {/* </ContainerDiv> */}
                </div>
              ) : (
                <Spinner />
              )
            ) : (
              <Spinner />
            )}
          </div>
        </CenterOfScreen>
      </div>
    </>
  );
};

export default UMDashboard;
