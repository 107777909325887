import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Api } from "../Api/ApiClient";
import { getTherapists } from "../Api/supervisorResponse";
import NavBarCont from "../Utils/NavBarCont";

import SearchBarTable from "../Utils/SearchBarTable";
import "../ComponentCSS/TherapistSearch.css";
import CenterOfScreen from "../Utils/CenterOfScreen";
import Spinner from "../Utils/Spinner";
import { setNavItems } from "../Utils/NavItems";
import { useHistory, useParams } from "react-router-dom";

interface tableData {
  Therapists?: therapist;
  Patients?: number;
  "% Completion"?: string;
  Completed?: number;
  Required?: number;
  therapist_name? : string;
}


interface sessionTableData {
  Therapists?: therapist;
  Patients?: number;
  "% Completion"?: string;
  Completed?: number;
  Required?: number;
  therapist_name? : string;
}

interface therapist {
  name: any;
  tid: string;
  payer?: string;
  // tid: number;
}

let BaseTableData: tableData[] = [];


const TherapistSearch: React.FC = () => {
  const [getTherapistTableData, setTherapistTableData] =
    useState<tableData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const params: any = useParams();
  var tableData: tableData[] = [];

  var sessiontbData : sessionTableData[] =[];
  var therapistData:any;
  useEffect(() => {
    

    if(sessionStorage.getItem("therapists")!== undefined && sessionStorage.getItem("therapists")!== null){
      sessionStorage.removeItem("patients");
      sessionStorage.removeItem("therapist_details");
      sessionStorage.removeItem("patient_distribution");
      therapistData = sessionStorage.getItem("therapists");
      therapistData = JSON.parse(therapistData);
      for (let i = 0; i < therapistData.length; i++) {
         
        tableData.push({
          Therapists: {
            name: (
              <a href="">{therapistData[i].therapist_name}</a>
            ),
            tid: therapistData[i].Therapists.tid,
            payer: sessionStorage.getItem("PayerOrgName") == null ? "" : sessionStorage.getItem("PayerOrgName")!.toString()
          },
          Patients: therapistData[i].Patients,
          "% Completion":
          therapistData[i]["% Completion"] === "--%"
              ? "0%"
              : therapistData[i]["% Completion"],
          Completed:
          therapistData[i].Completed === null
              ? 0
              : therapistData[i].Completed,
          Required: therapistData[i].Required,
        });
      
    }
      setTherapistTableData(tableData);
      BaseTableData = tableData;
      setLoading((prev) => (prev = false));
    }
    else{
      getTherapistList();
    }
  }, []);

  const searchStringHandler = (searchString: string) => {
    //alert("Jayant Jagtap" + searchString);

    // getTherapistTableData?.filter((value) => {
    //   //console.log(JSON.stringify(value.Therapists?.name));
    //   value.Therapists?.name === searchString
    //     ? setTherapistTableData([value])
    //     : console.log("Shruti");
    // });

    const byQuery = (searchValue: string) => (item: tableData) =>
      !searchString ||
      JSON.stringify(item.Therapists)
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());

    const filteredList = getTherapistTableData?.filter(byQuery(searchString));
    //alert(JSON.stringify(filteredList));

    if (filteredList !== null || filteredList !== undefined) {
      filteredList!.length !== 0
        ? searchString.length === 0
          ? setTherapistTableData((prev) => (prev = BaseTableData))
          : setTherapistTableData((prev) => (prev = filteredList))
        : setTherapistTableData((prev) => (prev = BaseTableData));
    } else {
      alert("no such record");
    }
  };

  const getTherapistList = async () => {
    try {
      let getTherapistListResponse: AxiosResponse;
      // if (sessionStorage.getItem("Role") === "Supervisor") {
      //   getTherapistListResponse = await Api.getTherapistBySupervisor(
      //     encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      //     sessionStorage.getItem("supervisorId")!
      //   );
      // } 
      if (sessionStorage.getItem("Role") === "Supervisor") {
        getTherapistListResponse = await Api.getTherapistBySupervisorList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          sessionStorage.getItem("orgId")!,
          "therapist",
          sessionStorage.getItem("supervisorId")!,
          "true",
          "",        
        );
      }
      
      else {
       
          getTherapistListResponse = await Api.getTherapistBySupervisorList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            params["id"],
            "",
            "",
            "",
            sessionStorage.getItem("PayerOrgName")!,        
          );
        
      }

      setLoading((prev) => (prev = false));
      console.log(JSON.stringify(getTherapistListResponse.data));
      const getTherapistResponseData: getTherapists[] =
        getTherapistListResponse.data.data;

      if (getTherapistListResponse.status === 200) {
        for (let i = 0; i < getTherapistResponseData.length; i++) {
         
            tableData.push({
              Therapists: {
                name: (
                  <a href="">{getTherapistResponseData[i].therapist_name}</a>
                ),
                tid: getTherapistResponseData[i].user_id,
                payer: sessionStorage.getItem("PayerOrgName") == null ? "" : sessionStorage.getItem("PayerOrgName")!.toString()
              },
              Patients: getTherapistResponseData[i].Patient_count,
              "% Completion":
                getTherapistResponseData[i].Survey_Completion_60days === "--%"
                  ? "0%"
                  : getTherapistResponseData[i].Survey_Completion_60days,
              Completed:
                getTherapistResponseData[i].actual_count_60days === null
                  ? 0
                  : getTherapistResponseData[i].actual_count_60days,
              Required: getTherapistResponseData[i].recom_count_60days
            });
          
            sessiontbData.push({
              Therapists: {
                name: (
                  <a href="">{getTherapistResponseData[i].therapist_name}</a>
                ),
                tid: getTherapistResponseData[i].user_id,
                payer: sessionStorage.getItem("PayerOrgName") == null ? "" : sessionStorage.getItem("PayerOrgName")!.toString()
              },
              Patients: getTherapistResponseData[i].Patient_count,
              "% Completion":
                getTherapistResponseData[i].Survey_Completion_60days === "--%"
                  ? "0%"
                  : getTherapistResponseData[i].Survey_Completion_60days,
              Completed:
                getTherapistResponseData[i].actual_count_60days === null
                  ? 0
                  : getTherapistResponseData[i].actual_count_60days,
              Required: getTherapistResponseData[i].recom_count_60days,
              therapist_name: getTherapistResponseData[i].therapist_name
            });
        }
        // alert(JSON.stringify(tableData))
        console.log("Here goes" + JSON.stringify(tableData));
        BaseTableData = tableData;
        setTherapistTableData((prev) => (prev = tableData));
        sessionStorage.setItem("therapists", JSON.stringify(sessiontbData))
        setLoading((prev) => (prev = false));
      } else {
        history.push("/");
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <CenterOfScreen >
        <div className="outer-table " >
          {loading === false ? (
            getTherapistTableData !== null && getTherapistTableData !== undefined && getTherapistTableData.length !== 0? (
              <SearchBarTable
              
                Table={getTherapistTableData}
                TableHeight="50Vh"
                tableBodyHeight="45vh"
                showNewButton={false}
                placeHolderValue="Therapist"
                SearchStringFunction={(searchStringValue: string) => {
                  searchStringHandler(searchStringValue);
                }}
              />
            ) : (
              <></>
            )
          ) : (
            <Spinner />
          )}
        </div>
      </CenterOfScreen>
    </div>
  );
};

export default TherapistSearch;
