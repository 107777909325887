import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Api } from "../Api/ApiClient";
import {
  getClinicallyAtRiskPatient,
  getSurveyCompletionSupervisor,
  getUpcomingSurvey,
  tableJson,
} from "../Api/supervisorResponse";
import CenterOfScreen from "../Utils/CenterOfScreen";
import ContainerDiv from "../Utils/ContainerDiv";
import DonutChart from "../Utils/DonutChart";
import BubbleChart from "../Utils/bubbleChart";
import NavBarCont from "../Utils/NavBarCont";
import { toast } from "react-toastify";
import TableContainer from "../Utils/TableContainer";
import moment from "moment";
import Spinner from "../Utils/Spinner";
import { setNavItems } from "../Utils/NavItems";
import "../ComponentCSS/TherapistDashboard.css";

const TherapistDashboard: React.FC = () => {
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [
    surveyCompletionForTherapistData,
    setSurveyCompletionForTherapistData,
  ] = useState<getSurveyCompletionSupervisor[]>([]);
  var getUpcomingSurveyDataResponse: getUpcomingSurvey[] = [];
  var getClinicallyAtRiskPatients: getClinicallyAtRiskPatient[] = [];
  const [loading, setLoading] = useState<boolean>(true);

  const [tableJsonArray, settableJsonArray] = useState<tableJson[]>([]);
  const history = useHistory();
  // const location = useLocation();
  const [clinicallyAtRiskPatientCount, setClinicallyAtRiskPatientsCount] =
    useState<any>(0);
  var thDataArr: any[] = [];
  var ptDataArr: any[] = [];
  var cgDataArr: any[] = [];
  let surveyCompletionData: any = [];
  // let bubbleChartSeriesData: any = [];
  // let tableJSONData: any = [];
  // var bubbleChartArray: any = [];
  // let clinicalCount: any;
  const [bubbleChartSeries, setBubbleChartSeries] = useState<any>();
  useEffect(() => {
    sessionStorage.removeItem("patientDetails");
    sessionStorage.removeItem("Upcoming_SFSS_Date");
    sessionStorage.removeItem("Upcoming_PHQ9_Date");
    sessionStorage.removeItem("SFSS_Chart_data");
    sessionStorage.removeItem("ace_score_history");
    sessionStorage.removeItem("file_history");

    getDetails();
    getTableDetails();
  }, []);

  const getDetails = async () => {
    try {
      if (sessionStorage.getItem("surveyCompletion") !== undefined && sessionStorage.getItem("surveyCompletion") !== null) {
        surveyCompletionData = sessionStorage.getItem("surveyCompletion");
        setSurveyCompletionForTherapistData(
          (prevData: any) => (prevData = JSON.parse(surveyCompletionData))
        );
      } else {
        const response: AxiosResponse = await Api.getSurveyCompletionCount(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          sessionStorage.getItem("therapistID")!.toString(),
          // "39",
          "60"
        );

        if (response.status === 200) {
          sessionStorage.setItem(
            "surveyCompletion",
            JSON.stringify(response.data)
          );
          setSurveyCompletionForTherapistData(
            (prvData: any) => (prvData = response.data)
          );
          console.log("details" + JSON.stringify(response.data));
        } else {
          notify("session Expired", "error");
        }
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getTableDetails = async () => {
    try {
      // if (
      //   sessionStorage.getItem("bubbleChartSeries") != undefined &&
      //   sessionStorage.getItem("tableJSON") != undefined &&
      //   parseInt(sessionStorage.getItem("clinicallyAtRiskPatientsCount")!) !=
      //     undefined
      // ) {
      //   // alert("session storage");
      //   thDataArr = [];
      //   ptDataArr= [];
      //   cgDataArr= [];
      //   setBubbleChartSeries(
      //     (prevData: any) => (prevData = undefined)
      //   );
      //   tableJSONData = sessionStorage.getItem("tableJSON");
      //   clinicalCount = parseInt(
      //     sessionStorage.getItem("clinicallyAtRiskPatientsCount")!
      //   );
      //   bubbleChartSeriesData = sessionStorage.getItem("bubbleChartSeries");
       
      //   settableJsonArray((prevData) => (prevData = JSON.parse(tableJSONData)));
      //   setClinicallyAtRiskPatientsCount(clinicalCount);
      //   setBubbleChartSeries(
      //     (prevData: {
      //       therapistArr: any;
      //       caregiverArr: any;
      //       patientArr: any;
      //     }) => (prevData = JSON.parse(bubbleChartSeriesData))
      //   );
      //   setLoading((prev) => (prev = false));
      // } else {
        const getUpcomingSurveysResponse: AxiosResponse =
          await Api.getUpcomingSurveysAndPhq9SurveyList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            sessionStorage.getItem("therapistID")!.toString(),
            // "11",
            "user_id",
            "sfss"
          );
        // settableJsonArray(getUpcomingSurveysResponse)
        const getClinicallyAtRiskPatientsResponse: AxiosResponse =
          await Api.getClinicallyAtRiskPatientsList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            sessionStorage.getItem("Role")!.toString(),
            sessionStorage.getItem("therapistID")!.toString()
            // "32"
          );

        getClinicallyAtRiskPatients =
          getClinicallyAtRiskPatientsResponse.data.data;
        console.log(
          "getClinicallyAtRiskPatients====" +
            JSON.stringify(getClinicallyAtRiskPatients)
        );
        // sessionStorage.setItem(
        //   "clinicallyAtRiskPatientsCount",
        //   clinicallyAtRiskPatientCount
        // );

        if (
          getUpcomingSurveysResponse.status === 200 &&
          getClinicallyAtRiskPatientsResponse.status === 200
        ) {
          setLoading((prev) => (prev = false));
          getUpcomingSurveyDataResponse = getUpcomingSurveysResponse.data.data;
          // alert("res----------"+JSON.stringify(getUpcomingSurveyDataResponse))
          getUpcomingSurveyDataResponse.map((item) => {
            return getClinicallyAtRiskPatients.map((value) =>
              value.patient_id === item.patient_id
                ? parseInt(value.Therapist_Total) >= 65 ||
                  parseInt(value.Patient_Total) >= 65 ||
                  parseInt(value.CG_Total) >= 65 ||
                  value.ace_score >= 3 ||
                  parseInt(value.phq9_score) >= 16
                  ? (item.highRisk = true)
                  : (item.highRisk = false)
                : true
            );
          });
          // alert("res----------"+JSON.stringify(getUpcomingSurveyDataResponse))
          var table: tableJson[] = [];

          for (let i = 0; i < getUpcomingSurveyDataResponse.length; i++) {
            //  for (let j = i; j <= i; j++) {
            if (getUpcomingSurveyDataResponse[i].highRisk === true) {
              table.push({
                Patient: {
                  name: getUpcomingSurveyDataResponse[i].name,
                  pid: getUpcomingSurveyDataResponse[i].patient_id,
                  tid: getUpcomingSurveyDataResponse[i].therapist_id,
                  warning: "high",
                },
                "Patient Code": getUpcomingSurveyDataResponse[i].patient_code,
                "Next Survey": moment(
                  getUpcomingSurveyDataResponse[i].UpComing_DateRange
                ).format("MM/DD/YY"),
              });
            } else {
              table.push({
                Patient: {
                  name: getUpcomingSurveyDataResponse[i].name,
                  pid: getUpcomingSurveyDataResponse[i].patient_id,
                  tid: getUpcomingSurveyDataResponse[i].therapist_id,
                  warning: "low",
                },
                "Patient Code": getUpcomingSurveyDataResponse[i].patient_code,
                "Next Survey": moment(
                  getUpcomingSurveyDataResponse[i].UpComing_DateRange
                ).format("MM/DD/YY"),
              });
            }
          }
          var num = 0;
          for (let i = 0; i < getClinicallyAtRiskPatients.length; i++) {
            var sname = "";
            if (getClinicallyAtRiskPatients[i]["name"].split(" ").length > 1) {
              sname =
                getClinicallyAtRiskPatients[i]["name"].split(" ")[0].charAt(0) +
                "" +
                getClinicallyAtRiskPatients[i]["name"].split(" ")[1].charAt(0);
            } else {
              sname = getClinicallyAtRiskPatients[i]["name"]
                .split(" ")[0]
                .charAt(0);
            }
            console.log(
              "getClinicallyAtRiskPatients.length=============" +
                getClinicallyAtRiskPatients,
              sname
            );

            if (
              parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"]) >=
                65 ||
              parseInt(getClinicallyAtRiskPatients[i]["CG_Total"]) >= 65 ||
              parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]) >= 65
            ) {
              // alert("Therapist_Total====="+parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"])+" CG_Total======="+parseInt(getClinicallyAtRiskPatients[i]["CG_Total"])+" Patient_Total======="+parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]))
              num = num + 1;
              // setClinicallyAtRiskPatientsCount(num);
              if (
                parseInt(getClinicallyAtRiskPatients[i]["Therapist_Total"]) >=
                65
              ) {
                // alert("therapist")
                thDataArr.push({
                  x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                  y: parseInt(
                    getClinicallyAtRiskPatients[i]["Therapist_Total"],
                    0
                  ),
                  z: 60,
                  shortName: sname,
                  fullName: getClinicallyAtRiskPatients[i]["name"],
                  scoreType: "Therapist",
                });
              }
              if (
                parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"]) >= 65
              ) {
                // alert("patient")
                ptDataArr.push({
                  x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                  y: parseInt(
                    getClinicallyAtRiskPatients[i]["Patient_Total"],
                    0
                  ),
                  z: 60,
                  shortName: sname,
                  fullName: getClinicallyAtRiskPatients[i]["name"],
                  scoreType: "Patient",
                });
              }
              if (parseInt(getClinicallyAtRiskPatients[i]["CG_Total"]) >= 65) {
                // alert("caregiver")
                cgDataArr.push({
                  x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
                  y: parseInt(getClinicallyAtRiskPatients[i]["CG_Total"], 0),
                  z: 60,
                  shortName: sname,
                  fullName: getClinicallyAtRiskPatients[i]["name"],
                  scoreType: "Caregiver",
                });
              }
              // thDataArr.push({
              //   x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
              //   y: parseInt(
              //     getClinicallyAtRiskPatients[i]["Therapist_Total"],
              //     0
              //   ),
              //   z: 60,
              //   shortName: sname,
              //   fullName: getClinicallyAtRiskPatients[i]["name"],
              //   scoreType: "Therapist",
              // });
              // ptDataArr.push({
              //   x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
              //   y: parseInt(getClinicallyAtRiskPatients[i]["Patient_Total"], 0),
              //   z: 60,
              //   shortName: sname,
              //   fullName: getClinicallyAtRiskPatients[i]["name"],
              //   scoreType: "Patient",
              // });
              // cgDataArr.push({
              //   x: new Date(getClinicallyAtRiskPatients[i]["surveyDate"]),
              //   y: parseInt(getClinicallyAtRiskPatients[i]["CG_Total"], 0),
              //   z: 60,
              //   shortName: sname,
              //   fullName: getClinicallyAtRiskPatients[i]["name"],
              //   scoreType: "Caregiver",
              // });
              // setClinicallyAtRiskPatientsCount(
              //   (prevCount: number) => prevCount +1
              //   );
            }
          }
          setClinicallyAtRiskPatientsCount(
            // (prevCount: number) => {prevCount = prevCount + 1
            // (prevCount: number) => prevCount
            num
          );
          sessionStorage.setItem(
            "clinicallyAtRiskPatientsCount",
            JSON.stringify(num)
          );
          // sessionStorage.setItem(
          //   "clinicallyAtRiskPatientsCount",
          //   clinicallyAtRiskPatientCount
          // );
          console.log(
            "========thDataArr===========" + JSON.stringify(thDataArr)
          );
          console.log(
            "========ptDataArr===========" + JSON.stringify(ptDataArr)
          );
          console.log(
            "========cgDataArr===========" + JSON.stringify(cgDataArr)
          );

          setBubbleChartSeries(
            (prevData: {
              therapistArr: any;
              caregiverArr: any;
              patientArr: any;
            }) =>
              (prevData = {
                therapistArr: thDataArr,
                caregiverArr: cgDataArr,
                patientArr: ptDataArr,
              })
          );

          sessionStorage.setItem(
            "bubbleChartSeries",
            JSON.stringify({
              therapistArr: thDataArr,
              caregiverArr: cgDataArr,
              patientArr: ptDataArr,
            })
          );
          console.log("bubbleChartSeries============"+bubbleChartSeries);
          
          // var getBubbleChartData=sessionStorage.getItem("bubbleChartSeries")
          // alert("getBubbleChartData===="+getBubbleChartData)
          // alert("===bubbleChartSeries from session====="+bubbleChartArray.push(getBubbleChartData))
          // sessionStorage.setItem(
          //   "bubbleChartSeries",
          //   JSON.stringify({
          //     therapistArr: thDataArr,
          //     caregiverArr: cgDataArr,
          //     patientArr: ptDataArr,
          //   })
          // );
          // alert("===bubbleChartSeries======"+JSON.stringify(bubbleChartSeries))
          settableJsonArray(
            (prevData) =>
              (prevData = Object.values(table).sort((a, b) =>
                a.Patient!.warning.localeCompare(b.Patient!.warning)
              ))
          );
          sessionStorage.setItem(
            "tableJSON",
            JSON.stringify(
              Object.values(table).sort((a, b) =>
                a.Patient!.warning.localeCompare(b.Patient!.warning)
              )
            )
          );
        } else {
          // alert("Token expired");
          history.goBack();
        }
      // }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <div>
        <CenterOfScreen>
          <div style={{ width: "85%", paddingTop: "5%" }}>
            {/* {alert("data==========="+sessionStorage.getItem("bubbleChartSeries"))} */}

            {loading === false ? (
              surveyCompletionForTherapistData.length !== 0 ? (
                <div className="pt-3">
                  <ContainerDiv widthInPercentage="100%" height="">
                    <div
                      className="row pt-3"
                      style={{ position: "relative", top: "0px" }}
                    >
                      <div className="col-md-6" style={{ height: "65vh" }}>
                        {/* <ContainerDiv widthInPercentage="100%" height="28vh"> */}
                        <>
                          <div
                            className="row pt-2 text-start ps-1"
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="col-md-6 ">
                              <p className="Header_Text">
                                {sessionStorage.getItem("userName")}
                              </p>

                              <div className="row">
                                <p className="subHeader text-start ">
                                  Survey Completion Rate:
                                </p>
                              </div>
                              <div className="row">
                                <div
                                  className="col-md-6 "
                                  style={{ color: " #71D016" }}
                                >
                                  required:
                                  {
                                    surveyCompletionForTherapistData[0]
                                      ?.recom_count
                                  }
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ color: " #71D016" }}
                                >
                                  completed:
                                  {
                                    surveyCompletionForTherapistData[0]
                                      ?.actual_count
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 col-lg-5 col-sm-5 ">
                            { surveyCompletionForTherapistData[0]
                                    .Survey_Completion  !== "NA" ? (<>
                                  <DonutChart
                                value={
                                  surveyCompletionForTherapistData[0]
                                    .Survey_Completion  
                                }
                                  />
                                </>
                                ) : (
                                  <><span>No Data Available</span></>
                              )}
                              
                            </div>
                          </div>
                          <div
                            className="row p-3 mt-3 me-2 sfss-outer-block"
                            style={{
                              backgroundColor: "white",
                              height: "48vh",
                            }}
                          >
                            <>
                              <div className="col-md-12">
                                <p className="Header_Text text-start">
                                  High Risk Patients (SFSS):
                                  <span style={{ color: " #71D016" }}>
                                    &nbsp; &nbsp;{clinicallyAtRiskPatientCount}
                                  </span>
                                </p>
                                {clinicallyAtRiskPatientCount > 0 &&
                                bubbleChartSeries !== undefined ? (<>
                                  <BubbleChart value={bubbleChartSeries} /></>
                                ) : (
                                  <><span>No Data Available</span></>
                                )}
                              </div>
                            </>
                          </div>
                        </>
                        {/* </ContainerDiv> */}
                      </div>
                      <div
                        className="col-md-6 pt-3 "
                        style={{ backgroundColor: "white", height: "80vh" }}
                      >
                        <div style={{ width: "100%" }} className="Header_Text ">
                          <p>Upcoming Surveys</p>

                          {tableJsonArray.length !== 0 && tableJsonArray !== undefined ? (
                            <TableContainer
                              JsonArray={tableJsonArray}
                              tBodyHeight="65vh"
                              height="65vh"
                              // columnToShow={["Patient","Therapists","Next Survey"]}
                            />
                            
                          ) : (
                            <span className="NoDataColor">
                              No Data Available
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </ContainerDiv>
                </div>
              ) : (
                <>No data found</>
                // <Spinner />
              )
            ) : (
              <Spinner />
            )}
          </div>
        </CenterOfScreen>
      </div>
    </>
  );
};

export default TherapistDashboard;
