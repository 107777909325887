import React from "react";

import SearchBar from "./SearchBar";
import "../CSS/OrgDashboard.css";
import CustomButton from "./CustomButton";
import TableContainer from "./TableContainer";
import Dropdown from "./Dropdown";

interface SearchBarTableProps {
  spAdminUser?: Boolean;
  Table: any[];
  TableHeight?: string;
  showNewButton: boolean;
  placeHolderValue: string;
  tableBodyHeight: string;
  dropDown?: any[];
  disableValue?:boolean;
  SearchStringFunction?: Function;
  onDropdownValueChange?: Function;
  onActionClickHandler?: Function;
  onNewClickHandler?: Function;
  leftAlignTable?: boolean;
}

const SearchBarTable: React.FC<SearchBarTableProps> = (SearchBarTableProps) => {
  return (
    <div>
      <>
        <>
          <div>
            <div className="text-start">
              <SearchBar
                placeHolderValue={SearchBarTableProps.placeHolderValue}
                OnInputChangeHandler={(value: string) => {
                  // onInputChange(value);
                  SearchBarTableProps.SearchStringFunction!(value);
                }}
              />
            </div>
            <div
              className="text-start pt-4 ps-4"
              style={{ background: "#fff" ,borderRadius: "10px" }}
            >
              {SearchBarTableProps.spAdminUser === true ? (
                <div className="pb-3">
                  <span>Organization : </span>
                  <span>
                    <Dropdown
                      dropdownList={SearchBarTableProps.dropDown}
                      onChangeDropDownHandler={(dropDownValue: string) => {
                        SearchBarTableProps.onDropdownValueChange!(
                          dropDownValue
                        );
                      }}
                    />
                  </span>
                </div>
              ) : (
                <></>
              )}
              {SearchBarTableProps.showNewButton === true ?  SearchBarTableProps.disableValue === true? (
                <div className="pb-3 ps-4">
                  <CustomButton
                    buttonName="New"
                    disabled={true}
                    OnClickHandler={(e) => {
                      SearchBarTableProps.onNewClickHandler!(e);
                    }}
                  />
                </div>
              ) : (
                <div className="pb-3 ps-4">
                  <CustomButton
                    buttonName="New"
                    disabled={false}
                    OnClickHandler={(e) => {
                      SearchBarTableProps.onNewClickHandler!(e);
                    }}
                  />
                </div>
              ) : 
              <></>}

              {SearchBarTableProps.Table !== null &&
              SearchBarTableProps.Table !== undefined  && Object.keys(SearchBarTableProps.Table).length !== 0? (
                <TableContainer
                  JsonArray={SearchBarTableProps.Table}
                  tBodyHeight={SearchBarTableProps.tableBodyHeight}
                  height={SearchBarTableProps.TableHeight}
                  left={SearchBarTableProps.leftAlignTable}
                  actionButtonHandler={(value: any) => {
                    //alert("Insearch=" + JSON.stringify(value));
                    SearchBarTableProps.onActionClickHandler!(value);
                  }}
                />
              ) : (
                <div className="row NoDataColor">
                        <p>No Data Available</p>
                      </div>
              )}
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default SearchBarTable;
