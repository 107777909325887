import React, {  useState } from "react";
import "../ComponentCSS/CreatePassword.css";
// import CustomButton from "./CustomButton";
import { useHistory } from "react-router";
import CustomButton from "../Utils/CustomButton";
// import sha1 from "sha1";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CenterOfScreen from "../Utils/CenterOfScreen";
import { AxiosResponse } from "axios";
import { setNavItems } from "../Utils/NavItems";
import NavBarCont from "../Utils/NavBarCont";
import doneIcon from "../Assets/done-teal.svg";
import { Api } from "../Api/ApiClient";
// import { send } from "process";
// import { stringify } from "querystring";
const CreatePassword: React.FC = () => {
  // const [userIcon, setUserIcon] = useState<string>("none");
  // const [logout, setLogout] = useState<string>("none");
  // const [settings, setSettings] = useState<string>("none");
  // const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [checkBox, setCheckbox] = useState<boolean>(false);
  // const params: any = useParams();
  const history = useHistory();
  // useEffect(() => {
  //   CreatePassword(params.token, params.role);

  // }, []);
  const search = useLocation().search;
  const token = JSON.parse(
    JSON.stringify(new URLSearchParams(search).get("token") || "{}")
  ); 

  const pwd = newPassword;

  const CreatePassword = async () => {
    var createPasswordResponse: AxiosResponse;
    if (newPassword && confirmNewPassword !== undefined) {
      if (newPassword === confirmNewPassword) {
        createPasswordResponse = await Api.createPasswordPost(
          encodeURIComponent(token),
          JSON.stringify(pwd),
          "forgotPassword"
        );
        createPasswordResponse.data.status === "success"
          ? notify(createPasswordResponse.data.msg, "info")
          : notify(createPasswordResponse.data.msg, "error");
        if (createPasswordResponse.data.status !== "error") {
           history.push("/")
        }
      } else {
        notify("Password does not match", "error");
      }
    } else {
      notify("Password can not be blank", "error");
    }
  };

  // var saveClickHandler: string;
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <div>
        <CenterOfScreen>
          <div className="col-8 outer-div" style={{width:'30%'}}>
            <h1>Create password </h1>

            <div className="pt-4">
              {/* <label className="pb-2">New password</label> */}
              <input
                className="border-top-0 border-end-0 border-start-0 p-2 border-info pb-3"
                type={`${checkBox === false ? "password" : "text"}`}
                placeholder="New Password"
                onChange={(e) => {
                  setNewPassword(() => e.target.value);
                  console.log("password_" + setNewPassword);
                }}
              ></input>
              <div className="pt-2">
                <input
                  className="border-top-0 border-end-0 border-start-0 p-2 border-info pt-4"
                  type={`${checkBox === false ? "password" : "text"}`}
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setConfirmNewPassword(() => e.target.value);
                    console.log("Confirm_password_" + setConfirmNewPassword);
                  }}
                ></input>
              </div>
            </div>
            <div className="pt-4">    
              <input
                checked={checkBox}
                type="checkbox"
                style={{ width: "10%", padding: "2%" }}
                onChange={() => {
                  setCheckbox((prev) => (prev = !checkBox));
                }}
              ></input>
              <label> Show Password</label>
            </div>
            <div className="pt-3">
              <small style={{ fontSize: "80%" }}>
                Note: Password length should be between 11 to 20 characters and
                should contain a lowercase character, an uppercase character, a
                digit, a special character.
              </small>
            </div>
            <div className="pt-3">
              <CustomButton
                buttonName="Save"
                disabled={false}
                logo={doneIcon}
                OnClickHandler={() => {
                  CreatePassword();
                  // alert("Newpassword"+JSON.stringify(send));
                }}
              />
            </div>
          </div>
        </CenterOfScreen>
      </div>
    </>
  );
};

export default CreatePassword;