import React from "react";

const Spinner = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* <span className="pr-5">Loading...</span> */}
      <div
        className="spinner-grow text-info"
        style={{ height: "4rem", width: "4rem" }}
        role="status"
      ></div>
    </div>
  );
};

export default Spinner;

//style={{zIndex:3, paddingTop: "10%", paddingLeft: "50%", paddingRight: "50%" ,height:"4rem"}}
