import React from "react";

interface CustomButtonProps {
  buttonName: string;
  logo?: string;
  disabled:any;
  OnClickHandler: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Custom blue button
 * @param CustomButtonProps Button name,click event
 * @returns JSX element
 */
const CustomButton: React.FC<CustomButtonProps> = (CustomButtonProps) => {
  return (
    <>
      <button
        type="button"
        disabled={CustomButtonProps.disabled}
        className="btn btn-lg skyBlue text_color_SkyBlue buttonStyle fw-bold pt-1 pb-1 ps-3 pe-4 pb-2"
        onClick={CustomButtonProps.OnClickHandler}
      >
        {CustomButtonProps.logo && (
          <img src={CustomButtonProps.logo} alt="login" className="pe-2" style={{height:"3vh"}}/>
        )}

        <span style={{fontSize:15}}>{CustomButtonProps.buttonName}</span>
      </button>
    </>
  );
};

export default CustomButton;
