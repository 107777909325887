import React from "react";
import searchIcon from "../Assets/search.svg";
import "../CSS/SearchBar.css";

interface SearchBarProps {
  children?: JSX.Element;
  placeHolderValue: string;
  OnInputChangeHandler?: Function;
  searchBarBorder?: boolean;
  
}

const SearchBar: React.FC<SearchBarProps> = (SearchBarProps) => {
  return (
    <div className="pb-3">
      <div className={`search-Div ${ SearchBarProps.searchBarBorder ? "search-DivWithBorder": "search-Div"}`}>
        <span className="row ps-3 pt-2 pb-2 pe-1 ">
          {/* <div className="col-1 ps-3" >
              <img src={searchIcon} alt="Search Icon"/>
            </div>
            <div className="col-11">
              <input
                type="text"
                className="form-control searchStyleColor border-0"
                placeholder={`Search ${SearchBarProps.placeHolderValue}`}
                // aria-label="Username"
                // aria-describedby="addon-wrapping"
              ></input>
            </div> */}
          <span>
            <img
              src={searchIcon}
              alt="Search Icon"
              className="d-inline-flex ps-1"
              style={{ width: "2.3%" }}
            />

            <input
              type="text"
              className="form-control searchStyleColor border-0 d-inline-flex ps-4"
              placeholder={`Search ${SearchBarProps.placeHolderValue}`}
              style={{ width: "fit-content" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                SearchBarProps.OnInputChangeHandler!(e.target.value);
              }}
              // aria-label="Username"
              // aria-describedby="addon-wrapping"
            ></input>
          </span>
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
