import axios, { AxiosResponse } from "axios";
import { NewOrgPost } from "../Components/SpAdminDashboard";
import { UpdateUserPost } from "../Components/SuperAdminUsers";
import { BASE_URL, BASE_URL2 } from "./ApiConstants";
import { LoginBody } from "./ApiResponseDataTypes";
import qs from "qs";

export class Api {
  static postLoginCall = async (loginData: LoginBody) => {
    console.log("DATA ==" + JSON.stringify(loginData));
    // alert(JSON.stringify(loginData))
    var responseData = await axios({
      method: "POST",
      url: `${BASE_URL}/Login`,
      data:
        "userId=" +
        loginData.userId +
        "&pwd=" +
        loginData.pwd +
        "&accessToken=" +
        encodeURIComponent(loginData.accessToken) +
        "&therapistId=" +
        loginData.therapistId +
        "&model=" +
        loginData.model +
        "&location=" +
        loginData.location +
        "&phoneMake=" +
        loginData.phoneMake +
        "&appVersion=" +
        loginData.appVersion +
        "&osVersion=" +
        loginData.osVersion +
        "&IMEI=" +
        loginData.IMEI,
      // headers: {
      //   accept: "application/json",
      // },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        //here catches the error
        return err.response;
      });
    // alert(responseData)
    return responseData;
  };

  static getAccessToken = async (emailId: string) => {
    var responseData = await axios({
      method: "GET",
      url: `${BASE_URL}/getAccessToken?userId=${emailId}`,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  // static getSurveyCompletionForSupervisor = async (
  //   supervisor_code: string,
  //   token: string
  // ) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getSurveyCompletionForSupervisor?token=${token}&supervisor_code=${supervisor_code}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static postLogout = async (token: string, logOutBody: any) => {
    var responseData = await axios({
      method: "POST",
      url: `${BASE_URL}/logout?token=${token}`,
      data: "userId=" + logOutBody.userId,
      // headers: {
      //   accept: "application/json",
      // },
    })
      .then((response: AxiosResponse) => {
        //alert(JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        //here catches the error
        return err.response;
      });
    // alert(responseData)
    return responseData;
  };

  static postChangePassword = async (
    token: string,
    changePasswordBody: any
  ) => {
    var responseData = await axios({
      method: "POST",
      url: `${BASE_URL}/changePassword?token=${token}`,
      data:
        "oldPwd=" +
        changePasswordBody.oldPwd +
        "&newPwd=" +
        changePasswordBody.newPwd +
        "&role=" +
        changePasswordBody.role +
        "&code=" +
        changePasswordBody.code,
      // headers: {
      //   accept: "application/json",
      // },
    })
      .then((response: AxiosResponse) => {
        //alert(JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        //here catches the error
        return err.response;
      });
    // alert(responseData)
    return responseData;
  };

  // static getUpcomingSurveys = async (
  //   id_code: string,
  //   token: string,
  //   queryValue: string
  // ) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getUpcomingSurveys?token=${token}&${queryValue}=${id_code}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getUpcomingPhq9Survey = async (
  //   id_code: any,
  //   token: string,
  //   queryValue: string
  // ) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getUpcomingPhq9Survey?token=${token}&${queryValue}=${id_code}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getPatientDetails = async (pid: number, token: string) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getPatientDetails?token=${token}&pid=${pid}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getTherapistDetails = async (tid: number, token: string) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getTherapistDetails?token=${token}&tid=${tid}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getClinicallyAtRiskPatients = async (
  //   id: string,
  //   token: string,
  //   userType: string
  // ) => {
  //   var apiURL = `${BASE_URL}/getClinicallyAtRiskPatients?token=${token}`;
  //   if (userType === "Supervisor") {
  //     apiURL += `&supervisor_code=${id}`;
  //   } else if (userType === "Therapist") {
  //     apiURL += `&tid=${id}`;
  //   } else if (userType === "Utilization Manager") {
  //     apiURL += `&payer=${id}`;
  //   }
  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static getOrganizations = async (token: string, type?: string) => {
    var apiURL = "";
    if (type !== undefined) {
      apiURL = `${BASE_URL}/organizations?token=${token}&type=${type}`;
    } else {
      apiURL = `${BASE_URL}/organizations?token=${token}`;
    }
    var responseData = await axios({
      method: "GET",
      // url: `${BASE_URL}/getOrganisations?token=${token}&retrievePayer=${retrievePayer}`,
      // url: `${BASE_URL}/organizations?token=${token}`,
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getUsers = async (token: string, orgId: string) => {
    var responseData = await axios({
      method: "GET",
      url: `${BASE_URL}/getUsers?token=${token}&tenantId=${orgId}`,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  // static getTherapistBySupervisor = async (
  //   token: string,
  //   supervisorId: string
  // ) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getTherapistsBySupervisor?supervisorId=${supervisorId}&token=${token}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getUnassignedTherapists = async (token: string, orgId: string) => {
  //   var responseData = await axios({
  //     method: "GET",
  //     url: `${BASE_URL}/getUnassignedTherapists?token=${token}&orgId=${orgId}`,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static getSurveyCompletionForTherapist = async (
    therapistId: string,
    token: string,
    timePeriod: string
  ) => {
    var apiURL = `${BASE_URL}/getSurveyCompletionForTherapist?token=${token}&tid=${therapistId}`;
    if (timePeriod !== undefined) {
      apiURL += `&interval=` + timePeriod;
    }

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getTenants = async (token: string) => {
    var responseData = await axios({
      method: "GET",
      url: `${BASE_URL2}/getTenants?token=${token}`,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  // static getPatients = async (
  //   token: string,
  //   Code: string,
  //   CodeName: string
  // ) => {
  //   var apiURL = `${BASE_URL}/getPatients?token=${token}&${CodeName}=${Code}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getSupervisors = async (token: string, orgId: string) => {
  //   var apiURL = `${BASE_URL}/getSupervisors?token=${token}&orgId=${orgId}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static users = async (
    token: string,
    orgId: string,
    role: string,
    user_id?: string,
    mapping?: string
  ) => {
    var apiURL = "";
    if (role === "therapist") {
      if (mapping !== undefined) {
        apiURL = `${BASE_URL}/users?token=${token}&org_id=${orgId}&role=${role}&user_id=${user_id}&mapping=${mapping}`;
      } else {
        apiURL = `${BASE_URL}/users?token=${token}&org_id=${orgId}&role=${role}&user_id=${user_id}`;
      }
    } else {
      apiURL = `${BASE_URL}/users?token=${token}&org_id=${orgId}&role=${role}`;
    }
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getSurveyCompletionForPayer = async (
    payer: string,
    token: string,
    timePeriod: string
  ) => {
    var apiURL = `${BASE_URL}/getSurveyCompletionForPayer?token=${token}&payer=${payer}`;
    if (timePeriod !== undefined) {
      apiURL += `&interval=` + timePeriod;
    }

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getAgencies = async (token: string, payer: string, userId: string) => {
    var apiURL = `${BASE_URL}/agencies?token=${token}&payer=${payer}&userId=${userId}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getAgencyDetails = async (
    token: string,
    payer: string,
    org_id: number
  ) => {
    var apiURL = `${BASE_URL}/agencyDetails?token=${token}&payer=${payer}&org_id=${org_id}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getPatientDistribution = async (
    token: string,
    payer: string,
    userId: string,
    orgId : string
  ) => {
    var apiURL;
    if (sessionStorage.getItem("Role") === "Utilization Manager") {
      if (payer !== undefined && payer !== "" && orgId !== "") {
        apiURL = `${BASE_URL}/patientDistribution?token=${token}&payer=${payer}&org_id=${orgId}`;
      } else {
        apiURL = `${BASE_URL}/patientDistribution?token=${token}&tid=${userId}&payer=${payer}`;
      }
    }
    if (sessionStorage.getItem("Role") === "Supervisor") {
      apiURL = `${BASE_URL}/patientDistribution?token=${token}&tid=${userId}`;
    }
    if (sessionStorage.getItem("Role") === "Therapist") {
      apiURL = `${BASE_URL}/patientDistribution?token=${token}&tid=${userId}`;
    }
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getTherapistByUM = async (
    token: string,
    payerId: string,
    orgId: string
  ) => {
    var URL = `${BASE_URL}/getTherapistsBySupervisor?payer=${payerId}&token=${token}`;
    if (orgId !== undefined && orgId !== "") {
      URL += `&orgId=${orgId}`;
    }
    var responseData = await axios({
      method: "GET",
      url: URL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  // static getAceScoreHistory = async (token: string, pid: string) => {
  //   var apiURL = `${BASE_URL}/getACEScoreHistory?token=${token}&patientId=${pid}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getAceQuestionAnswers = async (pid: string, token: string) => {
  //   var apiURL = `${BASE_URL}/getACEQuestionAnswers?patientId=${pid}&token=${token}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getAceQuestionList = async (token: string) => {
  //   var apiURL = `${BASE_URL}/getACEQuestionList?token=${token}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getPatientsSFSSScores = async (
  //   pid: string,
  //   token: string,
  //   duration: number,
  //   scoreType: string
  // ) => {
  //   var apiURL = `${BASE_URL}/getPatientsSFSSScores?token=${token}&pid=${pid}&duration=${duration}&scoreType=${scoreType}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static setAceScoreForPatient = async (
  //   postJson: any,
  //   pid: number,
  //   token: string
  // ) => {
  //   var apiURL = `${BASE_URL}/setAceScoreForPatient?token=${token}`;

  //   var responseData = await axios({
  //     method: "POST",
  //     url: apiURL,
  //     data:
  //       "&patientId=" + pid + "&question_answers=" + JSON.stringify(postJson),
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  // static getFileHistory = async (pid: string, token: string) => {
  //   var apiURL = `${BASE_URL}/getFileHistory?token=${token}&Patient_ID=${pid}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static getSites = async (tenantId: string, token: string) => {
    var apiURL = `${BASE_URL2}/getSites?token=${token}&tenantId=${tenantId}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getOrgType = async (token: string) => {
    var apiURL = `${BASE_URL}/organizations?token=${token}&type=true`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  // static getRoles = async (token: string, role: string, tenantId: string) => {
  //   var apiURL = `${BASE_URL}/getRoles?token=${token}&role=${role}&tenantId=${tenantId}`;

  //   var responseData = await axios({
  //     method: "GET",
  //     url: apiURL,
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static updateUserPost = async (
    postJson: UpdateUserPost,
    tenantId: string,
    token: string
  ) => {
    var apiURL = `${BASE_URL}/updateUser?token=${token}&tenantId=${tenantId}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data:`&role=${postJson.role}&firstName=${
        postJson.firstName
      }&middleName=' '&lastName=${postJson.lastName}&email=${
        postJson.email
      }&mobile=' '&dob=${postJson.dob}&userMeta=${JSON.stringify(
        postJson.userMeta
      )}&sites=${postJson.sites}`,
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static uploadFile = async (token: string, file: FormData) => {
    var apiURL = `${BASE_URL}/uploadFileToBucket?token=${token}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data: file,
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  // static createUserPost = async (
  //   postJson: NewUserPost,
  //   tenantId: string,
  //   token: string
  // ) => {
  //   var apiURL = `${BASE_URL}/createUser?token=${token}&tenantId=${tenantId}&user_id=${postJson.user_id}`;

  //   var responseData = await axios({
  //     method: "POST",
  //     url: apiURL,
  //     data: `&role=${postJson.role}&firstName=${
  //       postJson.firstName
  //     }&middleName=' '&lastName=${postJson.lastName}&email=${
  //       postJson.email
  //     }&mobile=' '&dob=${postJson.dob}&tenantId=${postJson.tenantId}&type=${
  //       postJson.type
  //     }&userMeta=${JSON.stringify(postJson.userMeta)}&sites=${postJson.sites}`,
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };

  static createOrganizationPost = async (
    postJson: NewOrgPost,
    // tenantId: string,
    org_id: string,
    token: string
  ) => {
    var apiURL = `${BASE_URL}/organizations?token=${token}`;

    var responseData = await axios({
      method:"POST",
      url: apiURL,
      data:`&name=${postJson.name!.trim().charAt(0).toUpperCase()+ postJson.name!.trim().slice(1)
      }&domain=${postJson.domain!}&subDomain=${postJson.subDomain!}&primarySite=${postJson.primarySite!}&org_code=${
        postJson.org_code
      }&org_type=${postJson.org_type}`,
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  // static importFile = async (
  //   token: string,
  //   fileName: string,
  //   importFlag: string
  // ) => {
  //   var apiURL = `${BASE_URL}/import?token=${token}&fileName=${fileName}`;

  //   var responseData = await axios({
  //     method: "POST",
  //     url: apiURL,
  //     // headers: {
  //     //   accept: "application/json",
  //     // "accept": "application/json, text/javascript, */*; q=0.01",
  //     // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  //     //   "accept": "application/json, text/javascript, */*; q=0.01",
  //     //   // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  //     // "Access-Control-Allow-Origin": "*",
  //     //  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Accept",
  //     //  'Content-Type': 'application/x-www-form-urlencoded'
  //     // },
  //     data: `&import=${importFlag}&fileName=${fileName}`,
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
  //   return responseData;
  // };
  static importFile = async (
    token: string,
    fileName: string,
    specified_method: string,
    dataObj?: object
  ) => {
    // var apiURL = `${BASE_URL}/import?token=${token}&fileName=${fileName}`;
    var apiURL = `${BASE_URL}/import?token=${token}&fileName=${fileName}`;
    var responseData;
    if (specified_method === "GET") {
      responseData = await axios({
        method: "GET",
        url: apiURL,
      })
        .then((response: AxiosResponse) => {
          return response;
        })
        .catch((err) => {
          return err.response;
        });
    }
    if (specified_method === "POST") {
      responseData = await axios({
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: apiURL,
        data: qs.stringify(dataObj),
      })
        .then((response: AxiosResponse) => {
          return response;
        })
        .catch((err) => {
          return err.response;
        });
    }
    return responseData;
  };

  static getSurveyDetails = async (
    token: string,
    surveyId: string,
    voiceSampleId: string
  ) => {
    var apiURL = `${BASE_URL}/getSurveyDetails?token=${token}&surveyId=${surveyId}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getSurveysWithPagination = async (
    token: string,
    minCount: number,
    total: number,
    pid: number
  ) => {
    var apiURL = `${BASE_URL}/getSurveyWithPagination?token=${token}&minCount=${minCount}&total=${total}&pid=${pid}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static checkSession = async (token: string) => {
    var apiURL = `${BASE_URL}/checkSession?token=${token}&key=`;
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static uploadVoiceSample = async (
    token: string,
    file: any,
    fileName: string
    //surveyId: string
  ) => {
    var formData = new FormData();
    formData.append(file.target.files[0].name, file.target.files[0]);
    var apiURL = `${BASE_URL}/uploadVoiceSample?token=${token}&fileName=${fileName}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data: formData,
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static updateVoiceSample = async (
    token: string,
    surveyId: string,
    //therapistId: string,
    patientId: string,
    survey_voice_sample_id: string,
    surveyDate: string,
    voiceLabeling: string,
    patientLevelOfFunctioning: string,
    voice_storgae_link: string
  ) => {
    var apiURL = `${BASE_URL}/updateVoiceSamples?token=${token}`;
    //var apiURL = `http://localhost:8084?token=${token}`;
    var data = `survey_id=${surveyId}&patient_id=${patientId}&survey_voice_sample_id=${survey_voice_sample_id}&date_time=${surveyDate}&voice_labeling=${JSON.stringify(
      voiceLabeling
    )}&patients_level_of_functioning=${JSON.stringify(
      patientLevelOfFunctioning
    )}&bucket_link=${voice_storgae_link}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data: data,
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static supervisorTherapistMapping = async (
    token: string,
    supervisorId: string,
    therapistId: any[]
  ) => {
    var apiURL = `${BASE_URL}/supervisorTherapistMapping?token=${token}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data: `&supervisor_id=${supervisorId}&therapist_id=${therapistId}`,
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getPatientsForLabeling = async (token: string, org_id: string) => {
    var apiURL = `${BASE_URL}/patients?token=${token}&org_id=${org_id}`;
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getSurveysForPatient = async (token: string, patient_id: string) => {
    var apiURL = `${BASE_URL}/surveys?token=${token}&pid=${patient_id}`;
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getVoiceSamples = async (token: string, survey_id: string) => {
    var apiURL = `${BASE_URL}/voiceSamples?token=${token}&survey_id=${survey_id}`;
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static createUserPostList = async (
    postJson: object,
    token: string,
    tenantId: string,
    userCode: string
  ) => {
    var apiURL = `${BASE_URL}/users?token=${token}&tenantId=${tenantId}&user_code=${userCode}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data: qs.stringify(postJson),
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getRolesList = async (token: string, role: string, orgID: string) => {
    var apiURL = `${BASE_URL}/roles?token=${token}&role=${role}&org_id=${orgID}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static updateUserPostList = async (
    postJson: UpdateUserPost,
    //  postJson: Object,
    tenantId: string,
    token: string
  ) => {
    var apiURL = `${BASE_URL}/users?token=${token}&tenantId=${tenantId}&user_code=${postJson.user_code}`;
    // var apiURL = `http://localhost:5003/users?token=${token}&tenantId=${tenantId}`;
    // alert(apiURL);
    // alert("Update data=====>" + qs.stringify(postJson));
    var responseData = await axios({
      method: "post",
      url: apiURL,
      data: qs.stringify(postJson),
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
    return responseData;
  };

  static getSupervisorsList = async (
    token: string,
    orgId: string,
    role: string,
    user_id?: string,
    mapping?: string
  ) => {
    var apiURL = `${BASE_URL}/users?token=${token}&org_id=${orgId}&role=${role}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      // headers: {
      //   accept: "application/json",
      // },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getTherapistBySupervisorList = async (
    token: string,
    orgId?: string,
    role?: string,
    user_id?: string,
    mapping?: string,
    payer?: string
  ) => {
    var apiURL = "";
    if (payer !== undefined && payer !== "") {
      apiURL = `${BASE_URL}/users?token=${token}&payer=${payer}`;
      if (orgId !== undefined) {
        apiURL = `${BASE_URL}/users?token=${token}&payer=${payer}&org_id=${orgId}`;
      }
    } else {
      apiURL += `${BASE_URL}/users?token=${token}&org_id=${orgId}&role=${role}&user_id=${user_id}&mapping=${mapping}`;
    }
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getUnassignedTherapistsList = async (
    token: string,
    orgId: string,
    role: string,
    mapping: string,
    user_id: string
  ) => {
    var responseData = await axios({
      method: "GET",
      url: `${BASE_URL}/users?token=${token}&org_id=${orgId}&role=${role}&mapping=${mapping}&user_id=${user_id}`,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getPatientsSFSSScoresList = async (
    token: string,
    pid: string,
    duration: number,
    scoreType: string,
    therapist_id: string
  ) => {
    var apiURL = `${BASE_URL}/getPatientsSFSSScores?token=${token}&pid=${pid}&duration=${duration}&scoreType=${scoreType}&therapist_id=${therapist_id}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getUpcomingSurveysAndPhq9SurveyList = async (
    token: string,
    id_code: any,
    queryValue: string,
    survey_type: string,
    therapist_id?: string
  ) => {
    var responseData = await axios({
      method: "GET",
      url: `${BASE_URL}/getUpcomingSurveyAndPhq9Survey?token=${token}&${queryValue}=${id_code}&survey_type=${survey_type}&therapist_id=${therapist_id}`,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        // alert("successs--------"+response);
        return response;
      })
      .catch((err) => {
        // alert("error--------"+err);
        return err.response;
      });
    return responseData;
  };

  static getPatientsList = async (
    token: string,
    Code: string,
    CodeName: string,
    orgId? : string,
    userCode? : string
  ) => {
    var apiURL = `${BASE_URL}/patients?token=${token}&${CodeName}=${Code}`;
    if(orgId !== "" && orgId !== null && orgId !== undefined){
      apiURL += `&orgId=${orgId}`
    }
    if(userCode !=="" && userCode !== null && userCode !== undefined){
      apiURL += `&userCode=${userCode}`
    }

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getPatientDetailsList = async (
    pid: number,
    token: string,
    therapist_id: string
  ) => {
    var responseData = await axios({
      method: "GET",
      url: `${BASE_URL}/patients?token=${token}&patient_id=${pid}&therapist_id=${therapist_id}`,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getAceScoreHistoryList = async (token: string, pid: string) => {
    var apiURL = `${BASE_URL}/getACEScoreHistory?token=${token}&patientId=${pid}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
          // 'Access-Control-Allow-Origin' : '*',
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static getAceQuestionAnswersList = async (pid: string, token: string, ace_trans_id: string) => {
    var apiURL = `${BASE_URL}/getACEQuestionAnswers?patientId=${pid}&token=${token}`;
    if(ace_trans_id !== ""){
      apiURL += "&ace_survey_trans_id="+ace_trans_id;
    }


    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getAceQuestionLists = async (token: string) => {
    var apiURL = `${BASE_URL}/getACEQuestionList?token=${token}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getSurveyCompletionCount = async (
    token: string,
    userId?: string,
    timePeriod?: string,
    payer?: string
  ) => {
    var apiURL = `${BASE_URL}/surveyComplitionCount?token=${token}&user_id=${userId}&payer=${payer}`;
    if (timePeriod !== undefined) {
      apiURL += `&interval=` + timePeriod;
    }

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getClinicallyAtRiskPatientsList = async (
    token: string,
    userType: string,
    id: string,
    user_id?:string
  ) => {
    var apiURL = `${BASE_URL}/getClinicallyAtRiskPatients?token=${token}`;
    if (userType === "Supervisor") {
      apiURL += `&user_id=${id}`;
    } else if (userType === "Therapist") {
      apiURL += `&user_id=${id}`;
    } else if (userType === "Utilization Manager") {
      apiURL += `&payer=${id}&user_id=${user_id}`;
    }
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getTherapistDetailsList = async (token: string, tid: number, payer? : string) => {
    var apiURL = `${BASE_URL}/therapistDetails?token=${token}&user_id=${tid}`;
    if(payer !== "" && payer !== null && payer !== undefined){
      apiURL += `&payer=${payer}`;
    }
    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static getFileHistoryList = async (token: string, pid: string) => {
    var apiURL = `${BASE_URL}/getFileHistory?token=${token}&Patient_ID=${pid}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static setAceScoreForPatientList = async (
    postJson: any,
    pid: number,
    token: string
  ) => {
    var apiURL = `${BASE_URL}/setAceScore?token=${token}`;

    var responseData = await axios({
      method: "POST",
      url: apiURL,
      data:
        "&patientId=" + pid + "&question_answers=" + JSON.stringify(postJson),
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };

  static forgotPassword = async (userId: string) => {
    var apiURL = `${BASE_URL}/forgotPassword?userId=${userId}`;

    var responseData = await axios({
      method: "GET",
      url: apiURL,
      headers: {
        accept: "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
  static createPasswordPost = async (token: string, pwd: any, key:any) => {
    var responseData = await axios({
      method: "POST",
      url: `${BASE_URL}/createPassword?token=${token}&key=${key}`,
      data: "pwd=" + pwd ,
      // headers: {
      //   accept: "application/json",
      // },
    })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
    return responseData;
  };
}