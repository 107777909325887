import React, { useEffect, useState } from "react";
import CenterOfScreen from "../Utils/CenterOfScreen";
import CustomButton from "../Utils/CustomButton";
import "../ComponentCSS/PatientDetails.css";
import backButton from "../Assets/arrow_back-dark.svg";
import { AxiosResponse } from "axios";
import { Api } from "../Api/ApiClient";
import NavBarCont from "../Utils/NavBarCont";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import backIcon from "../Assets/arrow_back-dark.svg";
import arrow_back from "../Assets/arrow_back.svg";
import Spinner from "../Utils/Spinner";
import PopUp from "../Utils/PopUp";
import highRisk from "../Assets/Risk alert.svg";
import pen from "../Assets/pencil-edit-teal.svg";
import SfssLineChart from "../Utils/SfssLineChart";
import doneIcon from "../Assets/done-teal.svg";
import closeIcon from "../Assets/close-teal.svg";
import Alert from "../Assets/Alert symbol.svg";
import radioSelected from "../Assets/radio_button_checked-teal.svg";
import radioUnSelected from "../Assets/radio_button_unchecked-teal.svg";
import { toast } from "react-toastify";
import {
  getAceScoreHistoryData,
  getQuestionList,
  ViewResponses,
} from "../Api/supervisorResponse";
import moment from "moment";
import { setNavItems } from "../Utils/NavItems";
// import { any } from "underscore";

interface patientDetailsDatatype {
  name: string;
  patientID: string;
  age: number;
  therapist: string;
  status: string;
  aceScore: number;
  diagnosis: string;
  medication: string;
  lastSurveyDate: string;
  CG_Externalizing: string;
  CG_Internalizing: string;
  CG_Total: string;
  Patient_Externalizing: string;
  Patient_Internalizing: string;
  Patient_Total: string;
  Therapist_Externalizing: string;
  Therapist_Internalizing: string;
  Therapist_Total: string;
  phq9_score: string;
  ace_score: string;
  ace_survey_taken_on?: string | null;
  phq9_date?: string | null;
  patient_code: any;
}

export interface getPatientsSFSSScoresTotalValueResponse {
  status: string;
  data?: DataEntity[] | null;
}
export interface DataEntity {
  Survey_Date: string;
  therapist_id: number;
  patient_id: number;
  Patient_total: number;
  CG_total: number;
  Therapist_total: number;
  CG_internalizing_score: number;
  Patient_internalizing_score: number;
  Therapist_internalizing_score: number;
  CG_externalizing_score: number;
  Patient_externalizing_score: number;
  Therapist_externalizing_score: number;
}

interface QuestionAndAnswers {
  question: string;
  answer: number;
}

// interface chartValue {
//   therapistValue: any[];
//   therapistDataDate: any[];
// }
// interface chartValueCaregiver {
//   caregiverValue: any[];
//   caregiverDataDate: any[];
// }

// interface chartValuePatient {
//   patientValue: any[];
//   patientDataDate: any[];
// }

// interface TherapistDatatype {
//   value: number;
//   date: string;
// }

//var lastSurveyDateValue: string;
const PatientDetails = () => {
  const [patientDetails, setPatientDetails] =
    useState<patientDetailsDatatype>();
  const [loading, setLoading] = useState<boolean>(true);
  const [UpcomingSFSSDate, setUpcomingSFSSDate] = useState<string>();
  const [UpcomingPHQ9Date, setUpcomingPHQ9Date] = useState<string>();
  const [popUpDisplay, setPopUpDisplay] = useState<string>("none");
  const [aceScoreHistory, setAceScoreHistory] =
    useState<getAceScoreHistoryData[]>();
  const [popUpDisplayOnViewResponse, setPopUpDisplayOnViewResponse] =
    useState<string>("none");
  const [popUpDisplayOnEditSurvey, setPopUpDisplayOnEditSurvey] =
    useState<string>("none");
  const [ViewResponses, setViewResponses] = useState<QuestionAndAnswers[]>();
  const [popUpLast, setPopUpLast] = useState<string>("none");
  const [selected_ace_score, setSelectedAceScore] = useState<string>("");
  const [selected_ace_score_date, setSelectedAceScoreDate] =
    useState<string>("");
  // const [backgroundCSS, setBackgroundCSS] = useState<string>("");

  const notify = (message: string, type: any) => {
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(message, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // var chartValueTherapist: chartValue[] = [];
  // var chartValueCaregiver: chartValueCaregiver[] = [];
  // var chartValuePatient: chartValuePatient[] = [];
  var therapistDataValue: any[] = [];
  // var therapistDataDate: any[] = [];
  var caregiverDataValue: any = [];
  // var caregiverDataDate: any = [];
  var patientDataValue: any = [];
  // var patientDataDate: any = [];
  var arrDate: any = [];
  const [tp, setTp] = useState<boolean>(true);
  //const [noImage, setNoImage] = useState<string>();

  // var chartValueTherapist: chartValue[] = [];
  // var therapistDataValue: any[] = [];
  // var therapistDataDate: any[] = [];
  // var caregiverDataValue: any = [];
  // var patientDataValue: any = [];
  const history = useHistory();
  const params: any = useParams();
  const [selectedButton, setSelectedButton] = useState(3);
  const [selectedValue, setselectedValue] = useState("Total");
  let patientDetailsData: any = [];
  let upcoming_sfss_date: any;
  let upcoming_phq9_date: any;
  // let therapist_chart_data: any;
  let getPatientsSFSSScoresTotalValueResponseData: getPatientsSFSSScoresTotalValueResponse;
  const [therapistChartValue, setTherapistChartValue] = useState<any>();
  //  let therapist_id = sessionStorage.getItem("therapistID");
  useEffect(() => {
    if (sessionStorage.getItem("patientDetails") != undefined && sessionStorage.getItem("patientDetails") != null) {
      patientDetailsData = sessionStorage.getItem("patientDetails");
      setPatientDetails(JSON.parse(patientDetailsData));
      setLoading((prev) => (prev = false));
    } else {
      getPatientDetailsData(params.pid, params.tid);
    }
    if (sessionStorage.getItem("Upcoming_SFSS_Date") !== undefined && sessionStorage.getItem("Upcoming_SFSS_Date") !== null) {
      upcoming_sfss_date = sessionStorage.getItem("Upcoming_SFSS_Date");
      setUpcomingSFSSDate((prev) => (prev = upcoming_sfss_date));
    } else {
      getUpcomingSurveyResponse(params.pid, params.tid);
    }
    if (sessionStorage.getItem("Upcoming_PHQ9_Date") !== undefined && sessionStorage.getItem("Upcoming_PHQ9_Date") !== null) {
      upcoming_phq9_date = sessionStorage.getItem("Upcoming_PHQ9_Date");
      setUpcomingPHQ9Date((prev) => (prev = upcoming_phq9_date));
    } else {
      getUpcomingPhq9SurveyResponse(params.pid, params.tid);
    }
    getPatientsSFSSScoresData(
      params.pid,
      selectedButton,
      selectedValue,
      params.tid
    );
  }, []);

  // useEffect(() => {
  //   // if(sessionStorage.getItem("SFSS_Chart_data") != undefined){
  //   //   therapist_chart_data = sessionStorage.getItem("SFSS_Chart_data");
  //   //   setTherapistChartValue(
  //   //     (prev: any) =>
  //   //       (prev = JSON.parse(therapist_chart_data))
  //   //   );
  //   // }
  //   // else{
  //     getPatientsSFSSScoresData(
  //       params.pid,
  //       selectedButton,
  //       selectedValue,
  //       params.tid
  //     );
  //   // }
  // }, [selectedButton, selectedValue]);

  const getPatientsSFSSScoresData = async (
    pid: string,
    month: number,
    scoreData: string,
    tid: string
  ) => {
    try {
      const getPatientsSFSSScoresTotalValueResponse: AxiosResponse =
        await Api.getPatientsSFSSScoresList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          pid,
          month,
          scoreData,
          // params.tid,
          tid
        );
      getPatientsSFSSScoresTotalValueResponseData =
        getPatientsSFSSScoresTotalValueResponseData =
          getPatientsSFSSScoresTotalValueResponse.data;
      console.log(
        "check" + JSON.stringify(getPatientsSFSSScoresTotalValueResponseData)
      );

      if (getPatientsSFSSScoresTotalValueResponseData.status !== "undefined") {
        for (
          let i = 0;
          i < getPatientsSFSSScoresTotalValueResponseData.data!.length;
          i++
        ) {
          // for (let j = i; j <= i; j++) {
          if (scoreData === "Total") {
            console.log("Total");
            arrDate.push(
              moment(
                getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              ).format("MM/DD/YY")
            );
            therapistDataValue.push([
              Date.UTC(
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[0]
                ),
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[1]
                ) - 1,
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[2]
                )
              ),
              getPatientsSFSSScoresTotalValueResponseData.data![i]
                .Therapist_total,
            ]);
            // moment(
            //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            // ).format("MM/DD/YY"),
            // value:getPatientsSFSSScoresTotalValueResponseData.data![i]
            //     .Therapist_total,
            //     date:moment(
            //           getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //          ).format("MM/DD/YY")

            // therapistDataDate.push(
            // moment(
            //      getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //      ).format("DD MMM")
            // )
            // ]);

            caregiverDataValue.push([
              Date.UTC(
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[0]
                ),
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[1]
                ) - 1,
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[2]
                )
              ),
              getPatientsSFSSScoresTotalValueResponseData.data![i].CG_total,
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              // ).format("MM/DD/YY"),
            ]);
            // caregiverDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )

            patientDataValue.push([
              Date.UTC(
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[0]
                ),
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[1]
                ) - 1,
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[2]
                )
              ),
              getPatientsSFSSScoresTotalValueResponseData.data![i]
                .Patient_total,
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              // ).format("MM/DD/YY"),
            ]);
            // patientDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )
          } else if (scoreData === "Externalizing") {
            arrDate.push(
              moment(
                getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              ).format("MM/DD/YY")
            );
            therapistDataValue.push([
              Date.UTC(
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[0]
                ),
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[1]
                ) - 1,
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[2]
                )
              ),
              getPatientsSFSSScoresTotalValueResponseData.data![i]
                .Therapist_externalizing_score,
            ]);
            //   moment(
            //     getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //   ).format("MM/DD/YY"),
            // value:getPatientsSFSSScoresTotalValueResponseData.data![i]
            //     .Therapist_total,
            //     date:moment(
            //           getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //          ).format("MM/DD/YY")

            // therapistDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )

            caregiverDataValue.push(
              [
                Date.UTC(
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[0]
                  ),
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[1]
                  ) - 1,
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[2]
                  )
                ),
                getPatientsSFSSScoresTotalValueResponseData.data![i]
                  .CG_externalizing_score,
              ]
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              // ).format("MM/DD/YY"),
            );
            // caregiverDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )
            patientDataValue.push(
              [
                Date.UTC(
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[0]
                  ),
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[1]
                  ) - 1,
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[2]
                  )
                ),
                getPatientsSFSSScoresTotalValueResponseData.data![i]
                  .Patient_externalizing_score,
              ]
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              // ).format("MM/DD/YY"),
            );
            // patientDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )
          } else if (scoreData === "Internalizing") {
            arrDate.push(
              moment(
                getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              ).format("MM/DD/YY")
            );
            therapistDataValue.push([
              Date.UTC(
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[0]
                ),
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[1]
                ) - 1,
                parseInt(
                  getPatientsSFSSScoresTotalValueResponseData.data![
                    i
                  ].Survey_Date.split("-")[2]
                )
              ),
              getPatientsSFSSScoresTotalValueResponseData.data![i]
                .Therapist_internalizing_score,
            ]);
            //   moment(
            //     getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //   ).format("MM/DD/YY"),
            // value:getPatientsSFSSScoresTotalValueResponseData.data![i]
            //     .Therapist_total,
            //     date:moment(
            //           getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //          ).format("MM/DD/YY")

            // therapistDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )

            caregiverDataValue.push(
              [
                Date.UTC(
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[0]
                  ),
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[1]
                  ) - 1,
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[2]
                  )
                ),
                getPatientsSFSSScoresTotalValueResponseData.data![i]
                  .CG_externalizing_score,
              ]
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              // ).format("MM/DD/YY"),
            );
            // caregiverDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )
            patientDataValue.push(
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i]
              //     .Survey_Date
              // ).format("MM/DD/YY"),
              [
                Date.UTC(
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[0]
                  ),
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[1]
                  ) - 1,
                  parseInt(
                    getPatientsSFSSScoresTotalValueResponseData.data![
                      i
                    ].Survey_Date.split("-")[2]
                  )
                ),
                getPatientsSFSSScoresTotalValueResponseData.data![i]
                  .Patient_internalizing_score,
              ]
              // moment(
              //   getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
              // ).format("MM/DD/YY"),
            );
            // patientDataDate.push(
            //   moment(
            //        getPatientsSFSSScoresTotalValueResponseData.data![i].Survey_Date
            //        ).format("DD MMM")
            // )
          }
          //}
        }
        // chartValueTherapist.push({
        //  therapistValue:therapistDataValue,
        //  therapistDataDate:arrDate
        // });
        // chartValueCaregiver.push({
        //   caregiverValue:caregiverDataValue,
        //   date:arrDate
        //  });
        // chartValuePatient.push({
        //  patientValue:patientDataValue,
        //  date:arrDate

        //  });
        console.log("therapist.nnnn....." + JSON.stringify(therapistDataValue));
        console.log("caregiver.nnnn....." + JSON.stringify(arrDate));
        //  console.log("patient.nnnn....." + JSON.stringify(chartValuePatient[0].date))
      }
      // console.log("therapist.nnnn....." + JSON.stringify(chartValueTherapist.therapistValue));
      // console.log("caregiver......" + caregiverDataValue);
      // console.log("patients......" + patientDataValue);
      //
      setTherapistChartValue(
        (prev: any) =>
          (prev = {
            //  therapistArr: therapistDataValue,
            therapistArr: therapistDataValue,
            caregiverArr: caregiverDataValue,
            patientArr: patientDataValue,
            arrDates: arrDate.length !== undefined ? arrDate : [],
            selectedMonth: month,
          })
      );
      // sessionStorage.setItem("SFSS_Chart_data", JSON.stringify({therapistArr: therapistDataValue,
      //   caregiverArr: caregiverDataValue,
      //   patientArr: patientDataValue,
      //   arrDates: arrDate.length !== undefined ? arrDate : []}))
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const onAceSurveyClick = async () => {
    let aceScoreHistoryData: any;
    try {
      setPopUpDisplay("Block");
      if (sessionStorage.getItem("ace_score_history") != undefined) {
        aceScoreHistoryData = sessionStorage.getItem("ace_score_history");
        setAceScoreHistory(JSON.parse(aceScoreHistoryData));
      } else {
        const getAceSurveyHistoryResponse: AxiosResponse =
          await Api.getAceScoreHistoryList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            params.pid
          );

        setAceScoreHistory(getAceSurveyHistoryResponse.data);
        sessionStorage.setItem(
          "ace_score_history",
          JSON.stringify(getAceSurveyHistoryResponse.data)
        );
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getUpcomingPhq9SurveyResponse = async (pid: string, tid: string) => {
    try {
      const response: AxiosResponse =
        await Api.getUpcomingSurveysAndPhq9SurveyList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          pid,
          "patient_id",
          "phq",
          tid
        );
      if (response.data.data[0] === undefined) {
        setUpcomingPHQ9Date((prev) => (prev = "--"));
        sessionStorage.setItem("Upcoming_PHQ9_Date", "--");
      } else {
        if (
          response.data.data[0].nextSurvey_date != null &&
          response.data.data[0].nextSurvey_date !== undefined
        )
          setUpcomingPHQ9Date(
            (prev) => (prev = response.data.data[0].nextSurvey_date)
          );
        sessionStorage.setItem(
          "Upcoming_PHQ9_Date",
          response.data.data[0].nextSurvey_date
        );
      }
      // if (response.data.data.length > 0) {
      //   setUpcomingPHQ9Date(
      //     (prev) => (prev = response.data.data[0].nextSurvey_date)
      //   );
      //   // alert("datee"+UpcomingPHQ9Date)
      // } else {
      //   setUpcomingPHQ9Date((prev) => (prev = "--"));
      // }
      console.log("value==" + JSON.stringify(response.data));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getUpcomingSurveyResponse = async (pid: string, tid: string) => {
    try {
      const response: AxiosResponse =
        await Api.getUpcomingSurveysAndPhq9SurveyList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          pid,
          "patient_id",
          "sfss",
          tid
        );
      setLoading((prev) => (prev = false));

      console.log(JSON.stringify(response.data));
      if (response.data.data[0] === undefined) {
        setUpcomingSFSSDate((prev) => (prev = "--"));
        sessionStorage.setItem("Upcoming_SFSS_Date", "--");
      } else {
        setUpcomingSFSSDate(
          (prev) => (prev = response.data.data[0].nextSurveyStartDate)
        );
        sessionStorage.setItem(
          "Upcoming_SFSS_Date",
          response.data.data[0].nextSurveyStartDate
        );
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getPatientDetailsData = async (
    PatientId: number,
    therapist_id: any
  ) => {
    try {
      const response: AxiosResponse = await Api.getPatientDetailsList(
        PatientId,
        // 836,
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        therapist_id
        //  "39"
      );

      setLoading((prev) => (prev = false));
      var patientDatacheck: patientDetailsDatatype = {
        name: response.data.data[0].name,
        patientID: response.data.data[0].patient_code,
        age: response.data.data[0].age,
        therapist: response.data.data[0].Therapist,
        status: response.data.data[0].status,
        aceScore: response.data.data[0].ace_score,
        diagnosis: response.data.data[0].patient_diag_id,
        medication: response.data.data[0].patient_diag_id,
        lastSurveyDate: response.data.data[0].LastSurveyDate,
        CG_Externalizing:
          response.data.data[0].CG_externalizing_score === undefined
            ? "--"
            : response.data.data[0].CG_externalizing_score,
        CG_Internalizing:
          response.data.data[0].CG_internalizing_score === undefined
            ? "--"
            : response.data.data[0].CG_internalizing_score,
        CG_Total:
          response.data.data[0].CG_total === undefined
            ? "--"
            : response.data.data[0].CG_total,
        Patient_Externalizing:
          response.data.data[0].Patient_externalizing_score === undefined
            ? "--"
            : response.data.data[0].Patient_externalizing_score,
        Patient_Internalizing:
          response.data.data[0].Patient_internalizing_score === undefined
            ? "--"
            : response.data.data[0].Patient_internalizing_score,
        Patient_Total:
          response.data.data[0].Patient_total === undefined
            ? "--"
            : response.data.data[0].Patient_total,
        Therapist_Externalizing:
          response.data.data[0].Therapist_externalizing_score === undefined
            ? "--"
            : response.data.data[0].Therapist_externalizing_score,
        Therapist_Internalizing:
          response.data.data[0].Therapist_internalizing_score === undefined
            ? "--"
            : response.data.data[0].Therapist_internalizing_score,
        Therapist_Total:
          response.data.data[0].Therapist_total === undefined
            ? "--"
            : response.data.data[0].Therapist_total,
        phq9_score: response.data.data[0].phq9_score,
        ace_score: response.data.data[0].ace_score,
        patient_code: response.data.data[0].patient_code,
        ace_survey_taken_on: response.data.data[0].ace_Survey_taken_on,
        phq9_date: response.data.data[0].phq9_date,
      };

      // console.log("imp===="+JSON.stringify(patientDatacheck));
      if (
        response.data.data[0].ace_Survey_taken_on !== null &&
        response.data.data[0].ace_Survey_taken_on !== "--" &&
        response.data.data[0].phq9_date !== null
      ) {
        patientDatacheck.ace_survey_taken_on =
          response.data.data[0].ace_Survey_taken_on
            .slice(0, 10)
            .split("-")
            .reverse()
            .join("/");
        patientDatacheck.phq9_date = response.data.data[0].phq9_date
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");
      }
      setPatientDetails(patientDatacheck);
      sessionStorage.setItem(
        "patientDetails",
        JSON.stringify(patientDatacheck)
      );
      // setLoading((prev) => (prev = false));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const goBack = () => {
    // history.push("/supervisorDashboard");
    sessionStorage.removeItem("patientDetails");
    history.goBack();
  };

  const viewResponseClickHandler = async (
    ace_trans_id: any,
    ace_score?: any,
    ace_survey_taken_on?: any
  ) => {
    // try {
    var questionAnswers: QuestionAndAnswers[] = [];
    const ViewResponsesResponse: AxiosResponse =
      await Api.getAceQuestionAnswersList(
        params.pid,
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        ace_trans_id
      );

    setSelectedAceScore(ace_score);
    setSelectedAceScoreDate(ace_survey_taken_on);
    const viewResponsesData: ViewResponses = ViewResponsesResponse.data;

    const questionsResponse: AxiosResponse = await Api.getAceQuestionLists(
      encodeURIComponent(sessionStorage.getItem("token")!.toString())
    );

    const questionsData: getQuestionList[] = questionsResponse.data;
    let arrQuestionID: any[] = [];
    if (viewResponsesData.data?.length !== 0) {
      arrQuestionID = [
        ...Object.keys(
          JSON.parse(
            viewResponsesData.data![0].ace_question_answers !== null ||
              undefined
              ? viewResponsesData.data![0].ace_question_answers
              : "none:none"
          )
        ),
      ];
    }

    for (let j = 0; j < questionsData.length; j++) {
      if (arrQuestionID.length !== 0) {
        for (let i = 0; i < arrQuestionID.length; i++) {
          if (arrQuestionID[i] === questionsData[j].question_id.toString()) {
            console.log(
              "question" +
                questionsData[j].question_id +
                " Answer" +
                JSON.parse(viewResponsesData.data![0].ace_question_answers)[
                  [
                    ...Object.keys(
                      JSON.parse(
                        viewResponsesData.data![0].ace_question_answers
                      )
                    ),
                  ][i]
                ]
            );
            questionAnswers.push({
              question: questionsData[j].question,
              answer: JSON.parse(
                viewResponsesData.data![0].ace_question_answers
              )[
                [
                  ...Object.keys(
                    JSON.parse(viewResponsesData.data![0].ace_question_answers)
                  ),
                ][i]
              ],
            });
          }
        }
      } else {
        questionAnswers.push({
          question: questionsData[j].question,
          answer: 0,
        });
      }
    }

    setViewResponses(questionAnswers);
    setLoading((prev) => (prev = false));
    // } catch (error) {
    //   notify("session expired")
    //   history.push("/");
    // }
  };

  return (
    <>
      {/* {loading === false ? ( */}
      <>
        <>
          <NavBarCont
            jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
          ></NavBarCont>
        </>

        <>
          <CenterOfScreen>
            {/* <div style={{ position: "relative" }}> */}
            {/* <ContainerDiv widthInPercentage="100%" height="65vh"> */}
            {loading === false ? (
              <div>
                {console.log(JSON.stringify(therapistChartValue))}
                <PopUp
                  popUpDisplay={popUpLast}
                  popUpContentWidth="50%"
                  contentLeft="18%"
                  contentTop="20%"
                >
                  <h2>Save Response ?</h2>

                  {/* {loading === false ? ( */}
                  <>
                    <span className="pe-2">
                      <CustomButton
                        buttonName="Done"
                        disabled={false}
                        logo={doneIcon}
                        OnClickHandler={async (e) => {
                          try {
                            setLoading((prev) => (prev = true));
                            var send: any = {};
                            ViewResponses!.map((item, key) => {
                              send[`${++key}`] = item.answer;
                            });

                            const response =
                              await Api.setAceScoreForPatientList(
                                send,
                                params.pid,
                                encodeURIComponent(
                                  sessionStorage.getItem("token")!.toString()
                                )
                              );
                            //  alert("res-------"+(response.data.msg));
                            if (response.data.status === "success") {
                              notify(response.data.msg, "info");
                              setLoading((prev) => (prev = false));
                              setPopUpLast((prev) => (prev = "none"));
                              setPopUpDisplayOnEditSurvey("none");
                              setPopUpDisplay("none");
                              setPopUpLast("none");
                              sessionStorage.removeItem("ace_score_history");
                              getPatientDetailsData(params.pid, params.tid);
                              getUpcomingSurveyResponse(params.pid, params.tid);
                              getUpcomingPhq9SurveyResponse(
                                params.pid,
                                params.tid
                              );
                            }
                          } catch (error) {
                            console.log(JSON.stringify(error));
                          }
                        }}
                      />
                    </span>

                    <CustomButton
                      buttonName="go back"
                      disabled={false}
                      OnClickHandler={async (e) => {
                        setPopUpDisplayOnEditSurvey("Block");

                        setPopUpLast("none");
                      }}
                      logo={arrow_back}
                    />
                  </>
                  {/* ) : (
              <Spinner />
            )} */}
                </PopUp>
                <PopUp
                  popUpDisplay={popUpDisplayOnEditSurvey}
                  popUpContentWidth="80%"
                  contentLeft="12%"
                  contentTop="20%"
                >
                  {ViewResponses !== undefined ? (
                    <>
                      <img
                        src={backIcon}
                        alt="go back"
                        onClick={() => {
                          //setPopUpDisplayOnViewResponse("none");

                          setPopUpDisplayOnEditSurvey("none");
                          setPopUpDisplay("Block");

                          //setPopUpDisplay("none");
                        }}
                      />
                      <div className="row pt-2 ps-4">
                        <div className="col-12">
                          <div
                            className="row"
                            style={{ overflowY: "scroll", height: "58vh" }}
                          >
                            <div className="col-12">
                              <h3 className="AceTitle">Edit ACE Survey</h3>
                              <p className="AceSubtitle">
                                Last ACE Survey Taken:
                                {/* {moment(patientDetails?.ace_survey_taken_on).format(
                        "MM/DD/YYYY"
                      )} */}
                                {patientDetails?.ace_survey_taken_on}
                              </p>
                              <div className="pt-1">
                                <div>
                                  <div style={{ display: "none" }}>
                                    <p>{tp}</p>
                                  </div>
                                  {ViewResponses?.map((item, key) => (
                                    <div
                                      key={key + "Question no"}
                                      className="pt-3 pb-3"
                                    >
                                      <div className="row">
                                        <div className="col-1 ps-5 text-end">
                                          {++key}.
                                        </div>
                                        <div className="col-11">
                                          {formatQuestion(item.question)}
                                        </div>
                                      </div>
                                      <div className="ps-5 pt-3">
                                        <span className="">
                                          {item.answer === 1 ? (
                                            <div className="row ">
                                              <div
                                                className="col-4 text-center"
                                                id={key.toString()}
                                                key={key + "answer"}
                                                onClick={(e) => {
                                                  //alert(" high" + e.currentTarget.id);
                                                  var check = ViewResponses;

                                                  check[key - 1]["answer"] = 1;
                                                  setTp((pre) => (pre = !pre));
                                                  setViewResponses(
                                                    (prev) => (prev = check)
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={radioSelected}
                                                  alt="selected"
                                                />
                                                <span
                                                  style={{ color: "#3baeb9" }}
                                                >
                                                  Yes
                                                </span>
                                              </div>
                                              <div
                                                className="col-6 text-start"
                                                key={key.toString()}
                                                onClick={(e) => {
                                                  // alert(
                                                  //   "No low" + e.currentTarget.id
                                                  // );
                                                  var check = ViewResponses;

                                                  check[key - 1]["answer"] = 0;
                                                  setTp((pre) => (pre = !pre));
                                                  setViewResponses(
                                                    (prev) => (prev = check)
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={radioUnSelected}
                                                  alt="Unselected"
                                                />
                                                <span
                                                  style={{ color: "#3baeb9" }}
                                                >
                                                  No
                                                </span>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="row "
                                              key={key + "answer"}
                                            >
                                              <div
                                                className="col-4 text-center"
                                                id={key.toString()}
                                                onClick={(e) => {
                                                  var check = ViewResponses;

                                                  check[key - 1]["answer"] = 1;
                                                  setTp((pre) => (pre = !pre));
                                                  setViewResponses(
                                                    (prev) => (prev = check)
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={radioUnSelected}
                                                  alt="selected"
                                                />
                                                <span
                                                  style={{ color: "#3baeb9" }}
                                                >
                                                  Yes
                                                </span>
                                              </div>
                                              <div
                                                className="col-6 text-start  "
                                                id={key.toString()}
                                                onClick={(e) => {
                                                  // alert(
                                                  //   "No high" + e.currentTarget.id
                                                  // );
                                                  var check = ViewResponses;

                                                  check[key - 1]["answer"] = 0;
                                                  setTp((pre) => (pre = !pre));
                                                  setViewResponses(
                                                    (prev) => (prev = check)
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={radioSelected}
                                                  alt="selected"
                                                />
                                                <span
                                                  style={{ color: "#3baeb9" }}
                                                >
                                                  No
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="pt-4">
                                <CustomButton
                                  buttonName="Finish"
                                  disabled={false}
                                  OnClickHandler={async (e) => {
                                    //alert(JSON.stringify(ViewResponses));

                                    setPopUpLast((prev) => (prev = "Block"));
                                    setPopUpDisplayOnEditSurvey("none");
                                    setPopUpDisplay("none");
                                  }}
                                  logo={doneIcon}
                                />
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <CustomButton
                                  buttonName="Back"
                                  disabled={false}
                                  OnClickHandler={(e) => {
                                    setPopUpDisplayOnEditSurvey("none");
                                    setPopUpDisplay("Block");
                                  }}
                                  logo={closeIcon}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <Spinner />
                    </div>
                  )}
                </PopUp>

                <PopUp
                  popUpDisplay={popUpDisplayOnViewResponse}
                  popUpContentWidth="80%"
                  contentLeft="12%"
                  contentTop="20%"
                >
                  {ViewResponses !== undefined ? (
                    <>
                      <img
                        src={backIcon}
                        alt="go back"
                        onClick={() => {
                          setPopUpDisplayOnViewResponse("none");

                          setPopUpDisplay("Block");
                        }}
                      />
                      <div className="row pt-2 ps-4">
                        <div className="col-12">
                          <div
                            className="row"
                            style={{ overflowY: "scroll", height: "57vh" }}
                          >
                            <div className="col-8">
                              <h3 className="AceTitle">
                                Previous ACE Survey Responses
                              </h3>
                              <p className="AceSubtitle">
                                Score:{selected_ace_score}
                              </p>
                              <p className="AceSubtitle">
                                Last Taken:
                                {/* {moment(patientDetails?.ace_survey_taken_on).format(
                        "MM/DD/YYYY"
                      )} */}
                                {selected_ace_score_date}
                              </p>
                              <div className="pt-4">
                                <div>
                                  {ViewResponses?.map((item, key) => (
                                    <div
                                      key={key + "Yes/No"}
                                      className="pt-4 pb-3"
                                    >
                                      <div key={key + "QuestionHolder"}>
                                        {/* <span className="ps-2">
                                      <span>{++key}.</span>
                                      {formatQuestion(item.question)}
                                    </span>{" "} */}

                                        <div className="row">
                                          <div className="col-1 ps-5 text-end">
                                            {++key}.
                                          </div>
                                          <div className="col-11">
                                            {formatQuestion(item.question)}
                                          </div>
                                        </div>
                                      </div>

                                      <span className="pt-2">
                                        {item.answer === 1 ? (
                                          <div className="row ps-5 pt-3">
                                            <div className="col-4 text-center">
                                              <img
                                                src={radioSelected}
                                                alt="selected"
                                              />
                                              <span
                                                style={{ color: "#3baeb9" }}
                                              >
                                                Yes
                                              </span>
                                            </div>
                                            <div className="col-6 text-start ps-5">
                                              <img
                                                src={radioUnSelected}
                                                alt="Unselected"
                                              />
                                              <span
                                                style={{ color: "#3baeb9" }}
                                              >
                                                No
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row pt-2 ps-5 pt-3">
                                            <div className="col-4 text-center">
                                              {" "}
                                              <img
                                                src={radioUnSelected}
                                                alt="selected"
                                              />
                                              <span
                                                style={{ color: "#3baeb9" }}
                                              >
                                                Yes
                                              </span>{" "}
                                            </div>
                                            <div className="col-6 text-start ps-5">
                                              <img
                                                src={radioSelected}
                                                alt="selected"
                                              />
                                              <span
                                                style={{ color: "#3baeb9" }}
                                              >
                                                No
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 text-end"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Spinner />
                  )}
                </PopUp>

                <PopUp
                  popUpDisplay={popUpDisplay}
                  popUpContentWidth="65%"
                  contentLeft="18%"
                  contentTop="20%"
                >
                  {aceScoreHistory !== undefined ? (
                    <>
                      <img
                        style={{ cursor: "pointer" }}
                        src={backIcon}
                        alt="go back"
                        onClick={() => {
                          setPopUpDisplay("none");
                        }}
                      />

                      <div className="row pt-2 ps-4">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-8">
                              <h3 className="AceTitle">
                                {patientDetails?.name}: ACE Score History
                              </h3>
                              <p style={{ fontWeight: 500, fontSize: "1rem" }}>
                                Current Score:
                                {aceScoreHistory[0]?.ace_score === undefined
                                  ? patientDetails?.ace_score
                                  : aceScoreHistory[0]?.ace_score}
                              </p>
                              <p style={{ fontWeight: 500, fontSize: "1rem" }}>
                                Last Taken:
                                {patientDetails !== undefined ? (
                                  patientDetails?.ace_survey_taken_on!
                                ) : (
                                  // moment(
                                  //   moment(
                                  //     patientDetails?.ace_survey_taken_on!,
                                  //     "DD/MM/YYYY"
                                  //   )
                                  // ).format("MM/DD/YYYY")
                                  <span>No date</span>
                                )}
                              </p>
                            </div>
                            <div className="col-4 text-end">
                              {/* <CustomButton
                            buttonName="Edit ACE"
                            logo={pen}
                            OnClickHandler={(e) => {
                              setPopUpDisplayOnEditSurvey("Block");
                              setPopUpDisplay("none");
                              viewResponseClickHandler();
                              //setPopUpDisplayOnViewResponse("none");
                            }}
                          /> */}

                              <button
                                type="button"
                                className="btn btn-lg skyBlue text_color_SkyBlue buttonStyle fw-bold pt-1 pb-1 ps-3 pe-4 pb-2"
                                onClick={() => {
                                  setPopUpDisplayOnEditSurvey("Block");
                                  setPopUpDisplay("none");
                                  viewResponseClickHandler("");
                                }}
                              >
                                <img
                                  src={pen}
                                  alt="login"
                                  className="pe-2"
                                  style={{ height: "2vh" }}
                                />
                                <span
                                  style={{ fontSize: "15px", fontWeight: 400 }}
                                >
                                  Edit ACE
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div>
                            {aceScoreHistory.length === 0 ? (
                              <div>
                                <p
                                  className="text-center "
                                  style={{
                                    color: "#2eb5b947",
                                    lineHeight: "0.5rem",
                                  }}
                                >
                                  {" "}
                                  No ACE scores yet:
                                </p>
                                <p
                                  className="text-center "
                                  style={{
                                    color: "#2eb5b947",
                                    lineHeight: "0.5rem",
                                  }}
                                >
                                  Click "Edit ACE" to start an ACE survey
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="table-responsive">
                              <table className="spacingBetweenRows">
                                <tbody>
                                  {aceScoreHistory &&
                                    aceScoreHistory?.map((item, key) => (
                                      <tr className="skyBlue" key={key + "tr"}>
                                        <td>Score:&nbsp;{item.ace_score}</td>
                                        {item.ace_score >= 3 ? (
                                          <td>
                                            Risk:&nbsp;
                                            <span>
                                              High
                                              <img
                                                src={Alert}
                                                alt="Risk is High"
                                              />
                                            </span>
                                          </td>
                                        ) : (
                                          <td>None</td>
                                        )}

                                        <td>
                                          Taken:&nbsp;
                                          {moment(item.modified_on).format(
                                            "MM/DD/YYYY"
                                          )}
                                        </td>
                                        <td>Editor:&nbsp;{item.name}</td>
                                        <td>
                                          <div
                                            className="backGroundWhite p-2 ps-3 pe-3"
                                            style={{
                                              borderRadius: "10em",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setPopUpDisplayOnViewResponse(
                                                "Block"
                                              );
                                              setPopUpDisplay("none");
                                              viewResponseClickHandler(
                                                item.ace_survey_trans_id,
                                                item.ace_score,
                                                moment(item.modified_on).format(
                                                  "MM/DD/YYYY"
                                                )
                                              );
                                            }}
                                          >
                                            View Responses
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Spinner />
                  )}
                </PopUp>
                <div className="row mt-5 me-1 m-auto upcomingdiv">
                  <div className="col-md-8 me-4 pb-5 patientDetails">
                    <div className="row text-start">
                      <div className="col-md-1 p-2">
                        <img
                          style={{ cursor: "pointer" }}
                          src={backButton}
                          alt="Previous page arrow"
                          onClick={goBack}
                        ></img>
                      </div>
                    </div>
                    <div className="row ps-5 pe-3 ">
                      <div className="col-md-6  patientDetailsCard patientDetailsCard1">
                        <div className="row cardHeader">
                          <div className="col-md-12 p-2 text-center">
                            <p className="nameStyleP">{patientDetails?.name}</p>
                          </div>
                        </div>
                        <div className="row">
                          <table>
                            <tbody
                              className="patientDetaisCardHeight"
                              style={{ overflow: "hidden" }}
                            >
                              <tr className="p-1 pb-2">
                                <td className="text-end p-2 cardtd">
                                  Patient Id :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {patientDetails?.patientID}
                                </td>
                              </tr>
                              <tr className="p-1">
                                <td className="text-end p-2 cardtd">Age :</td>
                                <td className="text-start p-2 cardtd">
                                  {patientDetails?.age}
                                </td>
                              </tr>
                              <tr className="p-1">
                                <td className="text-end p-2 cardtd">
                                  Therapist :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {patientDetails?.therapist}
                                </td>
                              </tr>
                              <tr className="p-1">
                                <td className="text-end p-2 cardtd">
                                  Status :{" "}
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {patientDetails?.status}
                                </td>
                              </tr>
                              <tr className="p-1">
                                <td className="text-end p-2 cardtd">
                                  ACE Score :{" "}
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {patientDetails?.aceScore}
                                </td>
                              </tr>
                              <tr className="p-1">
                                <td className="text-end p-2 cardtd">
                                  Diagnosis :{" "}
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {patientDetails?.diagnosis === null ||
                                  undefined
                                    ? ""
                                    : patientDetails?.diagnosis}
                                </td>
                              </tr>
                              <tr className="p-1">
                                <td className="text-end p-2 cardtd">
                                  Medication :{" "}
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {" -- "}
                                  {/* {patientDetails?.diagnosis === null || undefined
                                ? ""
                                : patientDetails?.diagnosis} */}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-end p-2 pe-4">
                                  <button
                                    className="cardButton p-3"
                                    onClick={onAceSurveyClick}
                                  >
                                    ACE Survey
                                  </button>
                                </td>
                                <td className="text-start p-1 ps-4">
                                  <button
                                    className="cardButton p-3"
                                    onClick={() => {
                                      //alert("Hello" + params.pid);
                                      history.push(
                                        `/patientFileHistory/${params.pid}`
                                      );
                                    }}
                                  >
                                    File History
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="secondColHeader text-center">
                          Scores at a Glance
                        </div>
                        <div className="row mt-3 ps-2">
                          <span
                            // style={{
                            //   fontWeight: "normal",
                            //   fontSize: "25px",
                            //   lineHeight: "42px",
                            //   letterSpacing: "0.01em",
                            //   textAlign: "left",
                            //   color: "#1C4261",
                            // }}
                            className="secondCol2ndHeader"
                          >
                            SFSS Score :
                          </span>
                        </div>
                        <div className="row ps-2">
                          <span className="text-start ">
                            Last Survey Taken:{" "}
                            {patientDetails?.lastSurveyDate === "null"
                              ? " "
                              : patientDetails?.lastSurveyDate}
                            {/* Last Survey Taken: {lastSurveyDateValue} */}
                          </span>
                        </div>
                        <div className="row p-2 m-2 ">
                          <table className="tableStyle">
                            <thead>
                              <tr className="p-1">
                                <th></th>
                                <th>Therapist</th>
                                <th>Caregiver</th>
                                <th>Patient</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="tbodyBorder">
                                <th
                                  className="sideTableHaead"
                                  // style={{ width: "73%" }}
                                >
                                  E
                                </th>
                                <td
                                  className="innerTdData"
                                  // style={{ width: "63%", textAlign: "inherit" }}
                                >
                                  {patientDetails?.Therapist_Externalizing}
                                </td>
                                <td className="innerTdData">
                                  {patientDetails?.CG_Externalizing}
                                </td>
                                <td className="innerTdData">
                                  {patientDetails?.Patient_Externalizing}
                                </td>
                              </tr>
                              <tr className="tbodyBorder">
                                <th
                                  className="sideTableHaead"
                                  // style={{ width: "73%" }}
                                >
                                  I
                                </th>
                                <td
                                  className="innerTdData"
                                  // style={{ width: "63%", textAlign: "inherit" }}
                                >
                                  {patientDetails?.Therapist_Internalizing}
                                </td>
                                <td className="innerTdData">
                                  {patientDetails?.CG_Internalizing}
                                </td>
                                <td className="innerTdData">
                                  {patientDetails?.Patient_Internalizing}
                                </td>
                              </tr>
                              <tr className="tbodyBorder">
                                <th
                                  className="sideTableHaead"
                                  // style={{ width: "73%" }}
                                >
                                  T
                                </th>
                                <td
                                  className="innerTdData"
                                  // style={{ width: "63%", textAlign: "inherit" }}
                                >
                                  {patientDetails?.Therapist_Total}
                                </td>
                                <td className="innerTdData">
                                  {patientDetails?.CG_Total}
                                </td>
                                <td className="innerTdData">
                                  {patientDetails?.Patient_Total}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="row ps-2 mt-2">
                          <span
                            // style={{
                            //   fontWeight: "normal",
                            //   fontSize: "25px",
                            //   lineHeight: "42px",
                            //   letterSpacing: "0.01em",
                            //   textAlign: "left",
                            //   color: "#1C4261",
                            // }}
                            className="secondCol2ndHeader"
                          >
                            ACE Score:
                            <span className="valueColor">
                              &nbsp; {patientDetails?.ace_score}
                            </span>
                            {/* {aceScoreHistory &&
                                aceScoreHistory?.map((item, key) => (
                                  <tr className="skyBlue" key={key + "tr"}>
                                    <td>Score:{item.ace_score}</td>
                                    {item.ace_score >= 3 ? (
                                      <td>
                                        Risk:&nbsp;
                                        <span>
                                          <img src={Alert} alt="Risk is High" />
                                        </span>
                                      </td>
                                    ) : (
                                      <td>None</td>
                                    )} */}
                            {patientDetails?.ace_score !== undefined || null ? (
                              parseInt(patientDetails!.ace_score) >= 3 ? (
                                <span>
                                  <img
                                    className="ps-4 pb-1"
                                    src={highRisk}
                                    alt="Risk is High"
                                  />
                                  {/* <span className="valueColor highRiskstyle">
                                High Risk
                              </span> */}
                                </span>
                              ) : (
                                <span></span>
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                        <div className="row ps-2 mb-2 ">
                          <span className="text-start">
                            Last Survey Taken:{"  "}
                            <span>
                              {moment(
                                moment(
                                  patientDetails?.ace_survey_taken_on!,
                                  "DD/MM/YYYY"
                                )
                              ).format("MM/DD/YYYY") === "Invalid date"
                                ? " "
                                : moment(
                                    moment(
                                      patientDetails?.ace_survey_taken_on!,
                                      "DD/MM/YYYY"
                                    )
                                  ).format("MM/DD/YYYY")}
                            </span>
                          </span>
                        </div>
                        <div className="row ps-2 mt-2">
                          <span
                            // style={{
                            //   fontWeight: "normal",
                            //   fontSize: "25px",
                            //   lineHeight: "42px",
                            //   letterSpacing: "0.01em",
                            //   textAlign: "left",
                            //   color: "#1C4261",
                            // }}
                            className="secondCol2ndHeader"
                          >
                            PHQ-9 Score:
                            <span className="valueColor">
                              {patientDetails?.phq9_score}
                            </span>
                            {patientDetails?.phq9_score !== undefined ||
                            null ? (
                              parseInt(patientDetails!.phq9_score) >= 16 ? (
                                <span>
                                  &nbsp;
                                  <img src={Alert} alt="Risk is High" />
                                  <span className="valueColor highRiskstyle">
                                    High Risk
                                  </span>
                                </span>
                              ) : (
                                <span></span>
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                        <div className="row ps-2  mb-2">
                          <span className="text-start">
                            Last Survey Taken:{"  "}
                            <span>
                              {moment(
                                moment(patientDetails?.phq9_date!, "DD/MM/YYYY")
                              ).format("MM/DD/YYYY") === "Invalid date"
                                ? " "
                                : moment(
                                    moment(
                                      patientDetails?.phq9_date!,
                                      "DD/MM/YYYY"
                                    )
                                  ).format("MM/DD/YYYY")}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-3 upcomingSurvey-div"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      boxSizing: "border-box",
                      marginTop: "37em",
                    }}
                  >
                    <div>
                      <div
                        // style={{
                        //   fontWeight: "normal",
                        //   fontSize: "36px",
                        //   lineHeight: "42px",
                        //   letterSpacing: "0.01em",
                        //   color: "#1C4261",
                        // }}
                        className="secondColHeader text-center"
                        // style={{ width: "100%" }}
                      >
                        Upcoming Surveys
                      </div>
                    </div>
                    <div className="row ps-2">
                      <span
                        // style={{
                        //   fontWeight: "normal",
                        //   fontSize: "25px",
                        //   lineHeight: "42px",
                        //   letterSpacing: "0.01em",
                        //   textAlign: "left",
                        //   color: "#1C4261",
                        // }}
                        className="secondCol2ndHeader"
                      >
                        SFSS:
                      </span>
                      <div className="row ps-2  mb-2">
                        {/* <span className="text-start valueColor">
                      <span>
                        {moment(moment(UpcomingSFSSDate, "DD/MM/YYYY")).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                      {moment().format("MM/DD/YYYY").toString() ===
                      moment(moment(UpcomingSFSSDate, "DD/MM/YYYY")).format(
                        "MM/DD/YYYY"
                      ) ? (
                        <></>
                      ) : (
                        <span>LAte</span>
                      )}

                      {}
                       </span>
                      */}
                        <span className="valueColor">
                          <p>
                            {UpcomingSFSSDate && UpcomingSFSSDate === "--" ? (
                              <span>--</span>
                            ) : moment(moment()).isBefore(UpcomingSFSSDate) ===
                              false ? (
                              <React.Fragment>
                                <span>{UpcomingSFSSDate}</span>
                                {UpcomingSFSSDate?.length === 0 ? (
                                  "--"
                                ) : (
                                  <span className="ps-3">
                                    <img src={Alert} alt="late" />{" "}
                                    <span className="highRiskstyle">Late</span>
                                  </span>
                                )}
                              </React.Fragment>
                            ) : (
                              <span>{UpcomingSFSSDate}</span>
                            )}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="row ps-2">
                      <span
                        // style={{
                        //   fontWeight: "normal",
                        //   fontSize: "25px",
                        //   lineHeight: "42px",
                        //   letterSpacing: "0.01em",
                        //   textAlign: "left",
                        //   color: "#1C4261",
                        // }}
                        className="secondCol2ndHeader"
                      >
                        PHQ9:
                      </span>
                      <div className="row ps-2  mb-2">
                        <span className="text-start valueColor">
                          {/* {UpcomingPHQ9Date} */}
                          {/* {moment(UpcomingPHQ9Date).format("DD-MM-YYYY")}
                      {moment().format("MM/DD/YYYY").toString() ===
                      UpcomingPHQ9Date ? (
                        <span></span>
                      ) : (
                        <span className="ps-3">
                          <img src={Alert} alt="late" />{" "}
                          <span className="highRiskstyle">Late</span>
                        </span>
                      )}

                      {moment().diff(
                        moment(UpcomingPHQ9Date).format("DD-MM-YYYY")
                      ) >= 0
                        ? alert("Past or current date")
                        : alert("It is a future date")} */}

                          {/* <p>
                        {UpcomingPHQ9Date && UpcomingPHQ9Date === "--" ? (
                          <span>--</span>
                        ) : moment(moment()).isBefore(UpcomingPHQ9Date) ===
                          false ? (
                          <React.Fragment>
                            <span>{UpcomingPHQ9Date}</span>
                            <span className="ps-3">
                              <img src={Alert} alt="late" />{" "}
                              <span className="highRiskstyle">Late</span>
                            </span>
                          </React.Fragment>
                        ) : (
                          <></>
                        )}
                      </p> */}
                          <p>
                            {UpcomingPHQ9Date === "--" ||
                            UpcomingPHQ9Date == null ? (
                              <span>--</span>
                            ) : moment(moment()).isBefore(UpcomingPHQ9Date) ===
                              false ? (
                              <React.Fragment>
                                <span>{UpcomingPHQ9Date}</span>
                                {UpcomingPHQ9Date?.length === 0 ? (
                                  "--"
                                ) : (
                                  <span className="ps-3">
                                    <img src={Alert} alt="late" />{" "}
                                    <span className="highRiskstyle">Late</span>
                                  </span>
                                )}
                              </React.Fragment>
                            ) : (
                              <span>{UpcomingPHQ9Date}</span>
                            )}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row" style={{backgroundColor:"white"}}>
                    <ContainerDiv widthInPercentage="100%" height="60vh">
                        <>
                        <div className="row" >
                            ffff
                        </div>
                        </>
                    </ContainerDiv>
                </div> */}
                {/* </div> */}
                {/* </ContainerDiv> */}
                <div className="row mt-5 me-1">
                  {/* <ContainerDiv widthInPercentage="80%" height="60vh"> */}
                  <>
                    <div
                      className="m"
                      style={{
                        backgroundColor: "white",
                        width: "78%",
                        margin: "auto",
                        marginTop: "-2em",
                        marginBottom: "20px",
                        marginLeft: "12%",
                      }}
                    >
                      {/* <ContainerDiv widthInPercentage="80%" height="60vh"> */}
                      <>
                        <div className="row text-start ">
                          <div className="col-md-3 pt-2 ps-4 text-start secondCol2ndHeader">
                            SFSS Scores
                          </div>

                          <div className="col-md-9 text-start pt-2 pe-1 SFSS-rapper-chart">
                            <div
                              className="btn-group pe-1"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              <input
                                type="button"
                                className={
                                  selectedValue === "Externalizing"
                                    ? "EbuttonStyle activeSelectedButton"
                                    : "EbuttonStyle"
                                }
                                value="E"
                                onClick={() => {
                                  setselectedValue(
                                    (prev) => (prev = "Externalizing")
                                  );
                                  // alert("Externalizing")
                                  setTherapistChartValue(
                                    (prev: any) =>
                                      (prev = {
                                        //  therapistArr: therapistDataValue,
                                        therapistArr: [],
                                        caregiverArr: [],
                                        patientArr: [],
                                        arrDates: [],
                                        selectedMonth: "",
                                      })
                                  );
                                  getPatientsSFSSScoresData(
                                    params.pid,
                                    selectedButton,
                                    "Externalizing",
                                    params.tid
                                  );
                                }}
                              />
                              <input
                                type="button"
                                className={
                                  selectedValue === "Internalizing"
                                    ? "IbuttonStyle activeSelectedButton"
                                    : "IbuttonStyle"
                                }
                                value="I"
                                onClick={(e) => {
                                  // setBackgroundCSS("activeSelectedButton");
                                  setselectedValue(
                                    (prev) => (prev = "Internalizing")
                                  );
                                  // alert("Internalizing")
                                  setTherapistChartValue(
                                    (prev: any) =>
                                      (prev = {
                                        //  therapistArr: therapistDataValue,
                                        therapistArr: [],
                                        caregiverArr: [],
                                        patientArr: [],
                                        arrDates: [],
                                        selectedMonth: "",
                                      })
                                  );
                                  getPatientsSFSSScoresData(
                                    params.pid,
                                    selectedButton,
                                    "Internalizing",
                                    params.tid
                                  );
                                }}
                              />
                              <input
                                type="button"
                                className={
                                  selectedValue === "Total"
                                    ? "TbuttonStyle activeSelectedButton"
                                    : "TbuttonStyle"
                                }
                                value="T"
                                onClick={() => {
                                  // setBackgroundCSS("activeSelectedButton");
                                  setselectedValue((prev) => (prev = "Total"));
                                  setTherapistChartValue(
                                    (prev: any) =>
                                      (prev = {
                                        //  therapistArr: therapistDataValue,
                                        therapistArr: [],
                                        caregiverArr: [],
                                        patientArr: [],
                                        arrDates: [],
                                        selectedMonth: "",
                                      })
                                  );
                                  // alert("Total")
                                  getPatientsSFSSScoresData(
                                    params.pid,
                                    selectedButton,
                                    "Total",
                                    params.tid
                                  );
                                }}
                              />
                            </div>
                            <div
                              className="btn-group ps-1"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              <input
                                type="button"
                                className={
                                  selectedButton === 3
                                    ? "EbuttonStyle activeSelectedButton"
                                    : "EbuttonStyle"
                                }
                                value="3mo."
                                onClick={() => {
                                  setSelectedButton((prev) => (prev = 3));
                                  // alert("3")
                                  setTherapistChartValue(
                                    (prev: any) =>
                                      (prev = {
                                        //  therapistArr: therapistDataValue,
                                        therapistArr: [],
                                        caregiverArr: [],
                                        patientArr: [],
                                        arrDates: [],
                                        selectedMonth: 3,
                                      })
                                  );
                                  getPatientsSFSSScoresData(
                                    params.pid,
                                    3,
                                    selectedValue,
                                    params.tid
                                  );
                                }}
                              />
                              <input
                                type="button"
                                className={
                                  selectedButton === 6
                                    ? "IbuttonStyle activeSelectedButton"
                                    : "IbuttonStyle"
                                }
                                value="6mo."
                                onClick={() => {
                                  setSelectedButton((prev) => (prev = 6));
                                  // alert("6")
                                  setTherapistChartValue(
                                    (prev: any) =>
                                      (prev = {
                                        //  therapistArr: therapistDataValue,
                                        therapistArr: [],
                                        caregiverArr: [],
                                        patientArr: [],
                                        arrDates: [],
                                        selectedMonth: 6,
                                      })
                                  );
                                  getPatientsSFSSScoresData(
                                    params.pid,
                                    6,
                                    selectedValue,
                                    params.tid
                                  );
                                }}
                              />
                              <input
                                type="button"
                                className={
                                  selectedButton === 9
                                    ? "TbuttonStyle activeSelectedButton"
                                    : "TbuttonStyle"
                                }
                                value="9mo."
                                onClick={() => {
                                  setSelectedButton((prev) => (prev = 9));
                                  // alert("9")
                                  setTherapistChartValue(
                                    (prev: any) =>
                                      (prev = {
                                        //  therapistArr: therapistDataValue,
                                        therapistArr: [],
                                        caregiverArr: [],
                                        patientArr: [],
                                        arrDates: [],
                                        selectedMonth: 9,
                                      })
                                  );
                                  getPatientsSFSSScoresData(
                                    params.pid,
                                    9,
                                    selectedValue,
                                    params.tid
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* {alert(JSON.stringify(therapistChartValue.therapistArr.length))}  */}
                          {/* {alert("data=======  "+selectedButton)} */}
                          {(therapistChartValue !== undefined || null) &&
                          (therapistChartValue.therapistArr.length !== 0 ||
                            therapistChartValue.caregiverArr.length !== 0 ||
                            therapistChartValue.patientArr.length !== 0 ||
                            therapistChartValue.selectedMonth.length !== 0) ? (
                            <SfssLineChart chartValue={therapistChartValue} />
                          ) : (
                            <div className=" dataNoAvailableContainer">
                              <p className="NoDataColor text-center p-5">
                                {" "}
                                No Data Available
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                      {/* </ContainerDiv> */}
                    </div>
                  </>
                  {/* </ContainerDiv> */}
                </div>
              </div>
            ) : (
              <div>
                <Spinner />
              </div>
            )}
          </CenterOfScreen>
        </>
      </>
    </>
  );
};

export default PatientDetails;

const formatQuestion = (question: string) => {
  return (
    <div>
      {question.includes("...") ? (
        <>
          <span className="pb-2">
            {question.substring(0, question.indexOf("...") + 3)}
          </span>
          <br />
          <div className="ps-1 pt-2 ps-3">
            {question.substring(
              question.indexOf("...") + 3,
              question.indexOf("?") + 1
            )}
          </div>
          <div className="ps-2 pt-1 ps-3">
            {question.substring(
              question.indexOf("?") + 1,
              question.indexOf("?") + 4
            )}
          </div>
          <p className="ps-2 pt-1 ps-3">
            {question.substring(question.indexOf("?") + 4)}
          </p>
        </>
      ) : question.includes(":") ? (
        <>
          <span className="pb-2 ps-3">
            {question.substring(0, question.indexOf(":") + 1)}
          </span>
          <br />
          <div className="ps-2 pt-2 ps-3">
            {question.substring(
              question.indexOf(":") + 2,
              question.indexOf("?") + 1
            )}
          </div>
          <div className="ps-2 pt-1 ps-3">
            {question.substring(
              question.indexOf("?") + 1,
              question.indexOf("?") + 4
            )}
          </div>
          <p className="ps-2 pt-1 ps-3">
            {question.substring(question.indexOf("?") + 4)}
          </p>
        </>
      ) : (
        <span>{question}</span>
      )}
    </div>
  );
};
