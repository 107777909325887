import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Api } from "../Api/ApiClient";
import { DataEntities, getPatients } from "../Api/PatientSearch";
import CenterOfScreen from "../Utils/CenterOfScreen";
import NavBarCont from "../Utils/NavBarCont";
import backIcon from "../Assets/arrow_back-dark.svg";
import SearchBar from "../Utils/SearchBar";
import Spinner from "../Utils/Spinner";
import highRisk from "../Assets/Alert symbol.svg";
import { setNavItems } from "../Utils/NavItems";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../ComponentCSS/PatientSearch.css";

var BaseTable: DataEntities[];
const PatientSearch: React.FC = () => {
  const [getPatientsResponseData, setGetPatientsResponseData] =
    useState<getPatients>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const params: any = useParams();
  let [therapistName, setTherapistName] = useState<any>();
  const notify = () =>
    toast.error("Lost Internet Connection ", {
      position: "top-right",
      autoClose: 2000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  let patientsData: any = [];
  useEffect(() => {
    sessionStorage.removeItem("patientDetails");
    sessionStorage.removeItem("Upcoming_SFSS_Date");
    sessionStorage.removeItem("Upcoming_PHQ9_Date");
    sessionStorage.removeItem("SFSS_Chart_data");
    sessionStorage.removeItem("ace_score_history");
    sessionStorage.removeItem("file_history");
    sessionStorage.removeItem("patients");
    if (
      sessionStorage.getItem("patients") !== undefined &&
      sessionStorage.getItem("patients") !== null
    ) {
      patientsData = sessionStorage.getItem("patients");
      setGetPatientsResponseData(JSON.parse(patientsData));
      BaseTable = patientsData.data;
      setLoading((prev) => (prev = false));
    } else {
      getPatients();
    }
  }, []);

  const getPatients = async () => {
    if (sessionStorage.getItem("Role") === "Supervisor") {
      if (params["id"] !== undefined && params["id"] !== null) {
        try {
          const getPatientsResponse: AxiosResponse = await Api.getPatientsList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            params["id"],
            "therapist_code"
          );

          setTherapistName(sessionStorage.getItem("therapistName"));
          if (getPatientsResponse.status === 200) {
            setGetPatientsResponseData(getPatientsResponse.data);
            sessionStorage.setItem(
              "patients",
              JSON.stringify(getPatientsResponse.data)
            );
            BaseTable = getPatientsResponse.data.data;
            setLoading((prev) => (prev = false));
          }
        } catch (error) {
          console.log(JSON.stringify(error));
          notify();
        }
      } else {
        try {
          const getPatientsResponse: AxiosResponse = await Api.getPatientsList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            sessionStorage.getItem("supervisorId")!.toString(),
            // "TEST197610",
            "supervisor_id"
          );

          if (getPatientsResponse.status === 200) {
            setGetPatientsResponseData(getPatientsResponse.data);
            sessionStorage.setItem(
              "patients",
              JSON.stringify(getPatientsResponse.data)
            );
            BaseTable = getPatientsResponse.data.data;
            setLoading((prev) => (prev = false));
          }
        } catch (error) {
          console.log(JSON.stringify(error));
          notify();
        }
      }
    } else if (sessionStorage.getItem("Role") === "Therapist") {
      try {
        const getPatientsResponse: AxiosResponse = await Api.getPatientsList(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          sessionStorage.getItem("therapist_code")!.toString(),
          //  "TEST190811",
          "therapist_code"
        );

        if (getPatientsResponse.status === 200) {
          setGetPatientsResponseData(getPatientsResponse.data);
          sessionStorage.setItem(
            "patients",
            JSON.stringify(getPatientsResponse.data)
          );
          BaseTable = getPatientsResponse.data.data;
          setLoading((prev) => (prev = false));
        }
      } catch (error) {
        console.log(JSON.stringify(error));
        notify();
      }
    } else if (sessionStorage.getItem("Role") === "Utilization Manager") {
      try {
        //Needs to be done yet
        var getPatientsResponse: AxiosResponse;
        if (params["agency"] !== undefined) {
          getPatientsResponse = await Api.getPatientsList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            sessionStorage.getItem("PayerOrgName")!.toString(),
            "payer",
            params["id"],
            ""
          );
        } else {
          getPatientsResponse = await Api.getPatientsList(
            encodeURIComponent(sessionStorage.getItem("token")!.toString()),
            sessionStorage.getItem("PayerOrgName")!.toString(),
            "payer",
            "",
            params["id"]
          );
        }

        //Needs to be done yet
        if (getPatientsResponse.status === 200) {
          setGetPatientsResponseData(getPatientsResponse.data);
          sessionStorage.setItem(
            "patients",
            JSON.stringify(getPatientsResponse.data)
          );
          BaseTable = getPatientsResponse.data.data;
          setLoading((prev) => (prev = false));
        }
      } catch (error) {
        console.log(JSON.stringify(error));
        notify();
      }
    }
  };

  const searchStringHandler = (searchString: string) => {
    const byQuery = (searchValue: string) => (item: DataEntities) =>
      !searchString ||
      item.full_name?.toLowerCase().includes(searchValue.toLowerCase());

    const filteredList = getPatientsResponseData?.data!.filter(
      byQuery(searchString)
    );

    if (filteredList !== null || filteredList !== undefined) {
      console.log("IN IF" + JSON.stringify(filteredList));
      console.log("BASETABLE" + JSON.stringify(BaseTable));
      console.log("Search===" + searchString.length);

      filteredList!.length !== 0
        ? searchString.length === 0
          ? setGetPatientsResponseData(
              (prev) =>
                (prev = {
                  status: "Ok",
                  data: BaseTable.sort((a: any, b: any) => {
                    var a1 = a.full_name.toLowerCase();
                    var b1 = b.full_name.toLowerCase();
                    return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
                  }),
                })
            )
          : setGetPatientsResponseData(
              (prev) =>
                (prev = {
                  status: "Ok",
                  data: filteredList?.sort((a: any, b: any) => {
                    var a1 = a.full_name.toLowerCase();
                    var b1 = b.full_name.toLowerCase();
                    return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
                  }),
                })
            )
        : setGetPatientsResponseData(
            (prev) =>
              (prev = {
                status: "Ok",
                data: BaseTable.sort((a: any, b: any) => {
                  var a1 = a.full_name.toLowerCase();
                  var b1 = b.full_name.toLowerCase();
                  return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
                }),
              })
          );
    } else {
      // alert("no such record");
      setGetPatientsResponseData(
        (prev) =>
          (prev = {
            status: "Ok",
            data: BaseTable.sort((a: any, b: any) => {
              var a1 = a.full_name.toLowerCase();
              var b1 = b.full_name.toLowerCase();
              return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            }),
          })
      );
    }

    getPatientsResponseData!.data!.sort((a, b) =>
      a.age!.toString().localeCompare(b.age!.toString())
    );
  };

  return (
    <React.Fragment>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <CenterOfScreen>
        <div
          // style={{ width: "70%", paddingTop: "6%" }}
          className="outer-table"
        >
          {loading === false ? (
            <div>
              {params["id"] === null || params["id"] === undefined ? (
                <SearchBar
                  placeHolderValue="Patients"
                  OnInputChangeHandler={(value: string) => {
                    searchStringHandler(value);
                  }}
                />
              ) : null}
              <div
                className="backGroundWhite"
                style={{ borderRadius: "10px", padding: "3%" }}
              >
                {sessionStorage.getItem("Role") === "Supervisor" ? (
                  <>
                    {params["id"] === null || params["id"] === undefined ? (
                      <></>
                    ) : (
                      <>
                        <div className="row">
                          <div id="back">
                            <img
                              src={backIcon}
                              alt="go back"
                              onClick={() => {
                                history.goBack();
                              }}
                            />
                          </div>
                        </div>
                        <div className="row p-3">
                          <h2>{therapistName}: Patients</h2>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                {sessionStorage.getItem("Role") === "Utilization Manager" ? (
                  <>
                    {params["id"] === null || params["id"] === undefined ? (
                      <></>
                    ) : (
                      <>
                        <div className="row">
                          <div id="back">
                            <img
                              src={backIcon}
                              alt="go back"
                              onClick={() => {
                                history.goBack();
                              }}
                            />
                          </div>
                        </div>
                        <div className="row p-3">
                          <h2>{params["name"]}: Patients</h2>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                <div className="table-responsive">
                  <div className="container" style={{ height: "58vh" }}>
                    <table
                      className="spacingBetweenRows"
                      style={{ width: "inherit", minWidth: "600px" }}
                    >
                      <thead
                        style={{ paddingRight: "1.5%", paddingBottom: "0.6%" }}
                      >
                        <tr className="backGroundWhite">
                          <th>Patient</th>
                          <th>Age</th>
                          <th>Status</th>
                          <th>
                            Last SFSS
                            <p>taken on</p>
                          </th>
                          <th
                          // style={{
                          //   width: "20%",
                          //   textAlign: "right",
                          //   paddingRight: "1%",
                          // }}
                          >
                            SFSS:
                          </th>

                          <th
                            className="skyBlue sfssHeading"
                            style={{
                              // width: "91%",
                              borderRadius: "11px 11px 11px 11px",
                              // width: "29%",
                            }}
                          >
                            Therapist&nbsp; &nbsp; &nbsp; Caregiver &nbsp;
                            &nbsp; Patient&nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ paddingRight: "1%", maxHeight: "50vh" }}>
                        {getPatientsResponseData !== undefined &&
                        getPatientsResponseData.data! &&
                        getPatientsResponseData.data.length !== 0 ? (
                          getPatientsResponseData.data!.map((item, key) => (
                            <tr className="skyBlue" key={key + "rows"}>
                              <td>
                                <div
                                  className="backGroundWhite"
                                  style={{ borderRadius: "12px", width: "8em" }}
                                >
                                  <div
                                    style={{
                                      // paddingTop: "5%",
                                      // paddingBottom: "5%",
                                      cursor: "pointer",
                                      textAlign: "left",
                                      paddingLeft: "0.5em",
                                      display: "flex",
                                    }}
                                    onClick={() => {
                                      history.push(
                                        `/patientDetails/${item.patient_id}/${item.therapist_id}`
                                      );
                                    }}
                                  >
                                    {item.full_name}
                                    {parseInt(item.CG_total) >= 60 ||
                                    parseInt(item.Therapist_total) >= 60 ||
                                    parseInt(item.Patient_total) >= 60 ||
                                    item.ace_score! >= 3 ? (
                                      <span>
                                        <img
                                          src={highRisk}
                                          alt="high risk"
                                          className="AlertSymbol"
                                        />
                                      </span>
                                    ) : (
                                      <span>
                                        <img
                                          style={{ visibility: "hidden" }}
                                          src={highRisk}
                                          alt="high risk"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                {`${item.age.substring(0, 2)}y ${
                                  item.age.substring(3, 5).charAt(0) === "0"
                                    ? item.age.substring(4, 5)
                                    : item.age.substring(3, 5)
                                }m`}
                              </td>
                              <td>{item.status}</td>
                              <td>
                                {item.LastSurveyDate === null
                                  ? "--"
                                  : item.LastSurveyDate}
                              </td>
                              {/* <td  style={{ width: "100%"}}></td> */}
                              <td
                                className="sfssTableData"
                                // style={{ width: "113%", paddingBottom: "2%" }}
                              >
                                {/* <td className="tbodyBorder">
                          <div className="td_small">{`E ${item.Therapist_internalizing_score}`}</div>
                        </td>
                        <td className="tbodyBorder">
                          <div className="td_small">
                            {item.CG_internalizing_score}
                          </div>
                        </td>
                        <td
                          className="tbodyBorder"
                          style={{ borderBottom: "0.5px dashed #2eb5b9" }}
                        >
                          <div className="td_small">
                            {item.Patient_internalizing_score}
                          </div>
                        </td> */}
                                <table
                                  className="tableStyle"
                                  // style={{ width: "5%", minWidth: "600px" }}
                                >
                                  {/* <thead>
                            <tr className="p-2">
                              <th></th>
                              <th style={{ color: "#2EB5B9" }}>i</th>
                              <th style={{ color: "#2EB5B9" }}>j</th>
                              <th style={{ color: "#2EB5B9" }}>k</th>
                            </tr>
                          </thead> */}

                                  {/* border-bottom: 0.5px dashed #2eb5b9;
  color: #2eb5b9;
  font-weight: normal;
  width: 58% !important; */}
                                  <tbody>
                                    <tr className="tbodyBorder">
                                      <td
                                        className="td_small"
                                        // style={{ width: "521%" }}
                                      >
                                        <span
                                        // style={{
                                        //   display: "inline-flex",
                                        //   position: "relative",
                                        //   left: "-24%",
                                        //   width: "14px",
                                        //   color: "#2eb5b947",
                                        // }}
                                        >
                                          E
                                        </span>
                                        <span className="scoreData">{` ${
                                          item.Therapist_externalizing_score ===
                                          ""
                                            ? "--"
                                            : item.Therapist_externalizing_score
                                        }`}</span>
                                      </td>
                                      <td
                                        className="td_small scoreDataCaregiver"
                                        // style={{ width: "366%" }}
                                      >
                                        {item.CG_externalizing_score}
                                      </td>
                                      <td
                                        className="td_small scoreData1 "
                                        // style={{ width: "307%" }}
                                      >
                                        {item.Patient_externalizing_score}
                                      </td>
                                    </tr>
                                    <tr className="tbodyBorder">
                                      <td
                                        className="td_small"
                                        // style={{ width: "521%" }}
                                      >
                                        <span
                                        // style={{
                                        //   display: "inline-flex",
                                        //   position: "relative",
                                        //   left: "-24%",
                                        //   width: "15px",
                                        //   color: "#2eb5b947",
                                        // }}
                                        >
                                          I
                                        </span>
                                        <span
                                          className="scoreData"
                                          // style={{ paddingLeft: "20%" }}
                                        >{` ${
                                          item.Therapist_internalizing_score ===
                                          ""
                                            ? "--"
                                            : item.Therapist_internalizing_score
                                        }`}</span>
                                      </td>
                                      <td
                                        className="td_small scoreDataCaregiver"
                                        // style={{ width: "366%" }}
                                      >
                                        {item.CG_internalizing_score}
                                      </td>
                                      <td
                                        className="td_small scoreData1 "
                                        // style={{ width: "307%" }}
                                      >
                                        {item.Patient_internalizing_score}
                                      </td>
                                    </tr>
                                    <tr className="tbodyBorder">
                                      <td
                                        className="td_small"
                                        // style={{ width: "521%" }}
                                      >
                                        <span
                                        // style={{
                                        //   display: "inline-flex",
                                        //   position: "relative",
                                        //   left: "-24%",
                                        //   width: "14px",
                                        //   color: "#2eb5b947",
                                        // }}
                                        >
                                          T
                                        </span>

                                        <span
                                          className="scoreData  "
                                          // style={{ paddingLeft: "20%" }}
                                        >{`${
                                          item.Therapist_total === ""
                                            ? "--"
                                            : item.Therapist_total
                                        }`}</span>
                                      </td>
                                      <td
                                        className="td_small scoreDataCaregiver "
                                        // style={{ width: "366%" }}
                                      >
                                        {item.CG_total}
                                      </td>
                                      <td
                                        className="td_small scoreData1 "
                                        // style={{ width: "307%" }}
                                      >
                                        {item.Patient_total}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="text-center">
                            <span className="NoDataColor">
                              No Data Available
                            </span>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </CenterOfScreen>
    </React.Fragment>
  );
};

export default PatientSearch;
