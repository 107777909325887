import React from 'react';
import Highcharts from 'highcharts';
import "../CSS/Pie.css";

class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [{
                name: 'Count',
                colorByPoint: true,
                data: props.value,
                showInLegend: true,
                dataLabels: {
                    enabled: false,
                    className: "labels"
                }
            }]
        }
    }

    highChartsRender() {
        Highcharts.chart('pieChartContainer', {
            chart: {
                // plotBackgroundColor: null,
                // plotBorderWidth: null,
                // plotShadow: false,
                //styledMode: true,
                type: 'pie',
                plotBackgroundColor: null,
                //plotBorderWidth: null,
                plotShadow: false
            },

            title: {
                text: null,
                align: "center"
            },
            legend: {
                //layout: 'vertical',
                align: 'center',
                verticalAlign: 'bottom',
                //y: 50,
                //padding: 3,
                itemMarginTop: 8,
                itemMarginBottom: 8,
                itemStyle: {
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: "14px"
                },
                useHTML: true,
                //labelFormatter: () => `<span>${JSON.stringify(this.state.series.map(items=>items.data.map(val=>val.name)))}</span><img src=${alertIcon}/>`


            },

            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    startAngle: 360,
                    colors: ["#FFB400", "#07D106", "#2EB5B9"],
                    layout: "Vertical",


                }

            },
            series: this.state.series
        });
    }

    componentDidMount() {
        this.highChartsRender();
    }

    render() {
        return (


            <div className="row">
                <div id="pieChartContainer"
                    style={{ width: "500px", height: "400px" }}>
                </div>
            </div>


        );
    }
}

export default PieChart;