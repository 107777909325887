// // export const SPAdminNavItems =  [
// //     {
// //         title :"Organization",
// //         url: "/OrganizationDashboard",
// //         cName:'nav-links navItemsBtn1 active',
// //         to:''
// //     },
// //     {
// //         title :"Import",
// //         url: "#",
// //         cName:'nav-links navItems',
// //         to:''
// //     },
// //     {
// //         title :"User",
// //         url: "/SPAdminUsers",
// //         cName:'nav-links navItems',
// //         to:''
// //     },
// //     {
// //         title :"Therapist Mapping",
// //         url: "/TherapistMappingSPAdmin",
// //         cName:'nav-links navItemsBtnlast',
// //         to:''
// //     }
// // ]

// // export const SuperAdminNavItems =  [

// //     {
// //         title :"User",
// //         url: "#",
// //         cName:'nav-links navItemsBtn1 active',
// //         to:''
// //     },
// //     {
// //         title :"Therapist Mapping",
// //         url: "#",
// //         cName:'nav-links navItemsBtnlast',
// //         to:''
// //     }
// // ]

// // export const SupervisorNavItems =  [
// //     {
// //         title :"Dashboard",
// //         url: "/supervisorDashboard",
// //         cName:'nav-links navItemsBtn1 active',
// //         to:''
// //     },
// //     {
// //         title :"Import",
// //         url: "#",
// //         cName:'nav-links navItems ',
// //         to:''
// //     },
// //     {
// //         title :"User",
// //         url: "/User",
// //         cName:'nav-links navItems',
// //         to:''
// //     },
// //     {
// //         title :"Therapist Search",
// //         url: "/TherapistSearch",
// //         cName:'nav-links navItems',
// //         to:''
// //     },
// //     {
// //         title :"Patient Search",
// //         url: "/PatientSearch",
// //         cName:'nav-links navItemsBtnlast',
// //         to:''
// //     }
// // ]

// // export const TherapistNavItems =  [
// //     {
// //         title :"Dashboard",
// //         url: "#",
// //         cName:'nav-links navItemsBtn1 active',
// //         to:''
// //     },
// //     {
// //         title :"Patient Search",
// //         url: "/PatientSearch",
// //         cName:'nav-links navItemsBtnlast',
// //         to:''
// //     }
// // ]
// // export const UtilizationNavItems =  [
// //     {
// //         title :"Dashboard",
// //         url: "/UMDashboard",
// //         cName:'nav-links navItemsBtn1 active',
// //         to:''
// //     },
// //     {
// //         title :"Agencies",
// //         url: "/Agencies",
// //         cName:'nav-links navItems',
// //         to:''
// //     },
// //     {
// //         title :"Therapist",
// //         url: "/TherapistSearch",
// //         cName:'nav-links navItems',
// //         to:''
// //     },
// //     {
// //         title :"Patient",
// //         url: "/PatientSearch",
// //         cName:'nav-links navItemsBtnlast',
// //         to:''
// //     }
// // ]

// // export const VoicelablingNavItems =  [
// //     {
// //         title :"Home",
// //         url: "",
// //         cName:'nav-links navItemsBtn1 active',
// //         to:''
// //     }
// // ]

// import React from "react";

// // function NavItems() {

// //     const [navItemValue, setNavItemValue] = useState([{}])

// //     useEffect(() => {
// //        checkRole()
// //     }, [])

// //     const checkRole = async()=>{
// //         var role = sessionStorage.getItem("Role")
// //         if(role === "Therapist"){
// //              const TherapistNavItems =  [
// //                     {
// //                         title :"Dashboard",
// //                         url: "#",
// //                         cName:'nav-links navItemsBtn1 active',
// //                         to:''
// //                     },
// //                     {
// //                         title :"Patient Search",
// //                         url: "/PatientSearch",
// //                         cName:'nav-links navItemsBtnlast',
// //                         to:''
// //                     }
// //                 ]
// //             setNavItemValue(TherapistNavItems)
// //         }
// //     }
// //     return (
// //         <div>

// //         </div>
// //     )
// // }
// let navItems = [{}];
// let checkRole = sessionStorage.getItem("Role");
// console.log("first" + checkRole);

// if (checkRole === "Therapist") {
//   console.log("=====" + checkRole);
//   navItems = [
//     {
//       title: "Dashboard",
//       url: "#",
//       cName: "nav-links navItemsBtn1 ",
//       to: "",
//     },
//     {
//       title: "Patient Search",
//       url: "/PatientSearch",
//       cName: "nav-links navItemsBtnlast",
//       to: "",
//     },
//   ];
//   console.log("nav" + JSON.stringify(navItems));
// } else if (checkRole === "SP Admin") {
//   navItems = [
//     {
//       title: "Organization",
//       url: "/OrganizationDashboard",
//       cName: "nav-links navItemsBtn1 ",
//       to: "",
//     },
//     {
//       title: "Import",
//       url: "#",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "User",
//       url: "/SPAdminUsers",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Therapist Mapping",
//       url: "/TherapistMappingSPAdmin",
//       cName: "nav-links navItemsBtnlast",
//       to: "",
//     },
//   ];
// } else if (checkRole === "Admin") {
//   navItems = [
//     {
//       title: "User",
//       url: "#",
//       cName: "nav-links navItemsBtn1 ",
//       to: "",
//     },
//     {
//       title: "Therapist Mapping",
//       url: "#",
//       cName: "nav-links navItemsBtnlast",
//       to: "",
//     },
//   ];
// } else if (checkRole === "Supervisor") {
//   navItems = [
//     {
//       title: "Dashboard",
//       url: "/supervisorDashboard",
//       cName: "nav-links navItemsBtn1 ",
//       to: "",
//     },
//     {
//       title: "Import",
//       url: "#",
//       cName: "nav-links navItems ",
//       to: "",
//     },
//     {
//       title: "User",
//       url: "/User",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Therapist Search",
//       url: "/TherapistSearch",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Patient Search",
//       url: "/PatientSearch",
//       cName: "nav-links navItemsBtnlast",
//       to: "",
//     },
//   ];
// } else if ((checkRole = "Voice Labeling")) {
//   navItems = [
//     {
//       title: "Dashboard",
//       url: "/UMDashboard",
//       cName: "nav-links navItemsBtn1 ",
//       to: "",
//     },
//     {
//       title: "Agencies",
//       url: "/Agencies",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Therapist",
//       url: "/TherapistSearch",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Patient",
//       url: "/PatientSearch",
//       cName: "nav-links navItemsBtnlast",
//       to: "",
//     },
//   ];
// } else if (checkRole === "Utilization Manager") {
//   navItems = [
//     {
//       title: "Dashboard",
//       url: "/UMDashboard",
//       cName: "nav-links navItemsBtn1 ",
//       to: "",
//     },
//     {
//       title: "Agencies",
//       url: "/Agencies",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Therapist",
//       url: "/TherapistSearch",
//       cName: "nav-links navItems",
//       to: "",
//     },
//     {
//       title: "Patient",
//       url: "/PatientSearch",
//       cName: "nav-links navItemsBtnlast",
//       to: "",
//     },
//   ];
// }

// export default navItems;

export const setNavItems = (checkRole: string) => {
  //let navItems = [{}];
  // let checkRole = sessionStorage.getItem("Role");
  // console.log("first" + checkRole);
  // let checkRole = sessionStorage.getItem("Role");
  // console.log("first" + checkRole);

  if (checkRole === "Therapist") {
    console.log("=====" + checkRole);
    return [
      {
        title: "Dashboard",
        url: "/TherapistDashboard",
        cName: "nav-links navItemsBtn1 ",
        to: "",
      },
      {
        title: "Patient Search",
        url: "/PatientSearch",
        cName: "nav-links navItemsBtnlast",
        to: "",
      },
    ];
    // console.log("nav" + JSON.stringify(navItems));
  } else if (checkRole === "SP Admin") {
    return [
      {
        title: "Organization",
        url: "/OrganizationDashboard",
        cName: "nav-links navItemsBtn1 ",
        to: "",
      },
      {
        title: "Import",
        url: "/importPatients",
        cName: "nav-links navItems",
        to: "",
      },
      {
        title: "User",
        url: "/SPAdminUsers",
        cName: "nav-links navItems",
        to: "",
      },
      {
        title: "Therapist Mapping",
        url: "/TherapistMappingSPAdmin",
        cName: "nav-links navItemsBtnlast",
        to: "",
      },
    ];
  } else if (checkRole === "Admin") {
    return [
      {
        title: "User",
        url: "/SuperAdminUser",
        cName: "nav-links navItemsBtn1 ",
        to: "",
      },
      {
        title: "Therapist Mapping",
        url: "/SuperAdminMapping",
        cName: "nav-links navItemsBtnlast",
        to: "",
      },
    ];
  } else if (checkRole === "Supervisor") {
    return [
      {
        title: "Dashboard",
        url: "/supervisorDashboard",
        cName: "nav-links navItemsBtn1 ",
        to: "",
      },
      // {
      //   title: "Import",
      //   url: "/importPatients",
      //   cName: "nav-links navItems ",
      //   to: "",
      // },
      // {
      //   title: "User",
      //   url: "/User",
      //   cName: "nav-links navItems",
      //   to: "",
      // }, 
      {
        title: "Therapist Search",
        url: "/TherapistSearch",
        cName: "nav-links navItems",
        to: "",
      },
      {
        title: "Patient Search",
        url: "/PatientSearch",
        cName: "nav-links navItemsBtnlast",
        to: "",
      },
    ];
  } else if (checkRole === "Voice Labeling") {
    return [
      {
        title: "Home",
        url: "/PatientSearchVL",
        cName: "nav-links navItemsSingle active",
        to: "",
      },
    ];
  } else if (checkRole === "Utilization Manager") {
    return [
      {
        title: "Dashboard",
        url: "/UMDashboard",
        cName: "nav-links navItemsBtn1 ",
        to: "",
      },
      {
        title: "Agencies",
        url: "/Agencies",
        cName: "nav-links navItems",
        to: "",
      },
      {
        title: "Therapists",
        url: "/TherapistSearch",
        cName: "nav-links navItems",
        to: "",
      },
      {
        title: "Patients",
        url: "/PatientSearch",
        cName: "nav-links navItemsBtnlast",
        to: "",
      },
    ];
  }
};

//   if (checkRole === "Therapist") {
//     console.log("=====" + checkRole);
//     return [
//       {
//         title: "Dashboard",
//         url: "#",
//         cName: "nav-links navItemsBtn1 ",
//         to: "",
//       },
//       {
//         title: "Patient Search",
//         url: "/PatientSearch",
//         cName: "nav-links navItemsBtnlast",
//         to: "",
//       },
//     ];
//     // console.log("nav" + JSON.stringify(navItems));
//   } else if (checkRole === "SP Admin") {
//     return [
//       {
//         title: "Organization",
//         url: "/OrganizationDashboard",
//         cName: "nav-links navItemsBtn1 ",
//         to: "",
//       },
//       {
//         title: "Import",
//         url: "#",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "User",
//         url: "/SPAdminUsers",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Therapist Mapping",
//         url: "/TherapistMappingSPAdmin",
//         cName: "nav-links navItemsBtnlast",
//         to: "",
//       },
//     ];
//   } else if (checkRole === "Admin") {
//     return [
//       {
//         title: "User",
//         url: "/User",
//         cName: "nav-links navItemsBtn1 ",
//         to: "",
//       },
//       {
//         title: "Therapist Mapping",
//         url: "/TherapistMappingSPAdmin",
//         cName: "nav-links navItemsBtnlast",
//         to: "",
//       },
//     ];
//   } else if (checkRole === "Supervisor") {
//     return [
//       {
//         title: "Dashboard",
//         url: "/supervisorDashboard",
//         cName: "nav-links navItemsBtn1 ",
//         to: "",
//       },
//       {
//         title: "Import",
//         url: "#",
//         cName: "nav-links navItems ",
//         to: "",
//       },
//       {
//         title: "User",
//         url: "/User",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Therapist Search",
//         url: "/TherapistSearch",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Patient Search",
//         url: "/PatientSearch",
//         cName: "nav-links navItemsBtnlast",
//         to: "",
//       },
//     ];
//   } else if ((checkRole = "Voice Labeling")) {
//     return [
//       {
//         title: "Dashboard",
//         url: "/UMDashboard",
//         cName: "nav-links navItemsBtn1 ",
//         to: "",
//       },
//       {
//         title: "Agencies",
//         url: "/Agencies",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Therapist",
//         url: "/TherapistSearch",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Patient",
//         url: "/PatientSearch",
//         cName: "nav-links navItemsBtnlast",
//         to: "",
//       },
//     ];
//   } else if (checkRole === "Utilization Manager") {
//     return [
//       {
//         title: "Dashboard",
//         url: "/UMDashboard",
//         cName: "nav-links navItemsBtn1 ",
//         to: "",
//       },
//       {
//         title: "Agencies",
//         url: "/Agencies",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Therapist",
//         url: "/TherapistSearch",
//         cName: "nav-links navItems",
//         to: "",
//       },
//       {
//         title: "Patient",
//         url: "/PatientSearch",
//         cName: "nav-links navItemsBtnlast",
//         to: "",
//       },
//     ];
//   }
// };
