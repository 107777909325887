import React, {  useState } from 'react'
// import CenterOfScreen from '../Utils/CenterOfScreen'
import NavBarCont from '../Utils/NavBarCont'
import { setNavItems } from '../Utils/NavItems'
import "../ComponentCSS/ImportFile.css"
// import CustomButton from '../Utils/CustomButton'
// import Button from '@material-ui/core/Button';
import { AxiosResponse } from 'axios'
import { Api } from '../Api/ApiClient'
// import TableContainer from '../Utils/TableContainer'
import { DOWNLOAD_TEMPLATE_URL } from '../Api/ApiConstants'
import Spinner from "../Utils/Spinner";
import {  toast } from "react-toastify";


export interface ImportResponseDatatype {
    status: string;
    new : [];
    matched : [];
    modified : [];
    error : [];
    total_count: number;
  }
//   export interface Data {
//     error?: (string)[] | null;
//     new?: (string)[] | null;
//     match?: (string)[] | null;
//     records?: (string)[] | null;
//     rows?: (number)[] | null;
//     unModified?: (string)[] | null;
//     duplicate?: (null)[] | null;
//     rowsData: RowsData;
//   }
  export interface RowsData {
    new: number;
    match: number;
    error: number;
    total: number;
    unModified: number;
    duplicate: number;
  }

//   interface ImportData {
    
//     "Row No"?: number;
//     data?: string;
//     msg?: string;
    
    
//   }



function ImportPatient() {
    const [fileName, setfileName] = useState("No File Chosen");
    const [loading, setLoading] = useState<boolean>(true);
    // var arrErrorData:string[] = []
    // const [tblErrorData, setTblErrorData] = useState<string[]>()
    // var arrData:string[] =[]
    // const [tblData, setTblData] = useState<string[]>()
    // var arrRow:number[] = []
    // const [tblRow, setTblRow] = useState<number[]>()
    // var tableData: ImportData[] = [];
    const [importResponseData, setImportResponseData] = useState<ImportResponseDatatype>()
    // var importResponse :ImportResponseDatatype
    // var newEntry :number
    // var errorData :number
    // var totalEntry :number
    // var unModifiedEntry :number
    // var duplicateEntry :number
    // const [newEntry, setNewEntry] = useState<number>()
    // const [rowData, setRowData] = useState<RowsData >()
    const [processButton, setProcessButton] = useState(true)
    // const [table, setTable] = useState<ImportData[]>([{}]);
    const [displayBlock, setDisplayBlock] = useState(false)
    
    const postApiCallUploadXl =async (files: FileList  ): Promise<void>=>
    {
        setfileName(files[0]!.name)
        // alert("hiiiii"+files[0]!.name) 
        const formData: FormData = new FormData();
    
        if (files !== null) {
        
        formData.append("file", files[0]);
        
        if (formData.get("file") !== undefined) {
        
            const response: AxiosResponse = await Api.uploadFile(
                encodeURIComponent(sessionStorage.getItem("token")!.toString()),
                formData
            );
            response.data.status !== undefined
                ? console.log(JSON.stringify(response.data.status))
                : console.log("try again")
            
            // alert(formData)
        }
        }
        setProcessButton(false)
    }

    const onInputClick= (e:any)=>{
        e.target.value=""
    }

    const importFile = async (importFlag:string) =>{
        setLoading((prev) => (prev = false));
        if(fileName === ""){
            notify("File Not Selected", "error");
        }
        else{
            if(importFlag === "false"){
                const response: AxiosResponse = await Api.importFile(
                    encodeURIComponent(sessionStorage.getItem("token")!.toString()),
                    fileName, 
                    "GET"
                );
                
                var responseData = response.data;
                setImportResponseData(responseData);
                setLoading((prev) => (prev = true));
                setDisplayBlock(true);
            }
            else{
                const response: AxiosResponse = await Api.importFile(
                    encodeURIComponent(sessionStorage.getItem("token")!.toString()),
                    fileName, 
                    "POST",
                    importResponseData
                );
                
                 responseData = response.data;
                notify("Data Imported Successfully", "success");
                // setImportResponseData(responseData);
                setLoading((prev) => (prev = true));
                setDisplayBlock(false);
            }
            
            //console.log("response of checking pateints data:"+response);

        }
        // if (fileName === "No file chosen"){
        //     alert("Please upload a file")
        // } else if(importFlag == "true" && importResponseData?.data.rowsData.new === 0 && importResponseData?.data.rowsData.match === 0  ){
        //     // alert("hhh")
        //     setDisplayBlock(false)
        //     setProcessButton(true)
        //     alert("No new or existing patient data to be updated");
            
        // }
        // else{
        //     const response: AxiosResponse = await Api.importFile(
        //         encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        //         fileName,
        //         importFlag
        //     );
        //     importResponse = response.data
        //     setImportResponseData(response.data)
        //     setDisplayBlock(true)
        //     if(importResponse.status === "success"){
        //         // alert("Check"+JSON.stringify(response.data))
        //         setLoading((prev) => (prev = false));
        //         for (var i = 0; i < importResponse.data.error!.length; i++) {
        //         if (importResponse.data.error![i] != "") {
        //             arrErrorData.push(response.data.data.error[i].split(","));
        //             arrData.push(response.data.data.records[i].split(","));
        //             arrRow.push(response.data.data.rows[i]); 
        //         }
        //       }
        //       setTblRow(arrRow)
        //       setTblData(arrData)
        //       setTblErrorData(arrErrorData)
        //       if (importResponseData && importResponseData.data.rowsData !== undefined){
               
        //         errorData = importResponse.data.rowsData.error
        //         totalEntry = importResponse.data.rowsData.total
        //         unModifiedEntry = importResponse.data.rowsData.unModified 
        //         duplicateEntry = importResponse.data.rowsData.duplicate 
                
        //       }

        //       for (let i = 0; i < importResponse!.data.rows!.length ; i++) {
                
        //           tableData.push({
        //             "Row No":importResponse!.data.rows![i],
        //             Data:importResponse!.data.records![i],
        //             Error:importResponse!.data.error![i]
                          
        //           });
                
        //       }
        //       setTable((prevData) => (prevData = tableData))
        //     //   console.log("check table data"+tableData);
        //       setLoading((prev) => (prev = true));
        //     }



        // }

        
    }
    const notify = (msg: any, type: any) => {
        if (type === "error") {
          toast.error(msg, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.info(msg, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
    // const cancelImportFile =async(clearData:any)=>{
    //     // alert("hhii")
    //     // setImportResponseData((prevData) =>(prevData = clearData))
    //     // console.log(("check"+ JSON.stringify(importResponseData)));
        
    // }
    return (
        <div>
            <NavBarCont
                jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
            ></NavBarCont>
           
                <>
                    <div className="ImportOuterDiv">
                    <div className = "row">
                        <div className="col-md-12">
                            <h2 className="headingStyle">Import Patient</h2>
                        </div>
                    </div>
                    <div className="row" style={{ width: "80%", paddingTop: "2%", backgroundColor:"white", height:"15vh"}}>
                    {loading === true ? (<>
                            <div className="col-md-12 ">
                                <div className="align-middle">
                                    
                                        <input
                                            type="file"
                                            accept=".xlsx"
                                            id="file-input_uplaod"
                                            className="inputfile"
                                            onChange={(event:any)=>{
                                                postApiCallUploadXl(event.target.files);
                                            }}
                                            onClick={(event:any)=>{
                                                onInputClick(event)
                                            }}
                                        />
                                        <label htmlFor="file-input_uplaod" className="width100">
                                            <span id="file-name" className="file-box" placeholder="No file chosen">{fileName}</span>
                                            <span className="file-button" style={{cursor:"pointer"}}>
                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                            &nbsp; Upload File
                                            </span>
                                        </label>
                                        <span className="process-button">
                                        <button
                                            style={{cursor:"pointer"}}
                                                type="submit"
                                                className="btn processFileButton "
                                                onClick={()=>{importFile("false")}}
                                                disabled={processButton}
                                                >
                                                Process</button>
                                            {/* <a className="d-flex justify-content-end " href ="#"> Download completed</a> */}
                                        </span>
                                        <span className="">
                                            <a
                                                className="btn downloadStyle text-end"
                                                // (click)="downloadTemplate(isImport)"
                                                href={DOWNLOAD_TEMPLATE_URL}
                                                download
                                            >
                                                Download Template
                                            </a>
                                            </span>
                                </div> 
                            </div>
                            </>
                    ) : (
                     <Spinner />
                   )}
                    </div>
                     
                    {/* <div className = "row" style={{ width: "80%", backgroundColor:"white", height:"60vh"}}>
                                    <div className = "col-md-3">
                                    </div>
                                    <div className = "col-md-6 middleWindow m-2  ">
                                        kdncs
                                    </div>
                                    <div className = "col-md-3">
                                    </div>
                    </div> */}
                  
                       { displayBlock === true ? 
                            <>
                            <div className = "row" style={{ width: "80%", backgroundColor:"white", overflowY:"auto"}}>
                                <div className = "col-md-4" >
                                </div>
                                <div className = "col-md-4 middleWindow pt-2  ">
                                    <span> New: {importResponseData && importResponseData?.new.length}</span>
                                    <span> Modified: {importResponseData && importResponseData?.modified.length}</span>
                                    <span> Unmodified: {importResponseData && importResponseData?.matched.length}</span>
                                    <span> Invalid: {importResponseData && importResponseData?.error.length}</span>
                                    {/* <span> Total : {importResponseData && importResponseData?.data.rowsData.total}</span> */}
                                    <span> Continue to import valid data?</span>
                                    {/* <hr style={{border: "#0a4364 2px solid"}}></hr> */}
                                    <div className="row mt-4 p-2 ">
                                        <div className = "col-md-6 ">
                                        <button
                                                type="submit"
                                                className="btn file-button ps-5 pe-5 pt-2 "
                                                onClick={()=>{importFile("true")}}
                                                >
                                                Yes</button>
                                        </div>
                                        <div className = "col-md-6">
                                        <button
                                                type="submit"
                                                className="btn file-button ps-5 pe-5 pt-2 "
                                                // onClick={()=>{cancelImportFile([])}}
                                                onClick={()=>{
                                                    setDisplayBlock(false)
                                                    setProcessButton(true)
                                                }}
                                                >
                                                No</button>
                                        </div>
                                    </div>

                                </div>
                                <div className = "col-md-4">
                                    
                                </div>   
                                
                                <hr className = "mt-3"style={{border: "#0a4364 2px solid"}}></hr>  
                               
                            
                            </div> 

                            <div className = "row" style={{ width: "80%", backgroundColor:"white", height:"43vh",overflowY:"scroll"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                    <span className="p-4"> Total: {importResponseData && importResponseData?.total_count}</span>
                                    <span className="p-4"> New: {importResponseData && importResponseData?.new.length}</span>
                                    <span className="p-4"> Modified: {importResponseData && importResponseData?.modified.length}</span>
                                    <span className="p-4"> Existing but not modified:  {importResponseData && importResponseData?.matched.length}</span>
                                    <span className="p-4"> Invalid: {importResponseData && importResponseData?.error.length}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 p-2">
                                    {/* <TableContainer
                                       JsonArray={table}
                                        tBodyHeight="40vh"
                                        left={true}
                                        
                                        /> */}
                                       
                                        <div className="">
                                            <div className="container" style={{ height: "50vh" }}>
                                               
                                                <table
                                                className="spacingBetweenRows p-3 "
                                                 style={{ width: "inherit", minWidth: "600px" }}
                                                >
                                                <thead style={{ paddingLeft: "2%"}}> 
                                                    <tr >
                                                    <th className="text-start" style={{ width: "10%" }}>Row</th>
                                                    <th className="text-start" style={{ width: "30%" }}>Data</th>
                                                    <th className="text-start" style={{ width: "60%" }}>Error</th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    style={{ paddingRight: "0.5%", maxHeight: "50vh" }}
                                                >
                                                   {importResponseData?.error !== undefined ?(
                                                       importResponseData?.error.map((item,key)=> (
                                                        <tr className="skyBlue" key={key + "rows"}>
                                                             <td className="text-start"
                                                                style={{ width: "10%" }}>
                                                                 {item['row_no']}
                                                             </td>
                                                             <td className="text-start"
                                                                style={{ width: "30%" }}>
                                                                 {item['data']}
                                                             </td>
                                                             <td className="text-start"
                                                                style={{ width: "60%" }}>
                                                                 <ul>
                                                                     <li>{item["msg"]}</li>
                                                                 </ul>
                                                             </td>
                                                        </tr>
                                                       ))
                                                   ) : (
                                                       <></>
                                                   )}
                                                </tbody>
                    </table>
                    
                  </div>
                </div>
                                       
                                    </div>
                                </div>
                            </div>
                                
                            </>
                             :
                             <></>

                       }
                        
                            </div>
                </>
            
        </div>
    )
}

export default ImportPatient
