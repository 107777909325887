import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Api } from "../Api/ApiClient";
import { getTherapists } from "../Api/supervisorResponse";

import CenterOfScreen from "../Utils/CenterOfScreen";
import ContainerDiv from "../Utils/ContainerDiv";

import Dropdown from "../Utils/Dropdown";
import NavBarCont from "../Utils/NavBarCont";
import { setNavItems } from "../Utils/NavItems";
import Spinner from "../Utils/Spinner";
import TherapistMappingDiv from "../Utils/TherapistMappingDiv";
import _ from "underscore";
import { tr } from "date-fns/locale";

export interface getSupervisors {
  success: Success;
}
export interface Success {
  data?: DataEntity[] | null;
}
export interface DataEntity {
  supervisor_id: number;
  org_id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  supervisor_code: string;
  status: string;
  modified_by: string;
  modified_on: string;
}

interface tableData {
  organizationName?: string;
  orgId?: string;
  userId:number;
}
// interface SupervisorData{
//   organizationName:string
//   orgId:number
// }

interface TherapistData {
  therapistName?: string;
  therapistId?: string;
  checked?:boolean
}
interface UnassignedTherapistData {
  therapistName?: string;
  therapistId?: string;
  checked?:boolean
}
export interface UnassignedTherapistResponse {
  therapist_id: string;
  therapist_code: string;
  therapist_name: string;

  first_name: string;
  user_id: string;
}

const SuperAdminMapping = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dropdownLoading, setDropDownLoading] = useState<boolean>(true);
  const [dropdownValue, setDropdownValue] = useState<tableData[]>();
  // const [supervisorDropdownValue, setSupervisorDropdownValue] = useState<SupervisorData[]>()
  let [assignedTherapist, setAssignedTherapist] = useState<TherapistData[]>();
  var getUnassignedTherapistData: UnassignedTherapistResponse[];
  // var getSupervisorsData: getSupervisors;
  let [unassignedTherapist, setUnassignedTherapist] =
    useState<UnassignedTherapistData[]>();
  useEffect(() => {
    getSupervisorsList();
  }, []);
 
  var tableData: tableData[] = [];
  // var supervisorData: SupervisorData[] = [];
  var therapistData: TherapistData[] = [];
  var getTherapistBySupervisorData: getTherapists[] = [];
  var UnassignedTherapistList: UnassignedTherapistData[] = [];
  const [therapistId, setTherapistId] = useState<UnassignedTherapistData[]>([]);
  // var getUnassignedTherapistData:getSupervisors ;
  // var UnassignedTherapistList:UnassignedTherapistData[]=[];
  const [supervisorId, setSupervisorId] = useState<any>();
  const [therapistDataArr, setTherapistDataArr] = useState<TherapistData[]>([]);
  var arrMoveToAssigned: TherapistData[] = [];
  var arrMoveToUnassigned: UnassignedTherapistData[] = [];
  const [disableSubmit, setSubmitDisable] = useState<any>(true);

  const getSupervisorsList = async () => {
    try {
      const getSupervisorsListResponse: AxiosResponse =
        await Api.getSupervisorsList(
          encodeURIComponent(sessionStorage.getItem("token")!),
          sessionStorage.getItem("orgId")!.toString(),
          "supervisor"
        );
        setDropDownLoading((prev) => (prev = false));
     // alert( JSON.stringify(getSupervisorsListResponse.data.success.data))
      // console.log(
      //   "list==>" +
      //     JSON.stringify(getSupervisorsListResponse.data.success.data.tenants)
      // );
      const getSupervisorsListResponseData =
        getSupervisorsListResponse.data.success.data;
      for (let i = 0; i < getSupervisorsListResponseData.length; i++) {
        //for (let j = i; j <= i; j++) {
          tableData.push({
            organizationName:
              getSupervisorsListResponseData[i].first_name +
              " " +
              getSupervisorsListResponseData[i].last_name,
            orgId: getSupervisorsListResponseData[i].org_id,
            userId: getSupervisorsListResponseData[i].user_id,
          });
        //}
      }
      setDropdownValue(tableData);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  // const onDropdownValueChangeCheck = async(valueFromDropDown: string) => {
  //   alert("check"+ valueFromDropDown)
  //   const getSupervisorsResponse: AxiosResponse = await Api.getSupervisors(
  //     encodeURIComponent(sessionStorage.getItem("token")!.toString()),
  //     valueFromDropDown
  //   );
  //   getSupervisorsData = getSupervisorsResponse.data;
  //   console.log("Supervisor Data"+JSON.stringify(getSupervisorsData.success));
  //   if(getSupervisorsData.success !== undefined){
  //     for (let i = 0; i<getSupervisorsData.success!.data!.length;i++){
  //       for(let j = i;j <= i ; j++){
  //         supervisorData.push({
  //           organizationName: getSupervisorsData.success!.data![i].first_name + " "+ getSupervisorsData.success!.data![i].last_name ,
  //           orgId:getSupervisorsData.success!.data![i].supervisor_id
  //         })
  //       }
  //     }
  //   }
  //   console.log("sfsdsd"+ JSON.stringify(supervisorData));
  //   setSupervisorDropdownValue(supervisorData)
  //   const getUnassignedTherapistsResponse: AxiosResponse = await Api.getUnassignedTherapists(
  //     encodeURIComponent(sessionStorage.getItem("token")!.toString()),
  //     valueFromDropDown
  //   );
  //   getUnassignedTherapistData = getUnassignedTherapistsResponse.data
  //   if(getUnassignedTherapistData !== undefined){
  //     for (let i = 0; i<getSupervisorsData.success!.data!.length;i++){
  //       for(let j = i;j <= i ; j++){
  //         // UnassignedTherapistList.push({
  //         //   // therapistName: getUnassignedTherapistData!.success!.data![i].therapist_name

  //         // })
  //       }
  //     }
  //   }
  //   // setUnassignedTherapist(UnassignedTherapistList)
  // }

  const onSupervisorDropdownValueChangeCheck = async (
    valueFromDropDown: string
  ) => {
    // alert("sss no" + valueFromDropDown);
    setSupervisorId(JSON.parse(valueFromDropDown));
    const getTherapistBySupervisorResponse: AxiosResponse =
      await Api.getTherapistBySupervisorList(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        JSON.parse(valueFromDropDown).orgId,
        "therapist",
        JSON.parse(valueFromDropDown).userId,
        "true"
      );
    getTherapistBySupervisorData = getTherapistBySupervisorResponse.data.data;
    console.log("th-su" + JSON.stringify(getTherapistBySupervisorData));
    if (getTherapistBySupervisorData !== undefined) {
      for (let i = 0; i < getTherapistBySupervisorData.length; i++) {
        
          therapistData.push({
            therapistName: getTherapistBySupervisorData[i].therapist_name,
            therapistId:
              getTherapistBySupervisorData[i].user_id.toString(),
              checked: undefined
          });
        
      }
    }
    console.log("therapist" + JSON.stringify(therapistData));
    setAssignedTherapist(therapistData);
    const getUnassignedTherapistsResponse: AxiosResponse =
      await Api.getUnassignedTherapistsList(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("orgId")!,
        "therapist",
        "false",
        JSON.parse(valueFromDropDown).userId
      );
    getUnassignedTherapistData = getUnassignedTherapistsResponse.data.success.data;
    // console.log("unassignvalue===="+JSON.stringify(getUnassignedTherapistsResponse));

    if (getUnassignedTherapistData !== undefined) {
      for (let i = 0; i < getUnassignedTherapistData!.length; i++) {
         
          UnassignedTherapistList.push({
            therapistName: getUnassignedTherapistData![i].first_name,
            therapistId: getUnassignedTherapistData![i].user_id,
            checked: undefined
          });
        
      }
    }
    // alert("checkfffff" + UnassignedTherapistList);

    setUnassignedTherapist(UnassignedTherapistList);
    setLoading((prev) => (prev = false));
  };

  const UnassigedToAssignedTherapist = (checkBoxValue: string, id: string,checked:boolean) => {
    // alert("gggg" + checkBoxValue +"id"+id);
    // arrMoveToAssigned.push({
    //   therapistName: checkBoxValue,
    // });
    // console.log("un" + arrMoveToAssigned);
    if(checked == true){
      setTherapistDataArr(
        (prevData) =>
          (prevData = [
            ...therapistDataArr,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
      setTherapistId(
        (prevData) =>
          (prevData = [
            ...therapistId,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
    }
    else{
      for(let i =0 ; i < therapistDataArr.length; i++){
        var check: UnassignedTherapistData[] = therapistDataArr!.filter(
          (item) => item.therapistName !== checkBoxValue
        );
        setTherapistDataArr((prevData) => (prevData = check));
         
      }
    }
    //alert("therapistDataArr===>"+JSON.stringify(therapistDataArr))
    // setAssignedTherapist((prevData) => (prevData = therapistData));
  };

  const assigedToUnassignedTherapist = (checkBoxValue: string, id: string,checked:boolean) => {
     // alert("hvghgvgh" + checked + "ggggg:== " + id);
    // arrMoveToUnassigned.push({
    //   therapistName: checkBoxValue,
    //   therapistId: id,
    // });
    // console.log("msattttt" + JSON.stringify(arrMoveToUnassigned));
    // const check = arrMoveToUnassigned;
    // arrMoveToUnassigned=[...]
    // setAssignedTherapist((prevData )=>(prevData=therapistData))
    if(checked == true){
      setTherapistId(
        (prevData) =>
          (prevData = [
            ...therapistId,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
      setTherapistDataArr(
        (prevData) =>
          (prevData = [
            ...therapistDataArr,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
    }
    else{
      for(let i=0; i < therapistId.length; i++){
        var check: TherapistData[] = therapistId!.filter(
          (item) => item.therapistName !== checkBoxValue
        );
        setTherapistId((prevData) => (prevData = check));
        
      }
    }
    alert("aaaaa==>"+checkBoxValue)
    // setUnassignedTherapist(therapistId);
  };
  const unassignNewTherapist = () => {
    //setUnassignedTherapist((prevData) => (prevData = arrMoveToUnassigned));
    if(unassignedTherapist?.length != 0){
      
      setUnassignedTherapist((prevData) => (prevData?.concat(therapistId))); 
      for(let i =0 ; i < therapistId.length; i++){
        var check: TherapistData[] = assignedTherapist!.filter(
          (item) => item.therapistName !== therapistId[i].therapistName
        );
        setAssignedTherapist((prevData) => (prevData = check));
        assignedTherapist = check;
        setSubmitDisable(false);
      }
      
      setTherapistId(
        (prevData) =>
          (prevData = [ 
          ])
      );
    } 
  };

  const assignNewTherapist = () => {
    if(assignedTherapist?.length != 0){
      setAssignedTherapist((prevData) => (prevData?.concat(therapistDataArr))); 
      for(let i =0 ; i < therapistDataArr.length; i++){
        var check: UnassignedTherapistData[] = unassignedTherapist!.filter(
          (item) => item.therapistName !== therapistDataArr[i].therapistName
        );
        setUnassignedTherapist((prevData) => (prevData = check));
        unassignedTherapist = check;
        setSubmitDisable(false);
      }
     
      setTherapistDataArr(
        (prevData) =>
          (prevData = [ 
          ])
      );
    }  
  };
  return (
    <div>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>

      {/* <CenterOfScreen> */}
        <div style={{ width: "100%", paddingTop: "8%", paddingLeft:"1%" }}>
          <>
            <div className="mb-5">
              <ContainerDiv widthInPercentage="100%" height="10vh">
              {dropdownLoading != true ? (<> <div className="text-start p-3  bg-light rounded">
                  <div className="pb-3">
                    <span className="fs-5">Supervisor : </span>
                    <span className="pe-5">
                      <Dropdown
                        dropdownList={dropdownValue}
                        onChangeDropDownHandler={(dropDownValue: string) => {
                          // alert("dropDownValue"+dropDownValue)
                          onSupervisorDropdownValueChangeCheck!(dropDownValue);
                        }}
                      />
                    </span>
                  </div>
                </div>
                </>
              ) : (
                     <Spinner />
                   )}
              </ContainerDiv>
            </div>
            <div className="">
            {loading != true && assignedTherapist?.length != 0? (<>
              <TherapistMappingDiv
                checkBoxList={assignedTherapist}
                unassignedList={unassignedTherapist}
                disableSubmit={disableSubmit}
                // mappingAssignValue={arrMoveToAssigned}
                // mappingUnassignValue={arrMoveToUnassigned}
                onUnassignedCheckBoxSelected={(
                  checkBoxValue: string,
                  id: string,
                  checked:boolean
                ) => {
                  // alert("CheckBox selected==>1 "+checkBoxValue+"  id"+id )
                  UnassigedToAssignedTherapist!(checkBoxValue, id,checked);
                }}
                onAssignedCheckBoxSelected={(
                  checkBoxValue: string,
                  id: string,
                  checked:boolean
                ) => {
                  // alert("CheckBox selected ==2 "+checkBoxValue+"  id"+id )
                  assigedToUnassignedTherapist!(checkBoxValue, id,checked);
                }}
                onUnassignedButtonClick={(e: any) => {
                  unassignNewTherapist!();
                }}
                supervisorDropdownValueChangeCheck= {(supervisorId : string)=>{
                  onSupervisorDropdownValueChangeCheck(supervisorId)
                }}
                onSubmitSupervisorValue={supervisorId}
                onSubmitTherapistValue={therapistId}
                onAssignedButtonClick={(e: any) => {
                  assignNewTherapist!();
                }}
              />
              </>
              ) : (
                     <Spinner />
                   )}
            </div>
          </>
        </div>
      {/* </CenterOfScreen> */}
    </div>
  );
};

export default SuperAdminMapping;
