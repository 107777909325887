import React, { useEffect, useRef } from "react";
import CenterOfScreen from "../Utils/CenterOfScreen";
import ContainerDiv from "../Utils/ContainerDiv";
import CustomButton from "../Utils/CustomButton";
import Gradient from "../Utils/Gradient";
import logo from "../Assets/Clarity AI Logo.png";
import alert from "../Assets/Alert symbol.svg";
import loginIcon from "../Assets/login-teal.svg";
// import { SPAdminNavItems, SuperAdminNavItems, SupervisorNavItems, TherapistNavItems, UtilizationNavItems, VoicelablingNavItems } from "../Utils/NavItems";
import "../ComponentCSS/Login.css";
import sha1 from "sha1";
import mailIcon from "../Assets/mail-teal.svg";
import userIcon from "../Assets/user-teal.svg";
import lockIcon from "../Assets/lock-teal.svg";
import { Api } from "../Api/ApiClient";
import { AxiosResponse } from "axios";
import { AccessToken, LoginBody } from "../Api/ApiResponseDataTypes";
import { postLoginResponse } from "../Api/LoginResponse";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Property } from "csstype";
import Spinner from "../Utils/Spinner";
import { toast } from "react-toastify";
import ForgotPassword from "./ForgotPassword";
/**
 * Represents the Login screen
 * Has children Gradient,CenterOfScreen,ContainerDiv
 * @param LoginProps
 * @returns Login Screen
 */
export const Login: React.FC = () => {
  const groupIdRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const emailRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const passwordRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  // const [count, setCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //      setCount(1);
  //    }, 1000);
  //  },[count]);
  const [forgotPasswordDiv, setForgotPasswordDiv] = useState(false);
  const [visibility, setVisibility] = useState<Property.Visibility>("hidden");
  const history = useHistory();
  const [disableLoginBtn, setLoginBtnDisable] = useState(true);

  //const location = useLocation();

  const notify = (message: string) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2500,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const validation = (groupId: string, email: string, password: string) => {
    if (email.length === 0 && password.length === 0) {
      console.log("field empty");
      notify("Empty fields");
      return false;
    } else {
      if (validateEmail(email) === true) {
        setLoading((prev) => (prev = true));
        return true;
      } else {
        notify("Wrong email address");
        console.log("wrong email");
        setVisibility("visible");

        return false;
      }
    }
  };
  const checkValidation = async () => {
    if (
      groupIdRef.current!.value.trim().toString() !== "" &&
      emailRef.current!.value.trim().toString() !== "" &&
      passwordRef.current!.value.trim().toString() !== ""
    ) {
      setLoginBtnDisable(false);
    } else {
      setLoginBtnDisable(true);
    }
  };

  const onClickHandler = async () => {
    // setLoading((prev) => (prev = true));

    if (
      validation(
        groupIdRef.current!.value.trim().toString(),
        emailRef.current!.value.trim().toString(),
        passwordRef.current!.value.trim().toString()
      )
    ) {
      try {
        const tokenHolderObj: AxiosResponse = await Api.getAccessToken(
          emailRef.current!.value.trim().toString()
        );

        const tokenObj: AccessToken = tokenHolderObj.data;

        console.log("TOKEN" + JSON.stringify(tokenObj));

        if (tokenHolderObj) {
          if (tokenObj.success !== undefined) {
            const encryptPassword = sha1(passwordRef.current!.value.toString());

            const encryptTimeStampPassword = sha1(
              encryptPassword + tokenObj.success!.data.accessTS.toString()
            );

            const responseBodyLogin: LoginBody = {
              IMEI: "xxx-xxxx-xxxx-xxxx-xxxx",
              accessToken: tokenObj.success.data.accessToken.toString(),
              appVersion: "xx",
              model: "web",
              therapistId: groupIdRef.current!.value.trim().toString(),
              osVersion: "xx.xx.xx",
              phoneMake: "web",
              pwd: encryptTimeStampPassword,
              userId: emailRef.current!.value.trim(),
              location: "xx.xx",
            };

            const loginResponseObjHolder: AxiosResponse =
              await Api.postLoginCall(responseBodyLogin);

            setVisibility((prev) => (prev = "hidden"));
            const loginResponse: postLoginResponse =
              loginResponseObjHolder.data;

            if (loginResponse.success !== undefined) {
              sessionStorage.setItem("token", loginResponse.success.data.token);
              sessionStorage.setItem("Role", loginResponse.success.data.role);
              sessionStorage.setItem(
                "userName",
                loginResponse.success.data.firstName +
                  " " +
                  loginResponse.success.data.lastName
              );
              switch (loginResponse.success.data.role) {
                case "Supervisor":
                  sessionStorage.setItem(
                    "supervisorCode",
                    loginResponse.success.data.userMeta.supervisorId
                  );
                  sessionStorage.setItem(
                    "userId",
                    loginResponse.success.data.userId
                  );
                  sessionStorage.setItem(
                    "supervisorId",
                    loginResponse.success.data.userMeta.user_id.toString()
                  );
                  sessionStorage.setItem(
                    "orgId",
                    loginResponse.success.data.userMeta.orgId
                  );
                  sessionStorage.setItem(
                    "TenantId",
                    loginResponse.success.data.tenantId.toString()
                  );
                  sessionStorage.setItem(
                    "domain",
                    loginResponse.success.data.tenantName
                  );
                  history.push("/supervisorDashboard");
                  break;
                case "Therapist":
                  if (
                    responseBodyLogin.therapistId ===
                    loginResponse.success.data.userMeta.therapistId
                  ) {
                    sessionStorage.setItem(
                      "userId",
                      loginResponse.success.data.userId
                    );
                    sessionStorage.setItem("isVoiceLabeling", "0");
                    sessionStorage.setItem("domain", "Movinture");
                    sessionStorage.setItem("Role", "Therapist");
                    sessionStorage.setItem("loggedIn", "1");
                    sessionStorage.setItem("isAdmin", "0");
                    sessionStorage.setItem("isSupervisor", "0");
                    sessionStorage.setItem("isUM", "0");
                    // sessionStorage.setItem(
                    //   "tid",
                    //   loginResponse.success.data.userMeta.user_sys_id!.toString()
                    // );
                    sessionStorage.setItem(
                      "userName",
                      loginResponse.success.data.firstName +
                        " " +
                        loginResponse.success.data.lastName
                    );
                    if (
                      loginResponse.success.data.userMeta.user_id === undefined
                    ) {
                      sessionStorage.setItem(
                        "therapistID",
                        loginResponse.success.data.userMeta.therapistId.toString()
                      );
                    } else {
                      sessionStorage.setItem(
                        "therapistID",
                        loginResponse.success.data.userMeta.user_id.toString()
                      );
                    }
                    sessionStorage.setItem(
                      "therapist_code",
                      loginResponse.success.data.userMeta.therapistId.toString()
                    );
                    history.push("/TherapistDashboard");
                  } else {
                    console.log("Incorrect therapist ID");
                  }
                  break;
                case "SP Admin":
                  // sessionStorage.setItem(
                  //   "TenantId",
                  //   loginResponse.success.data.tenantId.toString()
                  // );
                  console.log(
                    "LoginResponse" + JSON.stringify(loginResponse.success.data)
                  );
                  history.push("/OrganizationDashboard");
                  break;
                case "Utilization Manager":
                  sessionStorage.setItem(
                    "userId",
                    loginResponse.success.data.userId
                  );
                  sessionStorage.setItem("isVoiceLabeling", "0");
                  sessionStorage.setItem("Role", "Utilization Manager");
                  sessionStorage.setItem("loggedIn", "1");
                  sessionStorage.setItem("isAdmin", "0");
                  sessionStorage.setItem("isSupervisor", "0");
                  sessionStorage.setItem("isUM", "1");
                  sessionStorage.setItem("domain", "Movinture");
                  sessionStorage.setItem(
                    "UMID",
                    loginResponse.success.data.userMeta.UMId.toString()
                  );
                  sessionStorage.setItem(
                    "userId",
                    loginResponse.success.data.userMeta.user_id.toString()
                  );
                  sessionStorage.setItem(
                    "PayerOrgName",
                    loginResponse.success.data.tenantName
                  );
                  history.push("/UMDashboard");
                  break;
                case "Admin":
                  sessionStorage.setItem(
                    "userId",
                    loginResponse.success.data.userId
                  );
                  sessionStorage.setItem("isVoiceLabeling", "0");
                  sessionStorage.setItem("Role", "Admin");
                  sessionStorage.setItem("userProfile", "1");
                  sessionStorage.setItem("loggedIn", "1");
                  sessionStorage.setItem("isAdmin", "0");
                  sessionStorage.setItem("isSuperAdmin", "1");
                  sessionStorage.setItem("isUM", "0");
                  sessionStorage.setItem(
                    "isUserType",
                    loginResponse.success.data.userType
                  );
                  sessionStorage.setItem("domain", "Movinture");
                  sessionStorage.setItem("canCreateUser", "0");
                  sessionStorage.setItem(
                    "userName",
                    loginResponse.success.data.firstName +
                      " " +
                      loginResponse.success.data.lastName
                  );
                  sessionStorage.setItem(
                    "orgId",
                    loginResponse.success.data.userMeta.orgId
                  );
                  sessionStorage.setItem("expires_at", "");
                  sessionStorage.setItem(
                    "superAdminId",
                    loginResponse.success.data.userMeta.superAdminId
                  );
                  sessionStorage.setItem(
                    "TenantId",
                    loginResponse.success.data.tenantId.toString()
                  );
                  history.push("/SuperAdminUser");
                  break;
                case "Voice Labeling":
                  sessionStorage.setItem(
                    "userId",
                    loginResponse.success.data.userId
                  );
                  sessionStorage.setItem("isVoiceLabeling", "1");
                  sessionStorage.setItem("loggedIn", "1");
                  sessionStorage.setItem("isAdmin", "0");
                  sessionStorage.setItem("isSuperAdmin", "0");
                  sessionStorage.setItem("isUM", "0");
                  sessionStorage.setItem(
                    "TenantId",
                    loginResponse.success.data.tenantId.toString()
                  );
                  sessionStorage.setItem(
                    "orgId",
                    loginResponse.success.data.userMeta.orgId
                  );
                  sessionStorage.setItem(
                    "voiceLabelingId",
                    loginResponse.success.data.userMeta.voiceLabelingId.toString()
                  );
                  history.push("/PatientSearchVL");
                  break;
                default:
                  setVisibility((prev) => (prev = "visible"));

                  break;
              }
              setLoading((prev) => (prev = false));
            } else {
              setLoading((prev) => (prev = false));
              setVisibility("visible");
            }
          } else {
            notify(tokenObj.error!.msg);
            setLoading((prev) => (prev = false));
            setVisibility("visible");
          }
        }
        // setVisibility((prev) => (prev = "hidden"));
      } catch (error) {
        console.log(JSON.stringify(error));
        setLoading((prev) => (prev = false));
      }
      // setVisibility((prev) => (prev = "hidden"));
    }
  };
  return (
    <Gradient>
      <CenterOfScreen>
        <ContainerDiv widthInPercentage="453px" height="60vh">
          <>
            {forgotPasswordDiv ? (
              <ForgotPassword display={setForgotPasswordDiv} />
            ) : (
              <></>
            )}
            <div
              className="container p-2 rounded"
              style={{ background: "#fff" }}
            >
              <div className="d-flex flex-column bd-highlight mb-3 align-items-center">
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    width: "45%",
                    // height: "6.1vh"
                  }}
                  className="pt-1"
                />
                <div
                  className="pt-1 "
                  //  id = "hideThis"
                  style={{ color: "#FFB400", visibility: visibility }}
                  //  onLoadedData={() => setCount(count + 1)}
                >
                  <img className="pb-1" src={alert} alt="invalid"></img>
                  <span className="ps-1">Invalid login information</span>
                </div>
                <div className="ps-4 pe-4" style={{ width: "100%" }}>
                  <div
                    className="input-group mb-3 ps-2 pe-2"
                    style={{ height: "5vh" }}
                  >
                    <div className="input-group-prepend">
                      <span className=" border-0">
                        <img
                          src={userIcon}
                          alt="logo label"
                          style={{ width: "50%", height: "50px" }}
                        />
                      </span>
                    </div>
                    <input
                      ref={groupIdRef}
                      type="text"
                      className="text-start form-control border-top-0 border-start-0 border-end-0  pl- pr-1  border_color_skyBlue border-3 inputPlaceholder"
                      placeholder="group ID"
                      onChange={(e) => {
                        checkValidation();
                        setVisibility((prev) => (prev = "hidden"));
                      }}
                    />
                  </div>
                  <div
                    className="input-group mb-3 pt-1 ps-2 pe-2"
                    style={{ height: "5vh" }}
                  >
                    <div className="input-group-prepend">
                      <span className=" border-0">
                        <img
                          src={mailIcon}
                          alt="logo label"
                          style={{ width: "50%", height: "50px" }}
                        />
                      </span>
                    </div>
                    <input
                      ref={emailRef}
                      type="text"
                      className="text-start form-control border-top-0 border-start-0 border-end-0  pl- pr-1  border_color_skyBlue border-3 inputPlaceholder"
                      placeholder="email address"
                      onChange={(e) => {
                        checkValidation();
                        setVisibility((prev) => (prev = "hidden"));
                      }}
                    />
                  </div>
                  <div
                    className="input-group mb-3 pt-2 ps-2 pe-2"
                    style={{ height: "5vh" }}
                  >
                    <div className="input-group-prepend">
                      <span className=" border-0">
                        <img
                          src={lockIcon}
                          alt="logo label"
                          style={{ width: "50%", height: "50px" }}
                        />
                      </span>
                    </div>
                    <input
                      ref={passwordRef}
                      type="password"
                      className="text-start form-control border-top-0 border-start-0 border-end-0  pl- pr-1  border_color_skyBlue border-3 inputPlaceholder"
                      placeholder="password"
                      onChange={(e) => {
                        checkValidation();
                        setVisibility((prev) => (prev = "hidden"));
                      }}
                    />
                  </div>
                  <div
                    className="text-start ps-5 pt-4"
                    style={{ width: "100%" }}
                  >
                    <a
                      // href="/ForgotPassword"
                      style={{ fontSize: 12, cursor: "pointer" }}
                      className="text_color_SkyBlue fw-normal"
                      onClick={() => {
                        setForgotPasswordDiv((prev) => (prev = true));
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div className="pt-5  ">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      buttonName="Log In"
                      disabled={disableLoginBtn}
                      logo={loginIcon}
                      OnClickHandler={onClickHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        </ContainerDiv>
      </CenterOfScreen>
    </Gradient>
  );
};

export const validateEmail = (emailAddress: string) => {
  if (emailAddress !== undefined) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAddress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
