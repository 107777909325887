// import React from "react";
// import Highcharts from "highcharts";
// import highchartsMore from "highcharts/highcharts-more";
// import ContainerDiv from "./ContainerDiv";
// highchartsMore(Highcharts);

// class BubbleChart extends React.Component {
//   constructor(props) {
//     super(props);
//     //   this.valuePer = props.value;
//     this.state = {
//       series: [
//         {
//           data: props.value.therapistArr,
//           color: "#2EB5B9",
//           name: "Therapist",
//         },
//         {
//           data: props.value.caregiverArr,
//           color: "#71D016",
//           name: "Caregiver",
//         },
//         {
//           data: props.value.patientArr,
//           color: "#1C4261",
//           name: "Patient",
//         },
//       ],
//     };
//   }

//   highChartsRender() {
//     var mindate = new Date();
//     // var year = new Date().getFullYear().toString();
//     // mindate.setDate(mindate.getDate() - 180);
//     // year = mindate.getFullYear() + " - " + year;

//     // Highcharts.chart({
//     //   chart: {
//     //     type: "bubble",
//     //     renderTo: "bubbleChart",
//     //     plotBorderWidth: 1,
//     //           zoomType: "xy"
//     //   },
//     // title: {
//     //   text: "",
//     //   align: "left",
//     // },
//     //   xAxis: [
//     //     {
//     //       type: "datetime",
//     //       gridLineWidth: 1,
//     //       tickInterval: 1000 * 3600 * 24 * 30,
//     //       // minRange: 30,
//     //       min: mindate.getTime(),
//     //       max: new Date().getTime(),
//     //       // min: 1584698519042,
//     //       // max: 1600250663574,
//     //       // min: 0,
//     //       // max: 180,
//     //       startOnTick: true,
//     //       endOnTick: true,
//     //       alignTicks: true,
//     //       class: "graphText",

//     //       title: {
//     //         text: "Date",
//     //         align: "low",
//     //        // y: -60,
//     // x: -65
//     //       },
//     //       labels: {
//     //         format: "{value:%m/%d/%Y}",
//     //       },
//     //     },
//     //   ],

//     //   yAxis: {
//     //     startOnTick: true,
//     //     endOnTick: true,
//     //     tickInterval: 6,
//     //     min: 60,
//     //     title: {
//     //       text: "Scores",
//     //     },
//     //     labels: {
//     //       format: "{value}",
//     //     },
//     //    maxPadding: 0.2,
//     //   },
//     //   tooltip: {
//     //     useHTML: true,
//     //     headerFormat: "<table>",
//     //     pointFormat:
//     //       '<tr><th colspan="2"><h3>{point.fullName}</h3></th></tr>' +
//     //       "<tr><th>{point.scoreType} Score: {point.y}</th></tr>" +
//     //       "<tr><th>Duration: {point.x} </th></tr>",
//     //     footerFormat: "</table>",
//     //     followPointer: true,
//     //   },

//     //   plotOptions: {
//     // series: {
//     //   dataLabels: {
//     //     enabled: true,
//     //     format: "{point.shortName}",
//     //     color: "#000000",
//     //     style: {
//     //       textOutline: false,
//     //     },
//     //   },
//     //   shadow: false,
//     // },
//     //   },
//     //   series: this.state.series,
//     //   noData: {
//     //     style: {
//     //       fontWeight: "bold",
//     //       fontSize: "15px",
//     //       color: "#303030",
//     //     },
//     //   }
//     // });

//     Highcharts.chart("bubbleChart", {
//       chart: {
//         type: "bubble",
//         plotBorderWidth: 1,
//         zoomType: "xy",
//       },

//       legend: {
//         enabled: true,
//       },

//       title: {
//         text: "",
//         align: "left",
//       },

//       // accessibility: {
//       //     point: {
//       //         valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.'
//       //     }
//       // },

//       xAxis: {
//         gridLineWidth: 1,
//         title: {
//           text: "Date",
//           // align: 'low'
//         },
//         min: mindate.getTime(),
//         max: new Date().getTime(),
//         tickInterval: 1000 * 3600 * 24 * 30,
//         labels: {
//           format: "{value:%m/%d/%Y}",
//         },
//         // plotLines: [{
//         //     color: 'black',
//         //     dashStyle: 'dot',
//         //     width: 2,
//         //     value: 65,
//         //     label: {
//         //         rotation: 0,
//         //         y: 15,
//         //         style: {
//         //             fontStyle: 'italic'
//         //         },
//         //         text: 'Safe fat intake 65g/day'
//         //     },
//         //     zIndex: 3
//         // }],
//         // accessibility: {
//         //     rangeDescription: 'Range: 60 to 100 grams.'
//         // }
//       },

//       yAxis: {
//         startOnTick: true,
//         endOnTick: true,
//         tickInterval: 6,
//         min: 60,
//         title: {
//           text: "Scores",
//         },
//         labels: {
//           format: "{value}",
//         },
//         maxPadding: 0.2,
//         // plotLines: [{
//         //     color: 'black',
//         //     dashStyle: 'dot',
//         //     width: 2,
//         //     value: 50,
//         //     label: {
//         //         align: 'right',
//         //         style: {
//         //             fontStyle: 'italic'
//         //         },
//         //         text: 'Safe sugar intake 50g/day',
//         //         x: -10
//         //     },
//         //     zIndex: 3
//         // }],
//         // accessibility: {
//         //     rangeDescription: 'Range: 0 to 160 grams.'
//         // }
//       },

//       tooltip: {
//         useHTML: true,
//         headerFormat: "<table>",
//         pointFormat:
//           '<tr><th colspan="2"><h3>{point.fullName}</h3></th></tr>' +
//           "<tr><th>{point.scoreType} Score: {point.y}</th></tr>" +
//           "<tr><th>Duration: {point.x} </th></tr>",
//         footerFormat: "</table>",
//         followPointer: true,
//       },

//       // tooltip: {
//       //     useHTML: true,
//       //     headerFormat: '<table>',
//       //     pointFormat: '<tr><th colspan="2"><h3>{point.country}</h3></th></tr>' +
//       //         '<tr><th>Fat intake:</th><td>{point.x}g</td></tr>' +
//       //         '<tr><th>Sugar intake:</th><td>{point.y}g</td></tr>' +
//       //         '<tr><th>Obesity (adults):</th><td>{point.z}%</td></tr>',
//       //     footerFormat: '</table>',
//       //     followPointer: true
//       // },

//       plotOptions: {
//         series: {
//           dataLabels: {
//             enabled: true,
//             format: "{point.shortName}",
//             color: "#000000",
//             style: {
//               textOutline: false,
//             },
//           },
//           shadow: false,
//         },
//       },

//       series: this.state.series,
//     });
//   }

//   componentDidMount() {
//     this.highChartsRender();
//   }

//   render() {
//     return (
//       <ContainerDiv widthInPercentage="100%" height="20vh">
//         <div className="row">
//           <div
//             id="bubbleChart"
//             className="bg-transparent"
//             style={{ width: "500px", height: "200px" }}
//           ></div>
//         </div>
//       </ContainerDiv>
//     );
//   }
// }

// export default BubbleChart; 
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import { useEffect } from "react";
import ContainerDiv from "./ContainerDiv"; 

highchartsMore(Highcharts);

const BubbleChart = (props) => {

  var series = [
    {
      data: props.value.therapistArr,
      color: "#2EB5B9",
      name: "Therapist",
    },
    {
      data: props.value.caregiverArr,
      color: "#71D016",
      name: "Caregiver",
    },
    {
      data: props.value.patientArr,
      color: "#1C4261",
      name: "Patient",
    },
  ];

  const highChartsRender = () => {
    var mindate = new Date();
    // var year = new Date().getFullYear().toString();
    mindate.setDate(mindate.getDate() - 180);
    // year = mindate.getFullYear() + " - " + year;

    Highcharts.chart({
      chart: {
        type: "bubble",
        renderTo: "bubbleChart",
        plotBorderWidth: 2,
        allowChartUpdate: true, 
        zoomType: "xy",
      },
      title: {
        text: "",
        align: "left",
      },
      xAxis: [
        {
          type: "datetime",
          gridLineWidth: 1,
          tickInterval: 1000 * 3600 * 24 * 30,
          // minRange: 30,
          min: mindate.getTime(),
          max: new Date().getTime(),
          // min: 1584698519042,
          // max: 1600250663574,
          // min: 0,
          // max: 180,
          startOnTick: true,
          endOnTick: true,
          alignTicks: true,
          class: "graphText",

          title: {
            text: "Date",
            align: "low",
            y: -15,
            x: -65,
          },
          labels: {
            rotation: -45,
            format: "{value:%m-%d}",
          },
        },
      ],

      yAxis: {
        startOnTick: true,
        endOnTick: true,
        tickInterval: 5,
        min: 60,
        title: {
          text: "Scores",
        },
        labels: {
          format: "{value}",
        },
        maxPadding: 0.2,
      },
      // tooltip: {
      //   useHTML: true,
      //   headerFormat: "",
      //   className: "bubble-tooltip",
      //     // pointFormat:
      //     // "<h4>{point.fullName}<h4><h6>{point.scoreType} Score:<span>{point.y}</span></h6><h6>Duration:<span>{point.x:}</span></h6>",
      // formatter: function () {
      //   return (
      //     // "<h4>{this.series.fullName}<h4><h6>{this.series.scoreType} Score:<span>{this.series.y}</span></h6><h6>Duration:<span>{this.series.x:}</span></h6>"
      //     '<b>' + this.fullName + '</b><br/>' +
      //     Highcharts.dateFormat('%e - %b - %Y',
      //       new Date(this.x))
      //     + ' date, ' + this.y + ' Kg.'
      //   )


      //   },
      //   followPointer: true
      // },
      tooltip: {
        useHTML: true,
        headerFormat: "",
        className: "bubble-tooltip",
        //  pointFormat:
        //    "<tr><td>{point.scoreType} Score: {point.y}</td></tr>" +
        //    "<tr><td>Duration: {point.x} </td></tr>",
        //  footerFormat: "</table>",
        // pointFormat:
        //   `<h4>{point.fullName}<h4><h6>{point.scoreType} Score:<span>{point.y}</span></h6><h6>Duration:<span>{${moment.utc('point.x').tz("America/New_York")}}</span></h6>`,
        pointFormat:
          "<h4>{point.fullName}<h4><h6>{point.scoreType} Score:<span>{point.y}</span></h6><h6>Duration:<span>{point.x}</span></h6>",

        followPointer: true,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "{point.shortName}",
            color: "blue",
            style: {
              textOutline: false,
            },
          },
          shadow: false,
        },
      },
      series: series,
      noData: {
        style: {
          fontWeight: "bold",
          fontSize: "15px",
          color: "#303030",
        },
      },
    });
  };
  useEffect(() => {
    highChartsRender();
  });

  return (
    <ContainerDiv widthInPercentage="100%" height="25vh">
      <div className="row">
        <div
          id="bubbleChart"
          className="bg-transparent"
          style={{ width: "600px", height: "245px" }}
        ></div>
      </div>
    </ContainerDiv>
  );
};
// class BubbleChart extends React.Component {
//   constructor(props) {
//     super(props);
//     //   this.valuePer = props.value;
//     this.state = {
//       series: [
//         {
//           data: props.value.therapistArr,
//           color: "#2EB5B9",
//           name: "Therapist",
//         },
//         {
//           data: props.value.caregiverArr,
//           color: "#71D016",
//           name: "Caregiver",
//         },
//         {
//           data: props.value.patientArr,
//           color: "#1C4261",
//           name: "Patient",
//         },
//       ],
//     };
//   }

// highChartsRender() {
//   var mindate = new Date();
//   // var year = new Date().getFullYear().toString();
//   mindate.setDate(mindate.getDate() - 180);
//   // year = mindate.getFullYear() + " - " + year;

//   Highcharts.chart({
//     chart: {
//       type: "bubble",
//       renderTo: "bubbleChart",

//       plotBorderWidth: 2,

//       plotBorderWidth: 1,

//       zoomType: "xy"
//     },
//     title: {
//       text: "",
//       align: "left",
//     },
//     xAxis: [
//       {
//         type: "datetime",
//         gridLineWidth: 1,
//         tickInterval: 1000 * 3600 * 24 * 30,
//         // minRange: 30,
//         min: mindate.getTime(),
//         max: new Date().getTime(),
//         // min: 1584698519042,
//         // max: 1600250663574,
//         // min: 0,
//         // max: 180,
//         startOnTick: true,
//         endOnTick: true,
//         alignTicks: true,
//         class: "graphText",

//         title: {
//           text: "Date",
//           align: "low",
//           y: -15,
//           x: -65
//         },
//         labels: {
//           format: "{value:%m-%d}",
//         },
//       },
//     ],

//     yAxis: {
//       startOnTick: true,
//       endOnTick: true,
//       tickInterval: 5,
//       min: 60,
//       title: {
//         text: "Scores",
//       },
//       labels: {
//         format: "{value}",
//       },
//       maxPadding: 0.2,
//     },
//     tooltip: {
//       useHTML: true,
//       headerFormat: "",
//       className:"bubble-tooltip",
//       //  pointFormat:
//       //    "<tr><td>{point.scoreType} Score: {point.y}</td></tr>" +
//       //    "<tr><td>Duration: {point.x} </td></tr>",
//       //  footerFormat: "</table>",
//       pointFormat: "<h4>{point.fullName}<h4><h6>{point.scoreType} Score:<span>{point.y}</span></h6><h6>Duration:<span>{point.x}</span></h6>",
//       followPointer: true,
//     },

//     plotOptions: {
//       series: {
//         dataLabels: {
//           enabled: true,
//           format: "{point.shortName}",
//           color: "blue",
//           style: {
//             textOutline: false,
//           },
//         },
//         shadow: false,
//       },
//     },
//     series: this.state.series,
//     noData: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "15px",
//         color: "#303030",
//       },
//     }
//   });
// }

//   // componentDidMount() {
//   //   this.highChartsRender();
//   // }

//   render() {
// return (
//   <ContainerDiv widthInPercentage="100%" height="25vh">
//     <div className="row">
//       <div
//         id="bubbleChart"
//         className="bg-transparent"
//         //style={{ width: "600px", height: "300px" }}
//       ></div>
//     </div>
//   </ContainerDiv>
// );
//   }
// }

export default BubbleChart;
