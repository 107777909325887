import React from "react";

interface ContainerDivProps {
  children: JSX.Element;
  widthInPercentage?: string;
  height: string;
}
/**
 * Aligns all the content in the center has white background.
 * And has rounded corners.
 * @param ContainerDivProps Width in percentage
 * @returns JSX elements
 */
const ContainerDiv: React.FC<ContainerDivProps> = (ContainerDivProps) => {
  return (
    <div
      className="container text-center bg-white  bg-transparent "
      style={{
        width: `${ContainerDivProps.widthInPercentage}`,
        paddingLeft: "2%",
        paddingRight: "2%",
        paddingTop: "0.4%",
        paddingBottom: "1%",
        height: `${ContainerDivProps.height}`,
      }}
    >
      {ContainerDivProps.children}
    </div>
  );
};

export default ContainerDiv;
