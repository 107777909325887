import React from "react"; 
interface CenterOfScreenProps {
  children: JSX.Element;
}

/**
 * Aligns "div" in the center of the screen horizontally as well as vertically
 * @param CenterOfScreenProps
 * @returns JSX elements
 */
const CenterOfScreen: React.FC<CenterOfScreenProps> = (CenterOfScreenProps) => {
  return (
    // <div className="jumbotron d-block align-items-center min-vh-100">
    //     {CenterOfScreenProps.children}
    // </div>

    // <div className="d-flex align-items-center justify-content-center">

    // </div>
    // <Container>
    //   {CenterOfScreenProps.children}
    // </Container>

    <div className="border d-flex align-items-center justify-content-center min-vh-100 " style={{overflowY:"auto",maxHeight:"100vh"}}>
      {CenterOfScreenProps.children}
    </div>
  );
};

export default CenterOfScreen;
