import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { isTemplateTail } from "typescript";
import { Api } from "../Api/ApiClient";
import { getTherapists } from "../Api/supervisorResponse";
import Spinner from "../Utils/Spinner";
import BelowNavBar from "../Utils/BelowNavBar";
import CenterOfScreen from "../Utils/CenterOfScreen";
import ContainerDiv from "../Utils/ContainerDiv";

import Dropdown from "../Utils/Dropdown";
import NavBarCont from "../Utils/NavBarCont";
import { setNavItems } from "../Utils/NavItems";
import _ from "underscore";
import { tr } from "date-fns/locale";

import TherapistMappingDiv from "../Utils/TherapistMappingDiv";

export interface getSupervisors {
  success: Success;
}
export interface Success {
  data?: DataEntity[] | null;
}
export interface DataEntity {
  supervisor_id: number;
  org_id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  supervisor_code: string;
  status: string;
  modified_by: string;
  modified_on: string;
  user_id: number;
}

interface tableData {
  organizationName?: string;
  orgId?: string;
}
interface SupervisorData {
  organizationName: string;
  orgId: number;
  userId:number;
}

interface TherapistData {
  therapistName?: string;
  therapistId?: string;
  checked?:boolean
}

interface TherapistDataCheck {
  organizationName: string;
}

interface UnassignedTherapistData {
  therapistName?: string;
  therapistId?: string;
  checked?:boolean
}

export interface UnassignedTherapistResponse {
  therapist_id: string;
  therapist_code: string;
  therapist_name: string;

  first_name:string;
  user_id:string
}

// interface firstDropdown{
//   orgId:string;
//   organizationName:string
// }

const SPAdminMapping = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dropdownLoading, setDropDownLoading] = useState<boolean>(true);
  const [dropdownValue, setDropdownValue] = useState<tableData[]>();
  const [supervisorDropdownValue, setSupervisorDropdownValue] =
    useState<SupervisorData[]>();
  const [assignedTherapist, setAssignedTherapist] = useState<TherapistData[]>();
  const [unassignedTherapist, setUnassignedTherapist] =
    useState<UnassignedTherapistData[]>();
  const [supervisorId, setSupervisorId] = useState<any>();
  const [therapistId, setTherapistId] = useState<UnassignedTherapistData[]>([]);
  const [therapistDataArr, setTherapistDataArr] = useState<TherapistData[]>([]);
  var getSupervisorsData: getSupervisors;
  useEffect(() => {
    getTenantsList();
  }, []);
  var tableData: tableData[] = [];
  var supervisorData: SupervisorData[] = [];
  var therapistData: TherapistData[] = [];
  var getTherapistBySupervisorData: getTherapists[] = [];
  var getUnassignedTherapistData: UnassignedTherapistResponse[];
  var UnassignedTherapistList: UnassignedTherapistData[] = [];
  var orgId: any;
  var arrMoveToAssigned: TherapistData[] = [];
  var arrMoveToUnassigned: UnassignedTherapistData[] = [];
  const [disableSubmit, setSubmitDisable] = useState<any>(true);

  const getTenantsList = async () => {
    const getTenantsListResponse: AxiosResponse = await Api.getTenants(
      encodeURIComponent(sessionStorage.getItem("token")!)
    );
    // setLoading((prev) => (prev = false));
    setDropDownLoading((prev) => (prev = false));
    // console.log(
    //   "list==>" +
    //     JSON.stringify(getTenantsListResponse.data.success.data.tenants)
    // );
    const getTenantsListResponseData =
      getTenantsListResponse.data.success.data.tenants;
    for (let i = 0; i < getTenantsListResponseData.length; i++) {
      for (let j = i; j <= i; j++) {
        tableData.push({
          organizationName: getTenantsListResponseData[i].name,
          orgId: getTenantsListResponseData[i].metaData.org_id,
        });
      }
    }
    setDropdownValue(tableData);
  };

  const onDropdownValueChangeCheck = async (dataFromDropDown: string) => {
    // orgId = valueFromDropDown
    //valueFromDropDown=dataFromDropDown;
    // alert("orgId value"+ orgId)
  //  alert("Dropdown data"+ JSON.parse(dataFromDropDown).orgId);
    
    const getSupervisorsResponse: AxiosResponse = await Api.getSupervisorsList(
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      JSON.parse(dataFromDropDown).orgId,
      "supervisor"
    );
    getSupervisorsData = getSupervisorsResponse.data;
    // console.log("Supervisor Data"+JSON.stringify(getSupervisorsData.success));
    if (getSupervisorsData.success !== undefined) {
      for (let i = 0; i < getSupervisorsData.success!.data!.length; i++) {
        for (let j = i; j <= i; j++) {
          supervisorData.push({
            organizationName:
              getSupervisorsData.success!.data![i].first_name +
              " " +
              getSupervisorsData.success!.data![i].last_name,
            orgId: getSupervisorsData.success!.data![i].org_id,
          userId: getSupervisorsData.success!.data![i].user_id,
          });
        }
      }
    }
    // console.log("sfsdsd"+ JSON.stringify(supervisorData));
    setSupervisorDropdownValue(supervisorData);
    // const getUnassignedTherapistsResponse: AxiosResponse =
    //   await Api.getUnassignedTherapists(
    //     encodeURIComponent(sessionStorage.getItem("token")!.toString()),
    //     JSON.parse(dataFromDropDown).orgId
    //   );
    // getUnassignedTherapistData = getUnassignedTherapistsResponse.data;
    // // console.log("unassignvalue===="+JSON.stringify(getUnassignedTherapistsResponse));

    // if (getUnassignedTherapistData !== undefined) {
    //   for (let i = 0; i < getUnassignedTherapistData!.length; i++) {
    //     for (let j = i; j <= i; j++) {
    //       UnassignedTherapistList.push({
    //         therapistName: getUnassignedTherapistData![i].therapist_name,
    //         therapistId:getUnassignedTherapistData![i].therapist_id
    //       });
    //     }
    //   }
    // }
    // alert("checkfffff"+UnassignedTherapistList);

    // setUnassignedTherapist(UnassignedTherapistList);
  };

  const onSupervisorDropdownValueChangeCheck = async (
    valueFromDropDown: string
  ) => {
    // console.log("oooo" + orgId);
    const getUnassignedTherapistsResponse: AxiosResponse =
    await Api.getUnassignedTherapistsList(
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      JSON.parse(valueFromDropDown).orgId,
        "therapist",
        "false",
        JSON.parse(valueFromDropDown).userId
    );
  getUnassignedTherapistData = getUnassignedTherapistsResponse.data.success.data;
  // alert("unassigned"+JSON.stringify(getUnassignedTherapistData))
  // console.log("unassignvalue===="+JSON.stringify(getUnassignedTherapistsResponse));

  if (getUnassignedTherapistData !== undefined) {
    for (let i = 0; i < getUnassignedTherapistData!.length; i++) {
      for (let j = i; j <= i; j++) {
        UnassignedTherapistList.push({
          therapistName: getUnassignedTherapistData![i].first_name,
          therapistId:getUnassignedTherapistData![i].user_id
        });
      }
    }
  }
  // alert("checkfffff"+UnassignedTherapistList);

  setUnassignedTherapist(UnassignedTherapistList);
  //  alert("sss no" + valueFromDropDown);
  //  alert("Dropdown data"+ JSON.parse(valueFromDropDown).orgId);
  //  alert("---"+ JSON.parse(valueFromDropDown).userId);
  setSupervisorId(JSON.parse(valueFromDropDown)); 
//  alert(supervisorId)
    const getTherapistBySupervisorResponse: AxiosResponse =
      await Api.getTherapistBySupervisorList(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        JSON.parse(valueFromDropDown).orgId,
        "therapist",
        JSON.parse(valueFromDropDown).userId,
        "true"
      );
    getTherapistBySupervisorData = getTherapistBySupervisorResponse.data.data;
    // alert(JSON.stringify(getTherapistBySupervisorData))
    console.log("th-su" + JSON.stringify(getTherapistBySupervisorData));
    if (getTherapistBySupervisorData !== undefined) {
      for (let i = 0; i < getTherapistBySupervisorData.length; i++) {
        for (let j = i; j <= i; j++) {
          therapistData.push({
            therapistName: getTherapistBySupervisorData[i].therapist_name,
            therapistId:
              getTherapistBySupervisorData[i].user_id.toString(),
          });
        }
      }
    }
    console.log("therapist" + JSON.stringify(therapistData));
    setAssignedTherapist(therapistData);
    setLoading((prev) => (prev = false));
  };

  const UnassigedToAssignedTherapist = (checkBoxValue: string,id:string,checked:boolean) => {
    //******* */
    // setTherapistDataArr(
    //   (prevData) =>
    //     (prevData = [
    //       ...therapistDataArr,
    //       {
    //         therapistName: checkBoxValue,
    //         therapistId: id,
    //       },
    //     ])
    // );
    //  setAssignedTherapist((prevData )=>(prevData=therapistData))
    //******** */
    if(checked == true){
      setTherapistDataArr(
        (prevData) =>
          (prevData = [
            ...therapistDataArr,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
      setTherapistId(
        (prevData) =>
          (prevData = [
            ...therapistId,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
    }
    else{
      for(let i =0 ; i < therapistDataArr.length; i++){
        var check: UnassignedTherapistData[] = therapistDataArr!.filter(
          (item) => item.therapistName !== checkBoxValue
        );
        setTherapistDataArr((prevData) => (prevData = check));
         
      }
    }
  };

  const assigedToUnassignedTherapist = (checkBoxValue: string, id: string,checked:boolean) => {
    
  //**** 
    // setTherapistId(
    //   (prevData) =>
    //     (prevData = [
    //       ...therapistId,
    //       {
    //         therapistName: checkBoxValue,
    //         therapistId: id,
    //       },
    //     ])
    // );
    //************** */ */
    if(checked == true){
      setTherapistId(
        (prevData) =>
          (prevData = [
            ...therapistId,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
      setTherapistDataArr(
        (prevData) =>
          (prevData = [
            ...therapistDataArr,
            {
              therapistName: checkBoxValue,
              therapistId: id,
              checked:checked
            },
          ])
      );
    }
    else{
      for(let i=0; i < therapistId.length; i++){
        var check: TherapistData[] = therapistId!.filter(
          (item) => item.therapistName !== checkBoxValue
        );
        setTherapistId((prevData) => (prevData = check));
        
      }
    }
  };

  const unassignNewTherapist = () => {
   
    // setUnassignedTherapist((prevData) => (prevData = therapistId));
    // var check :TherapistData[] = assignedTherapist!.filter((item) => item.therapistName !== therapistId[0].therapistName )
    // setAssignedTherapist((prevData) =>(prevData =check))
    if(unassignedTherapist?.length != 0){
      
      setUnassignedTherapist((prevData) => (prevData?.concat(therapistId))); 
      for(let i =0 ; i < therapistId.length; i++){
        var check: TherapistData[] = assignedTherapist!.filter(
          (item) => item.therapistName !== therapistId[i].therapistName
        );
        setAssignedTherapist((prevData) => (prevData = check));
        // assignedTherapist = check;
        setSubmitDisable(false);
      }
      
      setTherapistId(
        (prevData) =>
          (prevData = [ 
          ])
      );
    } 
    };
  const assignNewTherapist = () => {
    // setAssignedTherapist((prevData) => (prevData = therapistDataArr));
    // var check :UnassignedTherapistData[] = unassignedTherapist!.filter((item) => item.therapistName !== therapistDataArr[0].therapistName )
    // setUnassignedTherapist((prevData) => (prevData = check));

    if(assignedTherapist?.length != 0){
      setAssignedTherapist((prevData) => (prevData?.concat(therapistDataArr))); 
      for(let i =0 ; i < therapistDataArr.length; i++){
        var check: UnassignedTherapistData[] = unassignedTherapist!.filter(
          (item) => item.therapistName !== therapistDataArr[i].therapistName
        );
        setUnassignedTherapist((prevData) => (prevData = check));
        // unassignedTherapist = check;
        setSubmitDisable(false);
      }
     
      setTherapistDataArr(
        (prevData) =>
          (prevData = [ 
          ])
      );
    }
  };

  




  return (
    <div>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>

      {/* <CenterOfScreen> */}
        <div style={{ width: "100%", paddingTop: "8%"}}>
          <>
            <div className="mb-5">
              <ContainerDiv widthInPercentage="100%" height="10vh">
              {dropdownLoading != true ?(<>
                <div className="text-start p-3  bg-light rounded">
                  <div className="pb-3">
                    <span className="fs-5">Organization : </span>
                    <span className="pe-5">
                      <Dropdown
                        dropdownList={dropdownValue}
                        onChangeDropDownHandler={(dropDownValue: string) => {
                          onDropdownValueChangeCheck!(dropDownValue);
                        }}
                      />
                    </span>
                    <span className="fs-5">Supervisor : </span>
                    <span>
                      <Dropdown
                        dropdownList={supervisorDropdownValue}
                        onChangeDropDownHandler={(dropDownValue: string) => {
                          onSupervisorDropdownValueChangeCheck!(dropDownValue);
                        }}
                      />
                    </span>
                  </div>
                </div>
                </>
              ) : (
                     <Spinner />
                   )}
              </ContainerDiv>
            </div>
            <div className="">
            {loading != true && assignedTherapist?.length != 0? (<>
              <TherapistMappingDiv
                checkBoxList={assignedTherapist}
                unassignedList={unassignedTherapist}
                disableSubmit={disableSubmit}
                onUnassignedCheckBoxSelected={(checkBoxValue: string,id:string,checked:boolean) => {
                  UnassigedToAssignedTherapist!(checkBoxValue,id,checked);
                }}
                mappingAssignValue={arrMoveToAssigned}
                onAssignedButtonClick={(e: any) => {
                  assignNewTherapist!();
                }}
                onUnassignedButtonClick={(e: any) => {
                  unassignNewTherapist!();
                }}
                onAssignedCheckBoxSelected={(
                  checkBoxValue: string,
                  id: string,
                  checked:boolean
                ) => {
                  assigedToUnassignedTherapist!(checkBoxValue, id,checked);
                }}
                mappingUnassignValue={arrMoveToUnassigned}
                onSubmitTherapistValue={therapistId }
                onSubmitSupervisorValue={supervisorId}
                supervisorDropdownValueChangeCheck= {(supervisorId : string)=>{
                  onSupervisorDropdownValueChangeCheck(supervisorId)
                }}
              /> 
              </>
              ) : (
                     <Spinner />
                   )}
            </div>
          </>
          {/* {"hii" + JSON.stringify(unassignedTherapist)} */}
          {/* {"arr" + arrMoveToUnassigned}
          {"kkk" + JSON.stringify(therapistId)}  */}
        </div>
      {/* </CenterOfScreen> */}
    </div>
  );
};

export default SPAdminMapping;
