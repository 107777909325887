import { AxiosResponse } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Api } from "../Api/ApiClient";
import CenterOfScreen from "../Utils/CenterOfScreen";
import NavBarCont from "../Utils/NavBarCont";
import { setNavItems } from "../Utils/NavItems";
import CustomButton from "../Utils/CustomButton";
import SearchBarTable from "../Utils/SearchBarTable";
import Spinner from "../Utils/Spinner";
import moment from "moment";
import { getSitesResponse } from "../Api/SuperAdminResponses";
import backIcon from "../Assets/arrow_back-dark.svg";
import { ToastContainer, toast } from "react-toastify";

var getSites: getSitesResponse;
interface tableData {
  "Organization Name"?: string;
  "Organization Code"?: string;
  Type?: string;
}
interface NewFormProps {
  setCancelState: React.Dispatch<React.SetStateAction<boolean>>;
}
let BaseTableData: tableData[] = [];

export interface NewOrgPost {
  org_type?: number;
  name?: string;
  org_code?: string;
  domain?: string;
  subDomain?: string;
  type?: string;
  // status?: string;
  // isPatient?: number;
  // primary_site?: string;
  primarySite?: string; //city
}

const SpAdminDashboard = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [getOrganizationTableData, setOrganizationTableData] =
    useState<tableData[]>();
  const [displayMainTable, setDisplayMainTable] = useState<boolean>(true);
  const [orgTypeList, setOrgType] = useState<any>();
  const [disableLoginBtn, setLoginBtnDisable] = useState<boolean>(true);
  var tableData: tableData[] = [];
  useEffect(() => {
    // getTenantsList();
    getOrganizationsList();

    getOrgTypeList();
  }, []);

  const getOrgTypeList = async () => {
    try {
      const getOrgTypeListResponse: AxiosResponse = await Api.getOrgType(
        encodeURIComponent(sessionStorage.getItem("token")!.toString())
      );
      setLoading((prev) => (prev = false));

      const getOrgTypeListResponseData = getOrgTypeListResponse.data.data;
      setOrgType(getOrgTypeListResponseData);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  // const getTenantsList = async () => {
  //   try {
  //     const getTenantsListResponse: AxiosResponse = await Api.getTenants(
  //       encodeURIComponent(sessionStorage.getItem("token")!.toString())
  //     );
  //     setLoading((prev) => (prev = false));
  //     console.log(
  //       "list==>" +
  //         JSON.stringify(getTenantsListResponse.data.success.data.tenants)
  //     );
  //     const getTenantsListResponseData =
  //       getTenantsListResponse.data.success.data.tenants;
  //     for (let i = 0; i < getTenantsListResponseData.length; i++) {
  //       tableData.push({
  //         "Organization Name": getTenantsListResponseData[i].name,
  //         "Organization Code": getTenantsListResponseData[i].metaData.org_code,
  //         Status: getTenantsListResponseData[i].status,
  //       });
  //     }
  //     console.log("table" + JSON.stringify(tableData));
  //     BaseTableData = tableData;
  //     setOrganizationTableData(tableData);
  //   } catch (error) {
  //     console.log(JSON.stringify(error));
  //   }
  // };
  const getOrganizationsList = async () => {
    try {
      const getOrganizationsListResponse: AxiosResponse =
        await Api.getOrganizations(
          encodeURIComponent(sessionStorage.getItem("token")!.toString())
        );
      setLoading((prev) => (prev = false));
      console.log(
        "list==>" + JSON.stringify(getOrganizationsListResponse.data.data)
      );
      const getOrganizationsListResponseData =
        getOrganizationsListResponse.data.data;
      for (let i = 0; i < getOrganizationsListResponseData.length; i++) {
        tableData.push({
          "Organization Name": getOrganizationsListResponseData[i].org_name,
          "Organization Code": getOrganizationsListResponseData[i].org_code,
          Type: getOrganizationsListResponseData[i].org_type,
        });
      }
      console.log("table" + JSON.stringify(tableData));
      BaseTableData = tableData;
      setOrganizationTableData(tableData);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const NewForm: React.FC<NewFormProps> = ({ setCancelState }) => {
    const orgNameRef: React.RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);
    const cityRef: React.RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);
    const domainRef: React.RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);
    const orgCodeRef: React.RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);

    const orgTypeRef: React.RefObject<HTMLSelectElement> =
      useRef<HTMLSelectElement>(null);
    const supsRef: React.RefObject<HTMLSelectElement> =
      useRef<HTMLSelectElement>(null);
    var objToPostNewOrg: NewOrgPost = {};

    const inputHandler = (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLSelectElement>
    ) => {
      const id: string = e.target.id;

      switch (id) {
        case "orgName":
          objToPostNewOrg.name = orgNameRef.current!.value.trim();
          objToPostNewOrg.org_code = orgCodeRef.current!.value.trim();
          objToPostNewOrg.domain = domainRef.current!.value.trim();
          objToPostNewOrg!.subDomain = domainRef.current!.value.trim();
          // objToPostNewOrg.isPatient = 0;
          objToPostNewOrg.org_type = parseInt(orgTypeRef.current!.value);
          objToPostNewOrg.primarySite = cityRef.current!.value;

          break;
        case "orgDomain":
          objToPostNewOrg.name = orgNameRef.current!.value.trim();
          objToPostNewOrg.org_code = orgCodeRef.current!.value.trim();
          objToPostNewOrg.domain = domainRef.current!.value.trim();
          objToPostNewOrg!.subDomain = domainRef.current!.value.trim();
          // objToPostNewOrg.isPatient = 0;
          objToPostNewOrg.org_type = parseInt(orgTypeRef.current!.value);
          objToPostNewOrg.primarySite = cityRef.current!.value;

          break;
        case "Code":
          objToPostNewOrg.name = orgNameRef.current!.value.trim();
          objToPostNewOrg.org_code = orgCodeRef.current!.value.trim();
          objToPostNewOrg.domain = domainRef.current!.value.trim();
          objToPostNewOrg!.subDomain = domainRef.current!.value.trim();
          // objToPostNewOrg.isPatient = 0;
          objToPostNewOrg.org_type = parseInt(orgTypeRef.current!.value);
          objToPostNewOrg.primarySite = cityRef.current!.value;

          break;
        case "city":
          objToPostNewOrg.name = orgNameRef.current!.value.trim();
          objToPostNewOrg.org_code = orgCodeRef.current!.value.trim();
          objToPostNewOrg.domain = domainRef.current!.value.trim();
          objToPostNewOrg!.subDomain = domainRef.current!.value.trim();
          // objToPostNewOrg.isPatient = 0;
          objToPostNewOrg.org_type = parseInt(orgTypeRef.current!.value);
          objToPostNewOrg.primarySite = cityRef.current!.value;

          break;
        case "drpOrgType":
          objToPostNewOrg.name = orgNameRef.current!.value.trim();
          objToPostNewOrg.org_code = orgCodeRef.current!.value.trim();
          objToPostNewOrg.domain = domainRef.current!.value.trim();
          objToPostNewOrg!.subDomain = domainRef.current!.value.trim();
          // objToPostNewOrg.isPatient = 0;
          objToPostNewOrg.org_type = parseInt(orgTypeRef.current!.value);
          objToPostNewOrg.primarySite = cityRef.current!.value;

          break;
      }
    };

    const onSaveClickHandler = async () => {
      if (
        objToPostNewOrg.name == "" ||
        objToPostNewOrg.name == null ||
        objToPostNewOrg.name == undefined
      ) {
        notify("Name is mandatory", "error");
      } else if (
        objToPostNewOrg.org_code == "" ||
        objToPostNewOrg.org_code == null ||
        objToPostNewOrg.org_code == undefined
      ) {
        notify("Org Code Name is mandatory", "error");
      } else if (
        objToPostNewOrg.domain == "" ||
        objToPostNewOrg.domain == null ||
        objToPostNewOrg.domain == undefined
      ) {
        notify("Domain is mandatory", "error");
      } else if (
        objToPostNewOrg.org_type?.toString() == "" ||
        objToPostNewOrg.org_type == null ||
        objToPostNewOrg.org_type == undefined
      ) {
        notify("Org Type is mandatory", "error");
      } else if (
        objToPostNewOrg.primarySite == "" ||
        objToPostNewOrg.primarySite == null ||
        objToPostNewOrg.primarySite == undefined
      ) {
        notify("City is mandatory", "error");
      } else {
        setLoading((prev) => (prev = true));
        const createUserResponse: AxiosResponse =
          await Api.createOrganizationPost(
            objToPostNewOrg,
            sessionStorage.getItem("org_id")!, //tenantId
            encodeURIComponent(sessionStorage.getItem("token")!.toString())
          );

        notify(createUserResponse.data.msg, "info");
        setDisplayMainTable((prev) => (prev = true));
        setLoading((prev) => (prev = false));
        // setLoginBtnDisable(false);
      }
      // alert("response ---------"+JSON.stringify(createUserResponse.data));
      //alert(JSON.stringify(createUserResponse.data));
      // validateEmail(objToPostNewOrg.domain!) === true
      //   ? console.log(JSON.stringify(objToPostNewOrg))
      //   : alert("either fields are empty and not valid");

      //alert(JSON.stringify(objToPostNewOrg));
    };
    const notify = (msg: any, type: any) => {
      if (type === "error") {
        toast.error(msg, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    return (
      <div
        className="skyBlue p-3"
        style={{
          borderRadius: "12px",
        }}
      >
        <p className="Action_Title text-center">New Organization</p>

        <div id="form-Div" className="ps-1 pe-1 pt-2">
          <div className="row">
            <div id="dropDownHolder" className="col-6 pt-3">
              <p>Organization Type*</p>
              <select
                id="drpOrgType"
                className="boxesClass skyBlue"
                ref={orgTypeRef}
                required={true}
                onChange={(e) => {
                  inputHandler(e);
                }}
                style={{ width: "100%" }}
              >
                {orgTypeList?.map((item: any, key: any) => (
                  <option key={key} value={item.id}>
                    {item.org_type}
                  </option>
                ))}
                {/* <option value="Supervisor">Supervisor</option>
                <option value="Therapist">Therapist</option> */}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-6 pt-3">
              <div id="inputBox holder">
                <p>Organization Name*</p>
                <input
                  className="boxesClass border border-white"
                  id="orgName"
                  ref={orgNameRef}
                  placeholder="Abc"
                  required={true}
                  onChange={(e) => {
                    inputHandler(e);
                  }}
                  readOnly={false}
                  //placeholder={formData.orgName}
                ></input>
              </div>
              <div id="inputBox holder" className="pt-3 ">
                <p>Domain*</p>
                <input
                  id="orgDomain"
                  ref={domainRef}
                  className="boxesClass border border-white"
                  placeholder={`e.g. abc.com`}
                  required={true}
                  onChange={(e) => {
                    inputHandler(e);
                  }}
                ></input>
              </div>

              <div id="buttonHolder" className="pt-5 buttonCenter">
                {/* <div className="pt-5  ">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      buttonName="Save"
                      disabled={"disableLoginBtn"}
                      OnClickHandler={onSaveClickHandler}
                    />
                  )}
                </div> */}

                {/* <div
                  className="buttonAction p-3 text-center"
                  onClick={onSaveClickHandler}
                >
                  Save
                </div> */}

                <div
                  className="buttonAction p-3 text-center"
                  onClick={onSaveClickHandler}
                >
                  {" "}
                  Save
                </div>
              </div>
            </div>
            <div className="col-6 pt-3">
              <div id="inputBox holder" style={{ visibility: "visible" }}>
                <p>{orgCodeRef.current?.value} Organization Code*</p>
                <input
                  id="Code"
                  ref={orgCodeRef}
                  className="boxesClass border border-white"
                  required={true}
                  placeholder="ABC"
                  onChange={(e) => {
                    inputHandler(e);
                  }}
                ></input>
              </div>
              <div id="inputBox holder" className="pt-3">
                <p>City*</p>
                <input
                  id="city"
                  ref={cityRef}
                  className="boxesClass border border-white"
                  placeholder="Atlanta"
                  required={true}
                  onChange={(e) => {
                    inputHandler(e);
                  }}
                ></input>
              </div>

              <div id="buttonHolder" className="pt-5 buttonCenter">
                <div
                  className="buttonAction p-3 text-center"
                  onClick={() => {
                    //setMainDiv((prev) => (prev = true));
                    setCancelState((prev) => (prev = true));
                  }}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const searchStringHandler = (searchString: string) => {
    //alert("Jayant Jagtap" + searchString);

    // getTherapistTableData?.filter((value) => {
    //   //console.log(JSON.stringify(value.Therapists?.name));
    //   value.Therapists?.name === searchString
    //     ? setTherapistTableData([value])
    //     : console.log("Shruti");
    // });

    const byQuery = (searchValue: string) => (item: tableData) =>
      !searchString ||
      JSON.stringify(item["Organization Name"])
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());

    const filteredList = getOrganizationTableData?.filter(
      byQuery(searchString)
    );
    //alert(JSON.stringify(filteredList));

    if (filteredList !== null || filteredList !== undefined) {
      filteredList!.length !== 0
        ? searchString.length === 0
          ? setOrganizationTableData((prev) => (prev = BaseTableData))
          : setOrganizationTableData((prev) => (prev = filteredList))
        : setOrganizationTableData((prev) => (prev = BaseTableData));
    } else {
      alert("no such record");
    }
  };

  return (
    <div>
      {loading === false ? (
        <div>
          <NavBarCont
            jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
          ></NavBarCont>
          <CenterOfScreen>
            <div style={{ width: "70%", paddingTop: "5%" }}>
              {displayMainTable === true ? (
                <div id="Main table">
                  {loading === false ? (
                    getOrganizationTableData != null ||
                    getOrganizationTableData != undefined ? (
                      <SearchBarTable
                        Table={getOrganizationTableData}
                        // TableHeight="50Vh"
                        tableBodyHeight="47vh"
                        showNewButton={true}
                        placeHolderValue="Organization"
                        SearchStringFunction={(searchStringValue: string) => {
                          searchStringHandler(searchStringValue);
                        }}
                        onNewClickHandler={() => {
                          setDisplayMainTable((prev) => (prev = false));
                          //setDisplayAction((prev) => (prev = false));
                        }}
                        // onActionClickHandler={(actionJson: any) => {
                        //   setDisplayAction((prev) => (prev = true));
                        //   OnActionClick(actionJson);
                        // }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              ) : (
                <div
                  id="action table"
                  className="backGroundWhite p-2 ps-3 pe-3 pb-3 "
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={backIcon}
                    alt="go back to main div"
                    onClick={() => {
                      setDisplayMainTable((prev) => (prev = true));
                      // getUsersGetOrganization();
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NewForm setCancelState={setDisplayMainTable} />
                  </div>
                </div>
              )}
            </div>
          </CenterOfScreen>
        </div>
      ) : (
        <div
          style={{
            marginTop: " 22%",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default SpAdminDashboard;
