import React, { useEffect, useState } from "react";
import NavBarCont from "../Utils/NavBarCont";
import alertIcon from "../Assets/Alertsymbolcopy.svg";
import "../ComponentCSS/TherapistDetails.css";
import { TherapistDetailsDatatype } from "../Api/ApiResponseDataTypes";
import { AxiosResponse } from "axios";
import { Api } from "../Api/ApiClient";
import PieChart from "../Utils/PieChart";
import { useHistory, useParams } from "react-router-dom";
import { setNavItems } from "../Utils/NavItems";
import CenterOfScreen from "../Utils/CenterOfScreen";
import Spinner from "../Utils/Spinner";

const TherapistDetails: React.FC = () => {
  const [therapistDetails, setTherapistDetails] =
    useState<TherapistDetailsDatatype>();
  const [pieChartData, setPieChartData] = useState<any>();
  const params: any = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  let therapist_details_data: any;
  let patient_distribution_data: any;
  useEffect(() => {
    if (sessionStorage.getItem("therapist_details") !== undefined && sessionStorage.getItem("therapist_details") !== null) {
      therapist_details_data = sessionStorage.getItem("therapist_details");
      setTherapistDetails(JSON.parse(therapist_details_data));
      setLoading((prev) => (prev = false));
      if (sessionStorage.getItem("patient_distribution") !== undefined) {
        patient_distribution_data = sessionStorage.getItem(
          "patient_distribution"
        );
        setPieChartData(
          (prev: any) => (prev = JSON.parse(patient_distribution_data))
        );
      }
    } else {
      getTherapistDetailsData();
    }

    // sessionStorage.clear()
  }, []);

  const getTherapistDetailsData = async () => {
    const response: AxiosResponse = await Api.getTherapistDetailsList(
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      params["tid"],
      params["payer"]
    );
    console.log("Res" + JSON.stringify(response.data));
    if (response.status === 200) {
      const therapistResponse = response.data;
      var therapistDetailsData: TherapistDetailsDatatype = {
        Total_Patients: therapistResponse[0].Total_Patients,
        Active_patients: therapistResponse[0].Active_patients,
        Inactive_patients: therapistResponse[0].Inactive_patients,
        Therapist_name: therapistResponse[0].Therapist_name,
        therapist_code: therapistResponse[0].therapist_code,
        Therapist_ID: therapistResponse[0].therapist_code,
        recom_count: therapistResponse[0].recom_count,
        actual_count: therapistResponse[0].actual_count,
        Survey_Completion: therapistResponse[0].Survey_Completion,
      };
      sessionStorage.setItem(
        "therapistName",
        therapistResponse[0].Therapist_name
      );
      setTherapistDetails(therapistDetailsData);
      sessionStorage.setItem(
        "therapist_details",
        JSON.stringify(therapistDetailsData)
      );
      getPatientDistribution(therapistResponse[0].Therapist_ID);
      setLoading((prev) => (prev = false));
    } else {
      history.push("/");
    }
  };

  const getPatientDistribution = async (tCode: number) => {
    try {
      const response: AxiosResponse = await Api.getPatientDistribution(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        params["payer"],
        tCode.toString(),
        ""
      );
      console.log("Res" + JSON.stringify(response.data));

      const patientDistributionResponse = response.data.data;
      var PatientDistributionData: any[] = [];
      if (
        patientDistributionResponse[0].greaterThan65 === 0 &&
        patientDistributionResponse[0].lessThan65 === 0 &&
        patientDistributionResponse[0].noScore === 0
      ) {
        PatientDistributionData = [];
      } else {
        PatientDistributionData.push(
          {
            name: `Score greater than 65 <sup><img src=${alertIcon} alt="mast"/><sup>`,
            y: parseFloat(patientDistributionResponse[0].greaterThan65),
          },
          {
            name: "Score less than 65 ",
            y: parseFloat(patientDistributionResponse[0].lessThan65),
          },
          {
            name: "No Score",
            y: parseFloat(patientDistributionResponse[0].noScore),
          }
        );
      }

      setPieChartData((prev: any) => (prev = PatientDistributionData));
      sessionStorage.setItem(
        "patient_distribution",
        JSON.stringify(PatientDistributionData)
      );
      // console.log("check"+JSON.stringify(AgencyDetailsData))
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div>
      <NavBarCont jsonArray={setNavItems(sessionStorage.getItem("Role")!)} />
      <CenterOfScreen>
        <>
          <div
            style={{
              width: "70%",
              paddingTop: "5%",
            }}
            className="therapist-details"
          >
            {loading === false ? (
              <div className="backGroundWhite row text-center">
                <div className="col-md-7">
                  <div className="row pt-3 pb-1 ps-4 text-start">
                    <h2 className="headerNameStyle">
                      Compliance Status & Risk Profile
                    </h2>
                  </div>
                  <div className="row ps-5 pb-3 pe-3" style={{ width: "60%" }}>
                    <div className="p-1 m-3 therapistDetailsCard col-md-10 pt-4">
                      <div className="row cardHeader">
                        <div className="col-md-12 text-center">
                          <p
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "normal",
                              fontSize: "36px",
                              lineHeight: "42px",
                              letterSpacing: "0.01em",
                              textDecoration: "underline",
                              color: "#3BAEB9",
                            }}
                            //  className="nameStyle"
                          >
                            {therapistDetails?.Therapist_name}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-md-2"></div> */}
                        <div className="col-md-12 d-flex justify-content-center">
                          <table className="">
                            <tbody
                              className=" text-center therapistDetails-table"
                              style={{ overflow: "hidden" }}
                            >
                              <tr className="p-2 ">
                                <td className="text-end cardtd p-2">
                                  Therapist Id :
                                </td>
                                <td className="text-start  cardtd p-2">
                                  {therapistDetails?.therapist_code}
                                </td>
                              </tr>
                              <tr className="p-2">
                                <td className="text-end p-2 cardtd">
                                  Patients :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {therapistDetails?.Total_Patients}
                                </td>
                              </tr>
                              <tr className="p-2">
                                <td className="text-end p-2 cardtd">
                                  Active Patients :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {therapistDetails?.Active_patients}
                                </td>
                              </tr>
                              <tr className="p-2">
                                <td className="text-end p-2 cardtd">
                                  Inactive Patients :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {therapistDetails?.Inactive_patients}
                                </td>
                              </tr>
                              <tr className="p-2">
                                <td className="text-end p-2 cardtd">
                                  Total Surveys :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {therapistDetails?.actual_count}
                                </td>
                              </tr>
                              <tr className="p-2">
                                <td className="text-end p-2 cardtd">
                                  Survey Completion Rate :
                                </td>
                                <td className="text-start p-2 cardtd">
                                  {therapistDetails?.Survey_Completion}
                                </td>
                              </tr>

                              <tr className="ms-4">
                                <td className="pe-5 pt-2" colSpan={2}>
                                  <button
                                    className="cardButton p-3"
                                    onClick={() => {
                                      if (
                                        sessionStorage.getItem("Role") ===
                                        "Utilization Manager"
                                      ) {
                                        history.push(
                                          `/PatientSearch/${therapistDetails?.therapist_code}/${therapistDetails?.Therapist_name}`
                                        );
                                      } else {
                                        history.push(
                                          `/PatientSearch/${therapistDetails?.therapist_code}`
                                        );
                                      }
                                    }}
                                  >
                                    View Patients
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row p-3 text-start">
                    <h4 className="nameChart">SFSS Score Distribution</h4>
                  </div>
                  {/* {loading === false ? (
                    <div className="row">
                      {pieChartData && pieChartData.length !== 0 ? (
                        <div className="row">
                          <PieChart value={pieChartData} />
                        </div>
                      ) : (
                        <div className="row NoDataColor">
                          <p>No Data Available</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Spinner />
                  )} */}
                  <div className="row">
                    {pieChartData && pieChartData.length !== 0 ? (
                      <div className="row">
                        <PieChart value={pieChartData} />
                      </div>
                    ) : // <div className="pieChartSpinner">
                    //   <Spinner />
                    //   <p>No Data Available</p>
                    // </div>
                    pieChartData && pieChartData.length === 0 ? (
                      <div className="pieChartSpinner">
                        {/* <Spinner /> */}
                        <p>No Data Available</p>
                      </div>
                    ) : (
                      <div className="pieChartSpinner">
                        <Spinner />
                        {/* <p>No Data Available</p> */}
                      </div>
                    )
                    // <div className="row NoDataColor">
                    //   {/* <p>No Data Available</p> */}
                    //   <Spinner/>
                    // </div>
                    }
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </>
      </CenterOfScreen>
    </div>
  );
};

export default TherapistDetails;
