import React from "react";
import ContainerDiv from "./ContainerDiv";
import "../CSS/TableContainer.css";
import pen from "../Assets/pencil-edit-teal.svg";
import alertIcon from "../Assets/Alert symbol.svg";
import { useHistory } from "react-router-dom";

interface TableContainerProps {
  children?: JSX.Element;
  JsonArray: any[];
  columnToShow?: string[];
  searchTerm?: string;
  height?: string;
  actionButtonHandler?: Function;
  left?: boolean;
  tBodyHeight: string;
}

/**
 * This is component creates a table based on a Json Array passed.
 * The key with Name "Action" will be a button with a value.
 * Also the you can choose which columns to show
 * @param TableContainerProps JsonArray[{}] columnToShow[""]
 * @returns JSX element Table
 */
const TableContainer: React.FC<TableContainerProps> = (TableContainerProps) => {
  const history = useHistory();
  // const location = useLocation();
 
  return (
    <ContainerDiv widthInPercentage="100%" height="inherit" >
      
      <div className="table-responsive-xxl" >
        <div
          className="container"
          style={{ height: '58vh' }}
        >
         
          <table className="spacingBetweenRows table-container">
            <thead className="backGroundWhite" style={{width: '100%'}}>
              <tr style={{ paddingRight: "1%" }}>
               
                {[...Object.keys(TableContainerProps.JsonArray[0])].map(
                  (item) => {
                    
                    return TableContainerProps.columnToShow !== undefined ? (
                      TableContainerProps.columnToShow.map((values) =>
                    
                        values === item ? (
                          item === "Therapists" ? (
                            <th
                              className="border-0 text-start"
                             
                              scope="col"
                              key={JSON.stringify(values)}
                            >
                              Therapist
                            </th>
                          ) : item === "Agencies" ? (
                            <th
                              className="border-0 text-start"
                              scope="col"
                              key={JSON.stringify(values)}
                            >
                              Agencies
                            </th>
                          ) : (
                            <th
                           
                              className="border-0 text-start"
                              scope="col"
                              key={JSON.stringify(values)}
                            >
                              {item}
                            </th>
                          )
                        ) : (
                          // <th
                          //   scope="col"
                          //   style={{ display: "none" }}
                          //   key={index + 1}
                          // >
                          //   {item}
                          // </th>
                          <></>
                        )
                      )
                    ) : item === "Completed" || item === "Required" ? (
                      item === "Completed" ? (
                        <th
                          className="border-0  Completed "
                          scope="col"
                          key={JSON.stringify(item) + "thead"}
                          // style={{ width: "50%" }}
                        >
                          {item}
                        </th>
                      ) : (
                        <th
                              className="border-0 Required "
            
                          scope="col"
                          key={JSON.stringify(item) + "thead"}
                          // style={{ width: "50%" }}
                        >
                          {item}
                        </th>
                      )
                    ) : // <th
                    //   className={`border-0 ${
                    //     TableContainerProps.left === true ? "text-start" : ""
                    //   }`}
                    //   scope="col"
                    //   key={JSON.stringify(item) + "thead"}
                    // >
                    //   {item}
                    // </th>

                    item === "Therapists" ? (
                      <th
                        className="border-0"
                        scope="col"
                        key={JSON.stringify(item)}
                      >
                        Therapist
                      </th>
                    ) : item === "Agencies" ? (
                      <th
                        className={`border-0 ${
                          TableContainerProps.left === true ? "" : ""
                        }`}
                        scope="col"
                        key={JSON.stringify(item)}
                      >
                        Agency
                      </th>
                    ) : (
                      <th
                        className={`border-0 ${
                          TableContainerProps.left === true ? "" : ""
                        }`}
                        // style={{position:"relative",left:"16px"}}
                        scope="col"
                        key={JSON.stringify(item)}
                      >
                        {item}
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            {/* ${TableContainerProps.left===true?"text-start":""} */}
            <tbody
              style={{
                paddingRight: "1%",
                maxHeight:'54vh'
                // maxHeight: `${TableContainerProps.tBodyHeight}`,
              }}
            >
              {TableContainerProps.JsonArray.map((item: any, index: any) => (
                <tr
                  className={`skyBlue text-center`}
                  key={JSON.stringify(item) + "Trow"}
                >
                  {[...Object.keys(item)].map((jsonKey, key) => {
                    return TableContainerProps.columnToShow !== undefined ? (
                      TableContainerProps.columnToShow.map((values, i) =>
                        values === jsonKey ? (
                          values === "Action" || values === "Patient" ? (
                            values === "Patient" ? (
                              <td
                                key={JSON.stringify(values) + "TData"}
                                style={{ padding: "15px" }}
                                
                                // className="text-start"
                              >
                                <div
                                  key={"Div" + JSON.stringify(values)}
                                  className="bg-light"
                                  style={{
                                    padding: "15px",
                                    borderRadius: "15px",
                                    height: "50px",
                                    // width: "150px",
                                    //wordBreak: "break-word",
                                    textDecoration: "underline",
                                  }}
                                  onClick={(e) => {
                                    // alert("tid=" + item.Patient.tid);
                                    history.push(
                                      `/patientDetails/${item.Patient.pid}/${item.Patient.tid}`
                                    );
                                  }}
                                >
                                  {item.Patient.warning === "low" ? (
                                    <label>{item.Patient.name}</label>
                                  ) : (
                                    <>
                                      <div className="row">
                                        <div className="col-9">
                                          <label>{item.Patient.name}</label>
                                        </div>
                                        <div className="col-3">
                                          <label>
                                            <img
                                              src={alertIcon}
                                              alt="alert"
                                              className="ps-2"
                                            ></img>
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            ) : jsonKey === "Therapists" ? (
                              <td key={JSON.stringify(item) + "TData"}>
                                <div
                                  key={
                                    "Div therapist" + JSON.stringify(jsonKey)
                                  }
                                  className=" btnPatient pt-2 pb-2 p-2"
                                  onClick={(e) => {
                                    // alert(
                                    //   "id Therapist=" +
                                    //     JSON.stringify(item[jsonKey])+" ===="+item.tid
                                    // );

                                    history.push(
                                      `/TherapistDetails/${item[jsonKey]["tid"]}/${item[jsonKey]["payer"]}`
                                    );
                                  }}
                                >
                                  {item.Therapists.name}
                                </div>
                              </td>
                            ) : (
                              <td key={JSON.stringify(values) + "TData"}>
                                {JSON.stringify(item[values]) !== "{}" ? (
                                  <div
                                    key={"Div" + JSON.stringify(values)}
                                    className="actionButton "
                                    onClick={(e) => {
                                      //alert(JSON.stringify(item[values]));
                                      TableContainerProps.actionButtonHandler!(
                                        item[values]
                                      );
                                    }}
                                  >
                                    <img
                                      src={pen}
                                      alt="actionIcon"
                                      className="actionImg"
                                    />
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </td>
                            )
                          ) : (
                            <td key={JSON.stringify(item) + "TData"}>
                              {item[values]}
                            </td>
                          )
                        ) : (
                          // <td style={{ display: "none" }} key={i + 5}>
                          //   {item[jsonKey]}
                          // </td>
                          <></>
                        )
                      )
                    ) : jsonKey === "Action" ||
                      jsonKey === "Patient" ||
                      jsonKey === "Therapists" ||
                      jsonKey === "Agencies" ? (
                      // <td key={JSON.stringify(item) + "ACTION"}>
                      //   <div
                      //     key={"ACTION" + JSON.stringify(item)}
                      //     className="actionButton"
                      //     onClick={(e) => {
                      //       alert("id=" + e.currentTarget);
                      //     }}
                      //   >
                      //     <img src={pen} alt="action" className="actionImg" />
                      //   </div>
                      // </td>

                      jsonKey === "Patient" ? (
                        <td  key={JSON.stringify(jsonKey) + "TData"}>
                          <div
                            key={"Div" + JSON.stringify(jsonKey)}
                            className=" btnPatient pt-2 pb-2 p-2"
                            onClick={(e) => {
                              // alert("tid=" + item.Patient.tid);

                              history.push(
                                `/patientDetails/${item.Patient.pid}/${item.Patient.tid}`
                              );
                            }}
                          >
                            {item.Patient.warning === "low" ? (
                              <label style={{textAlign:"left"}}><a href="">{item.Patient.name}</a></label>
                            ) : (
                              <>
                                {/* <div className="row"> */}
                                      <div
                                        // className="col-10 p-0 ps-2 pt-1"
                                      >
                                    <label style={{textAlign:"left"}}><a href="">{item.Patient.name}</a></label>
                                  </div>
                                      <div
                                        // className="col-2"
                                      >
                                    <label>
                                      <img
                                        src={alertIcon}
                                        alt="alert"
                                              className="AlertSymbol"
                                             
                                              // style={{ width: "700%" }}
                                              
                                      ></img>
                                    </label>
                                  </div>
                                {/* </div> */}
                              </>
                            )}
                          </div>
                        </td>
                      ) : jsonKey === "Therapists" ? (
                        <td key={JSON.stringify(item) + "TData"}>
                          <div
                            key={"Div therapist" + JSON.stringify(jsonKey)}
                            className=" btnPatient pt-2 pb-2 p-2"
                            onClick={(e) => {
                              // alert(
                              //   "id Therapist=" + JSON.stringify(item[jsonKey])+"++++++"+item[jsonKey]["tid"]
                              // );

                              history.push(
                                `/therapistDetails/${item[jsonKey]["tid"]}/${item[jsonKey]["payer"]}`
                              );
                            }}
                          >
                            {item.Therapists.name}
                          </div>
                        </td>
                      ) : jsonKey === "Agencies" ? (
                        <td key={JSON.stringify(item) + "TData"}>
                          <div
                            key={"Div agency" + JSON.stringify(jsonKey)}
                            className=" btnPatient pt-2 pb-2 p-2"
                            onClick={(e) => {
                              history.push(
                                `/agencyDetails/${item.Agencies.agency_id}`
                              );
                            }}
                          >
                            {item.Agencies.agency_name}
                          </div>
                        </td>
                      ) : (
                        <td
                          key={JSON.stringify(jsonKey) + "TData"}
                          className={`${
                            TableContainerProps.left === true
                              ? ""
                              : ""
                          }`}
                        >
                          {JSON.stringify(item[jsonKey]) !== "{}" ? (
                            <div
                              key={"Div" + JSON.stringify(jsonKey)}
                              className="actionButton "
                              onClick={(e) => {
                                //alert(JSON.stringify(item[jsonKey]));
                                TableContainerProps.actionButtonHandler!(
                                  item[jsonKey]
                                );
                              }}
                            >
                              <img
                                src={pen}
                                alt="actionIcon"
                                className="actionImg"
                              />
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </td>
                      )
                    ) : jsonKey === "Completed" || jsonKey === "Required" ? (
                      <td
                        key={JSON.stringify(item) + index++}
                        className={`${
                          TableContainerProps.left === true ? "" : ""
                        }`}
                        // style={{ width: "50%" }}
                      >
                        {item[jsonKey]}
                      </td>
                    ) : (
                      <td
                        key={JSON.stringify(item) + index++}
                        className={`${
                          TableContainerProps.left === true ? "" : ""
                        }`}
                      >
                        {item[jsonKey]}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ContainerDiv>
  );
};

export default TableContainer;
