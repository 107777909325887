
import React from "react";


interface DropdownProps {
  dropdownList?: any[];
  onChangeDropDownHandler?: Function;
}

const Dropdown: React.FC<DropdownProps> = ({
  dropdownList,
  onChangeDropDownHandler,
}) => {
  return (
    <span>
      <select
        className="form-select skyBlue text-start border-dark d-inline-flex"
        aria-label="Default select example"
        style={{ width: "15%", height: "50px" }}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          onChangeDropDownHandler!(event.target.value);
        }}
      > 
        <option className="dropdown-item border-dark disabled">
          --Select--
        </option>
        {dropdownList ? (
          dropdownList.map((items, key) => (
            <option
              key={key}
              style={{ width: "30%" }}
              className="border-dark"
              value={JSON.stringify(items)}
            >
              {items.organizationName}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </span>
  );
};

export default Dropdown;
