import { AxiosResponse } from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Api } from "../Api/ApiClient";
// import { DataEntities, getPatients } from "../Api/PatientSearch";
import CenterOfScreen from "../Utils/CenterOfScreen";
import NavBarCont from "../Utils/NavBarCont";
import Spinner from "../Utils/Spinner";
import { setNavItems } from "../Utils/NavItems";
import { useHistory, useParams } from "react-router-dom";
// import { ThemeProvider } from "react-bootstrap";
import "../ComponentCSS/PatientSearchVL.css";
// import Switch from 'react-input-switch';
import Select from "react-dropdown-select";
// import CustomButton from "../Utils/CustomButton";
// import uploadIcon from "../Assets/upload.svg";
import { toast } from "react-toastify";
import _ from "underscore";

const PatientSearchVL: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  let [patientListArr, setPatientList] = useState<any>([]);
  let [organizationListArr, setOrganizationList] = useState<any>([]);
  let [surveyListArr, setSurveyList] = useState<any>([]);
  const [toggleValue, setToggleValue] = useState<any>(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsOrgName, setSelectedOptionsOrgName] = useState<any>([]);
  const [notCompletedCount, SetNotCompletedCount] = useState<any>([]);
  const [partiallyCompletedCount, SetPartiallyCompletedCount] = useState<any>(
    []
  );
  const [completedCount, SetCompletedCount] = useState<any>([]);
  const [surveyWithNoVoiceSample, SetSurveyWithNoVoiceSample] = useState<any>(
    []
  );
  const params: any = useParams();
  const org_drp_ref: React.RefObject<HTMLSelectElement> =
    useRef<HTMLSelectElement>(null);
  let organizations_data: any;
  let org_patients_list: any;
  let patients_survey_list: any;
  useEffect(() => {
    if (sessionStorage.getItem("toggle") === "false") {
      setToggleValue(true);
      sessionStorage.setItem("surveyWithNoVoiceSample", "0");
    } else {
      setToggleValue(false);
      sessionStorage.setItem("surveyWithNoVoiceSample", "0");
    }
    organizations_data = sessionStorage.getItem("org_data");
    if (organizations_data !== undefined && organizations_data !== null) {
      var org_arr: any = [];
      setOrganizationList(JSON.parse(organizations_data));
      if (params.org_id !== undefined) {
        var selected_org_obj = _.findWhere(JSON.parse(organizations_data), {
          org_id: parseInt(params.org_id),
        });
        var selected_org_arr: any = [];
        selected_org_arr.push(selected_org_obj);

        selected_org_arr.map((item: any, index: any) => {
          org_arr.push({
            key: index,
            value: item.org_id,
            label: item.org_name.toString(),
          });
        });
        setSelectedOptionsOrgName(org_arr);
        getPatients(org_arr[0]["value"]);
      }
      setLoading((prev) => (prev = false));
    } else {
      getOrganization();
    }
  }, []);

  const getPatients = async (org_id: any) => {
    var patient_arr: any = [];
    org_patients_list = sessionStorage.getItem("org_patients_list");
    if (sessionStorage.getItem("org_patients_list") !== undefined && sessionStorage.getItem("org_patients_list") !== null) {
      setPatientList(JSON.parse(org_patients_list));
      if (params.patient_code !== undefined) {
        var selected_patient_code_obj = _.findWhere(
          JSON.parse(org_patients_list),
          { patient_code: params.patient_code }
        );
        var selected_patient_code_arr: any = [];
        selected_patient_code_arr.push(selected_patient_code_obj);
        selected_patient_code_arr.map((item: any, index: any) => {
          patient_arr.push({
            key: index,
            value: item.patient_id,
            label: item.patient_code.toString(),
          });
        });
        setSelectedOptions(patient_arr);
        getSurveysForPatient(selected_patient_code_obj.patient_id);
      }
      setLoading((prev) => (prev = false));
    } else {
      const getPatientsResponse: AxiosResponse =
        await Api.getPatientsForLabeling(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          org_id
        );

      if (getPatientsResponse.status === 200) {
        setPatientList(getPatientsResponse.data.data);
        sessionStorage.setItem(
          "org_patients_list",
          JSON.stringify(getPatientsResponse.data.data)
        );
        if (params.patient_code !== undefined) {
          var selected_patient_code_obj = _.findWhere(
            getPatientsResponse.data.data,
            { patient_code: params.patient_code }
          );
          var selected_patient_code_arr: any = [];
          selected_patient_code_arr.push(selected_patient_code_obj);
          selected_patient_code_arr.map((item: any, index: any) => {
            patient_arr.push({
              key: index,
              value: item.patient_id,
              label: item.patient_code.toString(),
            });
          });
          setSelectedOptions(patient_arr);
        }
        setLoading((prev) => (prev = false));
      } else {
        history.push("/");
      }
    }
  };
  const onToggleChange = () => {
    sessionStorage.removeItem("toggle");

    setToggleValue(!toggleValue);
    getSurveysForPatient(selectedOptions);

    if (toggleValue === true) {
      sessionStorage.setItem("toggle", "true");
    } else {
      sessionStorage.setItem("toggle", "false");
    }
  };

  const getSurveysForPatient = async (patient_id: any) => {
    if (sessionStorage.getItem("org_patients_surveys") !== undefined && sessionStorage.getItem("org_patients_surveys") !== null) {
      patients_survey_list = sessionStorage.getItem("org_patients_surveys");
      // patients_survey_list = patients_survey_list.sort(function (a:any, b:any) {
      //   return a.Date.localeCompare(b.name)
      // })
      if (sessionStorage.getItem("toggle") === "true") {
        setToggleValue(false);
        setSurveyList(JSON.parse(patients_survey_list));
      } else if (sessionStorage.getItem("toggle") === "false") {
        var combined_arr_for_labeled_notLabeled: any = [];
        var partially_labelled_arr = _.where(JSON.parse(patients_survey_list), {
          labeling_status_color: "yellow",
        });
        SetPartiallyCompletedCount(partially_labelled_arr);

        var unlabelled_arr = _.where(JSON.parse(patients_survey_list), {
          labeling_status_color: "red",
        });
        SetNotCompletedCount(unlabelled_arr);

        // var completly_label = _.where(
        //   JSON.parse( patients_survey_list),
        //    {labeling_status_color: "green"}
        // );

        combined_arr_for_labeled_notLabeled =
          partially_labelled_arr.concat(unlabelled_arr);
        // combined_arr_for_labeled_notLabeled.sort(function (a:any, b:any) {
        //     return a.Date.localeCompare(b.Date)
        //   });
        combined_arr_for_labeled_notLabeled.sort(
          (x: any, y: any) => +new Date(y.Date) - +new Date(x.Date)
        );
        setSurveyList(combined_arr_for_labeled_notLabeled);
      } else {
        if (toggleValue === false) {
          var combined_arr_for_labeled_notLabeled: any = [];
          var partially_labelled_arr = _.where(
            JSON.parse(patients_survey_list),
            { labeling_status_color: "yellow" }
          );
          SetPartiallyCompletedCount(partially_labelled_arr);

          var unlabelled_arr = _.where(JSON.parse(patients_survey_list), {
            labeling_status_color: "red",
          });
          SetNotCompletedCount(unlabelled_arr);

          // var completly_label = _.where(
          //   JSON.parse( patients_survey_list),
          //    {labeling_status_color: "green"}
          // );

          combined_arr_for_labeled_notLabeled =
            partially_labelled_arr.concat(unlabelled_arr);
          // combined_arr_for_labeled_notLabeled.sort(function (a:any, b:any) {
          //     return a.Date.localeCompare(b.Date)
          //   });
          combined_arr_for_labeled_notLabeled.sort(
            (x: any, y: any) => +new Date(y.Date) - +new Date(x.Date)
          );
          setSurveyList(combined_arr_for_labeled_notLabeled);
          // setSurveyList(combined_arr_for_labeled_notLabeled.sort(function (a:any, b:any) {
          //   return a.Date.localeCompare(b.Date)
          // }));
        } else {
          setSurveyList(JSON.parse(patients_survey_list));
        }
      }

      setLoading((prev) => (prev = false));
    } else {
      const getPatientsSurveyResponse: AxiosResponse =
        await Api.getSurveysForPatient(
          encodeURIComponent(sessionStorage.getItem("token")!.toString()),
          patient_id
        );

      if (getPatientsSurveyResponse.status === 200) {
        setSurveyList(getPatientsSurveyResponse.data.data);
        var unlabelled_arr = _.where(getPatientsSurveyResponse.data.data, {
          labeling_status_color: "red",
        });
        SetNotCompletedCount(unlabelled_arr);

        var partially_labelled_arr = _.where(
          getPatientsSurveyResponse.data.data,
          { labeling_status_color: "yellow" }
        );

        SetPartiallyCompletedCount(partially_labelled_arr);

        var fully_label = _.where(getPatientsSurveyResponse.data.data, {
          labeling_status_color: "green",
        });
        var fully_label_length_count = fully_label.length;
        sessionStorage.setItem(
          "fullyCompletedCount",
          JSON.stringify(fully_label_length_count)
        );
        SetCompletedCount(fully_label);

        var survey_with_no_voice_sample = _.where(
          getPatientsSurveyResponse.data.data,
          { labeling_status_color: "gray" }
        );
        var survey_with_no_voice_sample_length_count =
          survey_with_no_voice_sample.length;
        sessionStorage.setItem(
          "surveyWithNoVoiceSample",
          JSON.stringify(survey_with_no_voice_sample_length_count)
        );
        SetSurveyWithNoVoiceSample(survey_with_no_voice_sample);

        sessionStorage.setItem(
          "org_patients_surveys",
          JSON.stringify(getPatientsSurveyResponse.data.data)
        );
        setLoading((prev) => (prev = false));
      }
    }
  };
  const onChangeOrg = async (values: any) => {
    if (values.length !== 0) {
      sessionStorage.removeItem("org_patients_list");
      setSelectedOptionsOrgName(values);
      setLoading((prev) => (prev = true));
      getPatients(values[0]["value"]);
    }
  };
  const onChangePatient = async (values: any) => {
    if (values.length !== 0) {
      sessionStorage.removeItem("org_patients_surveys");
      setSelectedOptions(values);
      setLoading((prev) => (prev = true));
      getSurveysForPatient(values[0]["value"]);
    }
  };

  const getOrganization = async () => {
    const getOrganizationResponse: AxiosResponse = await Api.getOrganizations(
      encodeURIComponent(sessionStorage.getItem("token")!.toString()),
      "1"
    );
    var org_arr: any = [];
    if (getOrganizationResponse.status === 200) {
      setOrganizationList(getOrganizationResponse.data.data);
      sessionStorage.setItem(
        "org_data",
        JSON.stringify(getOrganizationResponse.data.data)
      );
      if (params.org_id !== undefined) {
        var selected_org_obj = _.findWhere(getOrganizationResponse.data.data, {
          org_id: parseInt(params.org_id),
        });
        var selected_org_arr: any = [];
        selected_org_arr.push(selected_org_obj);

        selected_org_arr.map((item: any, index: any) => {
          org_arr.push({
            key: index,
            value: item.org_id,
            label: item.org_name.toString(),
          });
        });
        setSelectedOptionsOrgName(org_arr);
        getPatients(org_arr[0]["value"]);
      }
      setLoading((prev) => (prev = false));
    } else {
      history.push("/");
    }
  };

  // const goBack = () => {
  //   history.goBack();
  // };

  // const onInputClick = (event: any) => {
  //   event.target.value = "";
  // };

  // const uploadFile = async (file: any) => {
  //   setLoading((prev) => (prev = true));
  //   // let validSize = (file.target.files[0].size / 1024 / 1024).toFixed(2);
  //   // console.log("File size:" + file.target.files[0].size);
  //   var fileName =
  //     params["therapist_id"] +
  //     "_" +
  //     params["patient_id"] +
  //     "_" +
  //     new Date().getTime();

  //   const getSurveyResponse: AxiosResponse = await Api.uploadVoiceSample(
  //     encodeURIComponent(sessionStorage.getItem("token")!.toString()),
  //     file,
  //     fileName
  //   );

  //   if (getSurveyResponse.data.status === "success") {
  //     setLoading((prev) => (prev = false));
  //     notify(getSurveyResponse.data.msg, "info");
  //   }
  // };
  // const notify = (msg: any, type: any) => {
  //   if (type === "error") {
  //     toast.error(msg, {
  //       position: "top-right",
  //       autoClose: 2000,
  //       theme: "colored",
  //       hideProgressBar: true,
  //       closeOnClick: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.info(msg, {
  //       position: "top-right",
  //       autoClose: 2000,
  //       theme: "colored",
  //       hideProgressBar: true,
  //       closeOnClick: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  return (
    <React.Fragment>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <CenterOfScreen>
        {/* <Container fluid> */}
        {/* <ThemeProvider
  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
  minBreakpoint="xxs"
> */}
        <div style={{ width: "70%" }}>
          {loading === false ? (
            <div className="patient-search-outer-block">
              {/* <div className="row p-3 m-4">
                <h2></h2>
              </div> */}

              <div
                className="backGroundWhite patient-search-outer-block"
                style={{ borderRadius: "10px", padding: "3%" }}
              >
                <div className="">
                  {/* <div className="row text-start">
                      <div className="col-md-1 p-2">
                        <img
                          src={backButton}
                          alt="Previous page arrow"
                          onClick={goBack}
                        ></img>
                      </div>
                    </div> */}
                  <div
                    className="container row"
                    // style={{ overflowY: "scroll", height: "57vh" }}
                  >
                    <div id="dropDownHolder" className="col-3 divDrpPatient">
                      <label className="form-label">Organization</label>

                      <Select
                        options={organizationListArr.map(
                          (item: any, index: any) => {
                            return {
                              key: index,
                              value: item.org_id,
                              label: item.org_name.toString(),
                            };
                          }
                        )}
                        key="drpOrgList"
                        values={selectedOptionsOrgName}
                        onChange={(values) => {
                          onChangeOrg(values);
                          delete params.patient_code;
                          delete params.org_id;
                          setSelectedOptions([]);
                          setSurveyList([]);
                        }}
                      />
                    </div>
                    <div id="dropDownHolder" className="col-3 divDrpPatient">
                      <label className="form-label">Patient</label>

                      <Select
                        options={patientListArr.map((item: any, index: any) => {
                          return {
                            key: index,
                            value: item.patient_id,
                            label: item.patient_code.toString(),
                          };
                        })}
                        key="drpPatientList"
                        values={selectedOptions}
                        onChange={(values) => {
                          onChangePatient(values);
                        }}
                      />
                    </div>
                    {selectedOptionsOrgName.length < 1 ||
                    selectedOptions.length < 1 ||
                    surveyListArr.length === 0 ? (
                      <div className="col-6 ">
                        <label className="form-label">
                          Display Partially Labelled or Unlabelled Voice Notes
                        </label>
                        <div style={{ marginTop: "-27px" }}>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onClick={onToggleChange}
                              value={toggleValue}
                              disabled
                            />
                            <span className="slider"></span>
                          </label>
                          {/* <Switch onChange={onToggleChange} checked={toggleValue} disabled/> */}
                        </div>
                      </div>
                    ) : (
                      <div className="col-6 ">
                        {/* <div>
                        <Switch onChange={onToggleChange} checked={toggleValue} value={toggleValue}/>
                      </div> */}
                        <label className="form-label">
                          Display Partially Labelled or Unlabelled Voice Notes
                        </label>
                        <div style={{ marginTop: "-27px" }}>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={toggleValue}
                              value={toggleValue}
                              onChange={onToggleChange}
                            />
                            <span className="slider"></span>
                          </label>
                        </div>
                      </div>
                    )}
                    <div
                      className="d-flex mt-3 lebelling-count"
                      style={{
                        backgroundColor: "#efefef",
                        borderRadius: "15px",
                        color: "black",
                        padding: "10px",
                      }}
                    >
                      {surveyListArr !== undefined ? (
                        <div>
                          <p
                            style={{ marginBottom: 0, marginTop: 0 }}
                            className="labelling-count-text"
                          >
                            Total Count:{surveyListArr.length}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {toggleValue === true ||
                      sessionStorage.getItem("fullyCompletedCount") ===
                        undefined ? (
                        <p
                          style={{
                            marginLeft: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          className="labelling-count-text"
                        >
                          Fully Labelled:0
                        </p>
                      ) : (
                        <p
                          style={{
                            marginLeft: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          className="labelling-count-text"
                        >
                          Fully Labelled:
                          {sessionStorage.getItem("fullyCompletedCount")}
                        </p>
                      )}
                      <p
                        style={{
                          marginLeft: 15,
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                        className="labelling-count-text"
                      >
                        Partially Labelled: {partiallyCompletedCount.length}
                      </p>
                      <p
                        style={{
                          marginLeft: 15,
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                        className="labelling-count-text"
                      >
                        Unlabelled:{notCompletedCount.length}
                      </p>
                      {toggleValue === true ||
                      sessionStorage.getItem("surveyWithNoVoiceSample") ===
                        undefined ? (
                        <p
                          style={{
                            marginLeft: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          className="labelling-count-text"
                        >
                          Survey With No Voice Sample:0
                        </p>
                      ) : (
                        <p
                          style={{
                            marginLeft: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          className="labelling-count-text"
                        >
                          Survey With No Voice Sample:
                          {sessionStorage.getItem("surveyWithNoVoiceSample")}
                        </p>
                      )}
                    </div>

                    {/* <div className="col-3 p-4" id="divAddSampleWithoutSurvey">
                      <label
                        className="btnFileUpload"
                        htmlFor="file-input_without_survey"
                      >
                        <span>Upload Sample Without Survey</span>
                      </label>
                      <input
                        type="file"
                        id="file-input_without_survey"
                        accept="audio/*"
                        className="fileUpload displayNo"
                        onChange={(event) => {
                          uploadFile(event);
                        }}
                        onClick={(event) => {
                          onInputClick(event);
                        }}
                      ></input>
                    </div> */}
                    <div className="container divSurveyDates">
                      <div className="row row-cols-6">
                        {surveyListArr.length !== 0 ? (
                          <>
                            {surveyListArr.map((item: any) => {
                              return (
                                <div className="col bgColor">
                                  {item.labeling_status_color !== "gray" ? (
                                    <div
                                      className="col bgColor"
                                      onClick={() => {
                                        sessionStorage.removeItem(
                                          "voice_sample_data"
                                        );
                                        history.push(
                                          `/VoiceLabeling/${item.survey_id}/${selectedOptions[0]["label"]}/${item.Date}/${selectedOptionsOrgName[0]["value"]}`
                                        );
                                      }}
                                    >
                                      <span>{item.Date}</span>
                                      <span className="alignRight">
                                        <i
                                          className={
                                            item.labeling_status_color
                                              ? "fas fa-microphone color_" +
                                                item.labeling_status_color
                                              : "fas fa-microphone color_gray"
                                          }
                                        ></i>
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="col bgColor">
                                      <span>{item.Date}</span>
                                      <span className="alignRight">
                                        <i
                                          className={
                                            item.labeling_status_color
                                              ? "fas fa-microphone color_" +
                                                item.labeling_status_color
                                              : "fas fa-microphone color_gray"
                                          }
                                        ></i>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="row text-center">
                              <span className="NoDataColorForLabelled">
                                No Data Available
                              </span>
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </div>
        {/* </Container> */}
        {/* </ThemeProvider> */}
      </CenterOfScreen>
    </React.Fragment>
  );
};

export default PatientSearchVL;
