import React from 'react';
import Highcharts from 'highcharts';



class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.value !== "--%") {
      this.valuePer = parseInt(this.props.value.replace("%", ""));
      this.reValue = 100 - this.valuePer;
    }
    else {
      this.valuePer = 0;
    }

  }

  highChartsRender() {
    Highcharts.chart({
      chart: {
        type: 'pie',
        renderTo: 'atmospheric-composition',
        backgroundColor: 'white',

      },

      title: {

        // paddingtop: "10%",
        verticalAlign: 'middle',
        floating: true,
        text: this.valuePer + "%",
        style: {
          fontSize: '20px',
          // textAlign: "Center"

        }
      },
      plotOptions: {
        pie: {
          startAngle: 30,
          innerSize: '90%'
        }
      },
      series:
        //  this.state.series

        [{
          name: 'Survey Completion Rate : Survey Completed',
          data: [
            {
              name: '',
              y: this.valuePer,
              color: '#71D016'
            },
            {
              name: '',
              y: this.reValue,
              color: '#EFEFEF'
            }
          ]
        }]


    });
  }

  componentDidMount() {
    console.log("hiii", parseInt(this.props.value.replace("%", "")), "hhhhhhh", this.reValue);
    this.highChartsRender();
  }

  render() {
    return (
      // <ContainerDiv widthInPercentage="100%" height="20vh" >
      // <div className="row">  style={{ width: "200px", height: "185px" }}
      <div id="atmospheric-composition" className="  bg-transparent " style={{ height: "185px" }}>


      </div>
      // </div>
      // </ContainerDiv>

    );
  }
}

export default DonutChart;