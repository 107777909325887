import React from 'react'

interface GradientProps{
    children:JSX.Element
    }

    /**
     * Gives the background gradient to the full screen.
     * green-yellow-sky blue-blue-deep blue-dark blue 
     * @param GradientProps 
     * @returns JSX element
     */
const Gradient:React.FC<GradientProps> = (GradientProps) => {
    return (
        <div className="linearBackgroundFullScreen">
            {GradientProps.children}
        </div>
    )
}

export default Gradient
