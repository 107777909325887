import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Api } from "../Api/ApiClient";
import { getAgencies } from "../Api/supervisorResponse";
import NavBarCont from "../Utils/NavBarCont";
import { toast } from "react-toastify";
import SearchBarTable from "../Utils/SearchBarTable";
import CenterOfScreen from "../Utils/CenterOfScreen";
import Spinner from "../Utils/Spinner";
import { setNavItems } from "../Utils/NavItems";

interface tableData {
  Agencies?: AgencyDetails;
  "Total Patients"?: number;
  "At-Risk Patients"?: number;
  "% Survey Completion"?: string;
}

interface AgencyDetails {
  agency_name: any;
  agency_id: number;
  
}

let BaseTableData: tableData[] = [];

const Agencies: React.FC = () => {
  const notify = (msg: any, type: any) => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } 
  }; 
  const [getAgenciesTableData, setAgenciesTableData] = useState<tableData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  var tableData: tableData[] = [];

  useEffect(() => {
    getAgencies();
  }, []);

  const searchStringHandler = (searchString: string) => {
    //alert("Jayant Jagtap" + searchString);

    // getAgenciesTableData?.filter((value) => {
    //   //console.log(JSON.stringify(value.Therapists?.name));
    //   value.Therapists?.name === searchString
    //     ? setAgenciesTableData([value])
    //     : console.log("Shruti");
    // });

    const byQuery = (searchValue: string) => (item: tableData) =>
      !searchString ||
      JSON.stringify(item.Agencies)
      .toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase());
      // item.Agencies?.agency_name
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase());

    const filteredList = getAgenciesTableData!.filter(byQuery(searchString));
    //alert(JSON.stringify(filteredList));

    if (filteredList !== null || filteredList !== undefined) {
      // console.log("IN IF" + JSON.stringify(filteredList));
      // console.log("BASETABLE" + JSON.stringify(BaseTableData));
      // console.log("Search===" + searchString.length);
      filteredList.length !== 0
        ? searchString.length === 0
          ? setAgenciesTableData((prev) => (prev = BaseTableData))
          : setAgenciesTableData((prev) => (prev = filteredList))
        : setAgenciesTableData((prev) => (prev = BaseTableData));
    } else {
      notify("No data found", "error");
    }
  };

  const getAgencies = async () => {
    try {
      const getAgenciesResponse: AxiosResponse = await Api.getAgencies(
        encodeURIComponent(sessionStorage.getItem("token")!.toString()),
        sessionStorage.getItem("PayerOrgName")!,
        sessionStorage.getItem("userId")?.toString()!

      );
      setLoading((prev) => (prev = false));
      console.log(JSON.stringify(getAgenciesResponse.data));
      const getAgenciesResponseData: getAgencies[] = getAgenciesResponse.data.data;

      for (let i = 0; i < getAgenciesResponseData.length; i++) {
        tableData.push({
          Agencies: {
            agency_name: (
              <a href="">{getAgenciesResponseData[i].org_name}</a>
            ),
            agency_id: getAgenciesResponseData[i].org_id,
          },
          "Total Patients": parseInt(getAgenciesResponseData[i].totalCount),
          "At-Risk Patients": getAgenciesResponseData[i].highRisk_count,
          "% Survey Completion": getAgenciesResponseData[i].survey_completion,
        });
      }

      console.log("Here goes" + JSON.stringify(tableData));
      BaseTableData = tableData;
      setAgenciesTableData((prev) => (prev = tableData));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div>
      <NavBarCont
        jsonArray={setNavItems(sessionStorage.getItem("Role")!)}
      ></NavBarCont>
      <CenterOfScreen>
        <div style={{ width: "70%", paddingTop: "5%" }}>
          {loading === false ? (
            getAgenciesTableData != null ||
            getAgenciesTableData !== undefined ? (
              <SearchBarTable
                Table={getAgenciesTableData}
                tableBodyHeight="47vh"
                TableHeight="50Vh"
                showNewButton={false}
                placeHolderValue="Agency"
                SearchStringFunction={(searchStringValue: string) => {
                  searchStringHandler(searchStringValue);
                }}
              />
            ) : (
              <></>
            )
          ) : (
            <Spinner />
          )}
        </div>
      </CenterOfScreen>
    </div>
  );
};

export default Agencies;
